import { Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { SupportedChainId } from 'constants/chains'
// import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { LinkIcon } from 'nft/components/icons'
import { useRef, useState, useEffect } from 'react'
import { AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import DiviPriceApi from '../../components/Tokens/TokenDetails/ChartSwap/DiviPriceApi'
import RangeSlider from '../../components/RangeSlider'
import LiquidityApi from '../Farms/LiquidityApi'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
// import { useIsTransactionConfirmed, useTransaction } from 'state/transactions/hooks'
// import styled, { useTheme } from 'styled-components/macro'
// import { AlertCircle } from 'react-feather'
// import { SpinnerWrapper } from '../../theme'
// import { ConfirmIcon, SpinnerIcon } from 'nft/components/icons'
// import { ColumnCenter } from '../../components/Column/index'
import { formatUnits, parseUnits } from '@ethersproject/units'
// import divi from '../../assets/images/divi-logo.svg'
import eth from '../../assets/images/farms/eth.svg'
import QuestionHelper from '../../components/QuestionHelper'
import {
  AccordionCol,
  AccordionLinks,
  Balance,
  CardBadge,
  CardBetween,
  CardExpand,
  CardHead,
  CardRow,
  CardText,
  CardTitle,
  CardWrapper,
  ColButton,
  ColCount,
  ColOuterButton,
  ColOuterRewardsButton,
  ColText,
  ColTitle,
  ColValue,
  FarmsCard,
  FarmsTable,
  IconInner,
  IconOuter,
  IconWrapper,
  IncrementButtons,
  Link,
  LPToken,
  MaxButton,
  ModalLink,
  PopupButtons,
  PopupInput,
  PopupInputInfo,
  PopupLabel,
  PopupLink,
  PopupStake,
  PopupTitle,
  PopupWrapper,
  RangeWrapper,
  RewardsTableBody,
  RewardsTableRow,
  RewardsTableValue,
  TableCellFarms,
  TableExpand,
  TableHead,
  Text,
  TextBold,
  TextDark,
  TextDarkSmall,
  TextUppercase,
  Tooltip,
  TooltipWrapper,
} from '../../components/TableDasboard/sharedStyling'
import Web3Status from '../../components/Web3Status'
import { useTokenBalance } from '../../state/connection/hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { formatEther } from 'ethers/lib/utils'
import { Dots } from 'components/swap/styleds'
import axios from 'axios'
import { ORACLE_ENDPOINT } from 'constants/misc'
import { DIVI } from 'constants/tokens'
import { AIRDROP_ADDRESS } from 'constants/addresses'

type TableHeadItem = {
  text: string
}

type TableBodyItem = {
  iconInner: string
  iconOuter: string
  innerAlt: string
  outerAlt: string
  apy: number
  multiplier: number
}

type ExpandItem = {
  title: string
  href: string
  target?: string
  isExternal: boolean
}

// const ConfirmedIcon = styled(ColumnCenter) <{ inline?: boolean }>`
//   padding: 0;
// `

export default function TableFarms() {
  const TableHeadItems: TableHeadItem[] = [
    {
      text: '',
    },
    {
      text: 'Rewards',
    },
    {
      text: 'APR',
    },
    {
      text: 'Liquidity',
    },
    // {
    //   text: 'Multiplier',
    // },
  ]

  const TableBodyItems: TableBodyItem[] = [
    {
      iconOuter: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3441.png',
      iconInner: eth,
      innerAlt: 'divi',
      outerAlt: 'ether',
      apy: 0,
      multiplier: 1,
    },
  ]

  const ExpandItems: ExpandItem[] = [
    {
      title: 'Get DIVI-ETH LP',
      href: '/liquidity',
      isExternal: false,
    },
    {
      title: 'View Contract',
      href: 'https://etherscan.io/address/0x011a4318e7a45927004018dc88bec93953476615',
      target: '_blank',
      isExternal: true,
    },
    {
      title: 'See Pair Info',
      href: '/pair',
      isExternal: false,
    },
  ]

  const { chainId, provider, account } = useWeb3React()
  const isAuthenticated = !!account

  const [lp, setLp] = useState<Token | undefined>(undefined)

  useEffect(() => {
    if (account) {
      if (chainId === SupportedChainId.GOERLI) {
        // lp = new Token(SupportedChainId.GOERLI, '0xbC90505a97550E5334DA92f0Ba0EB425fae8B178', 18)
        setLp(new Token(SupportedChainId.GOERLI, '0x08a670145536d74D3Ff9B44a1c0218Bfb741015d', 18))
      } else {
        setLp(new Token(SupportedChainId.MAINNET, '0x011A4318e7a45927004018dc88BEc93953476615', 18))
      }
    }
  }, [chainId, account])

  const diviLPBalance = useTokenBalance(account, lp)
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  // const ref = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  // useOnClickOutside(ref, () => setIsPopupOpen(false), [popupRef])

  const [price, setPrice] = useState(0)

  // const [futuReward, setFutuReward] = useState(0)
  const [stakedValue, setStakedValue] = useState('0.0')
  const [earnedValue, setEarnedValue] = useState('0.0')
  const [stakedValueUSD, setStakedValueUSD] = useState(0)
  const [earnedValueUSD, setEarnedValueUSD] = useState(0)
  const [stakedValueDisplay, setStakedValueDisplay] = useState('')
  const [earnedValueDisplay, setEarnedValueDisplay] = useState('')
  const [airdropHistory, setAirdropHistory] = useState<any[]>([])

  const [apy, setApy] = useState(0.0)

  const diviPriceFetch = () => {
    DiviPriceApi.getDiviPrice().then((price) => {
      setPrice(price)
    })
  }

  // const DISPLAY_LIMIT = 10000000000

  const getStakingDataForAccount = async () => {
    try {
      if (account) {
        const aLower = account.toLowerCase()
        setStakedValue(diviLPBalance ? diviLPBalance?.toExact() : '0.0')
        const ss = await axios.get(ORACLE_ENDPOINT + 'getCachedAirdropRewards')
        console.error(JSON.stringify(ss.data.rewards))
        if (ss && ss.data.rewards && ss.data.rewards[aLower]) {
          // rebuild
          setEarnedValue(formatUnits(ss.data.rewards[aLower], 8))
        } else {
          setEarnedValue('0.0')
        }
      } else {
        setEarnedValue('0.0')
      }
    } catch (e: any) {
      setEarnedValue('0.0')
      console.error(e)
    }
  }

  useEffect(() => {
    getStakingDataForAccount()
  }, [account, diviLPBalance])

  useEffect(() => {
    setStakedValueUSD(parseFloat(stakedValue) * price)
    setStakedValueDisplay(parseFloat(stakedValue).toFixed(5))
  }, [stakedValue])

  useEffect(() => {
    setEarnedValueUSD(parseFloat(earnedValue) * price)
    setEarnedValueDisplay(parseFloat(earnedValue).toFixed(5))
  }, [earnedValue])

  const formatDollarAmount = (num: number | bigint, digits: any) => {
    const formatter = new Intl.NumberFormat([], {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    })

    return formatter.format(num)
  }

  const [liquidity, setLiquidity] = useState(0)
  const [tokenLiquidity, setTokenLiquidity] = useState(0)

  const liquidityFetch = () => {
    LiquidityApi.getPairLiquidity().then((liquidity) => {
      setLiquidity(Number(liquidity[0]))
      setTokenLiquidity(Number(liquidity[1]))
    })
  }

  useEffect(() => {
    diviPriceFetch()
    liquidityFetch()
  }, [])

  const [inputValue, setInputValue] = useState('')
  // const [updated, setUpdated] = useState(inputValue)

  const formatValue = (maxValue: string): number => {
    const _maxValue = maxValue.replace(',', '')
    return Number(_maxValue) || 0
  }

  // async function onDecreaseAction() {
  //   setIsPopupOpen(true)
  //   setIsUnstakeAction(true)
  //   setInputValue(stakedValue)
  // }

  // async function onIncreaseAction() {
  //   setIsPopupOpen(true)
  //   setIsUnstakeAction(false)
  //   setInputValue('0')
  // }

  // async function onConfirmAction() {
  //   setUpdated(inputValue)

  //   setApprovingStaking(true)

  //   try {
  //     let result

  //     if (isUnstakeAction) {
  //       result = await stakingCenter?.unstake(parseUnits(inputValue, 18), account)
  //     } else {
  //       await stakingCenter?.approveToken(account)
  //       result = await stakingCenter?.stake(parseUnits(inputValue, 18), account)
  //     }

  //     setApprovingStaking(false)

  //     setIsPopupOpen(!(result === true))
  //     setIsUnstakeAction(false)
  //   } catch (error) {
  //     setApprovingStaking(false)
  //     setIsUnstakeAction(false)
  //   }
  // }

  // async function onCancelAction() {
  //   setUpdated(inputValue)
  //   setApprovingUnstaking(true)
  //   await stakingCenter?.unstake(parseUnits(updated, 18), account)
  //   setApprovingUnstaking(false)
  // }

  const getAirdropHistory = async (account: any) => {
    const r = await axios.get(`
      https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=${DIVI.address}&address=${AIRDROP_ADDRESS}&apikey=CWDSF4R3Y1BPD6VX7M43XHJ4XBFNCMYRGU
    `)
    const txs = r.data.result
    const ret = []
    for (let i = 0; i < txs.length; i++) {
      const to = txs[i].to.toLowerCase()
      if (to === account) {
        ret.push(txs[i])
      }
    }
    setAirdropHistory(ret)
  }

  const getApy = async (amount: number, account: any, thisProjected: any, totalLiquidity: number) => {
    let apy = 0
    try {
      // get airdrop history
      if (airdropHistory.length === 0) await getAirdropHistory(account.toLowerCase())
      // get the fraction of LP compared to total LP
      const lpDetails = await fetch(
        'https://api.etherscan.io/api?module=stats&action=tokensupply&contractaddress=0x011A4318e7a45927004018dc88BEc93953476615&apikey=CWDSF4R3Y1BPD6VX7M43XHJ4XBFNCMYRGU',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      // const data = await r.json()
      let totalLp = 0
      if (lpDetails.ok) {
        const r1 = await lpDetails.json()
        totalLp = parseFloat(r1['result'])
      } else {
        return 0
      }
      // multiply that by total eDIVI liquidity to get eDIVI value
      amount = (amount / totalLp / 1e18) * totalLiquidity
      // get sum of all previous airdrops plus the current projected airdrop
      let s = 0
      let d = 0
      for (let i = 0; i < airdropHistory.length; i++) {
        s += parseFloat(airdropHistory[i].value)
        d++
      }
      s += thisProjected
      const average = ((s / (d + 1)) * 1.0) / 1e8
      // calculate apy by getting projected earnings after 1 year (average weekly airdrop amount * 52) and dividing it by principal (amount held)
      apy = (average * 52) / amount
    } catch (e) {
      return 0.0
    }
    setApy(isNaN(apy) ? 0.0 : Math.round(apy * 100.0))
  }

  // const theme = useTheme()

  // const [txHash, setTxHash] = useState('')
  // const transaction = useTransaction(txHash)
  // const confirmed = useIsTransactionConfirmed(txHash)
  // const transactionSuccess = transaction?.receipt?.status === 1

  // async function onHarvestAction() {
  //   if (!account) return
  //   setApprovingUnstaking(true)
  //   const v = await stakingCenter?.getVaultAddress(account)
  //   const data = await stakingCenter?.getAccountStakingData(v ? v : '')
  //   if (data) await stakingCenter?.unstake(data?.staked, account)
  //   setApprovingUnstaking(false)
  // }

  // async function onApproveStaking() {
  //   if (!account) return
  //   setApprovingStaking(true)
  //   const address = await stakingCenter?.getVaultAddress(account)
  //   if (!address) {
  //     const vault = await stakingCenter?.createVault()
  //     setStakeAvailable(vault !== undefined)
  //   }
  //   setApprovingStaking(false)
  // }

  useEffect(() => {
    if (account && tokenLiquidity > 0 && parseFloat(stakedValue) > 0) {
      getApy(parseFloat(stakedValue), account, earnedValue, tokenLiquidity)
    } else {
      setApy(0.0)
      // setStakedValue('0.0')
      // setEarnedValue('0.0')
    }
  }, [stakedValue, account, tokenLiquidity, earnedValue])

  return (
    <>
      <FarmsTable>
        <TableHead style={{ borderRadius: '20px 20px 0 0' }}>
          {TableHeadItems.map((item, index) => (
            <TableCellFarms key={index}>{item.text}</TableCellFarms>
          ))}
        </TableHead>
        <RewardsTableBody allowZeroExpanded style={{ borderRadius: '0 0 20px 20px' }}>
          {TableBodyItems.map((item, index) => (
            <RewardsTableRow key={index}>
              <AccordionItemHeading>
                <RewardsTableValue>
                  <TableCellFarms>
                    <IconWrapper>
                      <IconOuter src={item.iconOuter} alt={item.outerAlt} />
                      <IconInner src={item.iconInner} alt={item.innerAlt} />
                    </IconWrapper>
                    DIVI-ETH
                  </TableCellFarms>
                  <TableCellFarms>
                    <IconOuter src={item.iconOuter} alt={item.outerAlt} />
                    <Text style={{ margin: '0 12px' }}>Divi</Text>
                    <TextDark>{earnedValueDisplay}</TextDark>
                  </TableCellFarms>
                  <TableCellFarms>{apy}%</TableCellFarms>
                  <TableCellFarms>
                    {formatDollarAmount(Number(liquidity), 2)}
                    <Tooltip>
                      <QuestionHelper text={<span>Total value of the funds in this farm&apos;s liquidity pool</span>} />
                    </Tooltip>
                  </TableCellFarms>
                  {/* <TableCellFarms>
                    {item.multiplier}x
                    <Tooltip>
                      <QuestionHelper
                        text={
                          <span>
                            The Multiplier represents the proportion of Divi Coin rewards each farm receives, as a
                            proportion of the Divi Coin produced each block. For example, if a 1x farm received 1 Divi
                            Coin per block, a 40x farm would receive 40 Divi Coin per block. This amount is already
                            included in all APR calculations for the farm.
                          </span>
                        }
                      />
                    </Tooltip>
                  </TableCellFarms> */}
                </RewardsTableValue>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <TableExpand>
                  <AccordionCol>
                    <ColText>
                      <ColTitle>Divi Rewards</ColTitle>
                      <ColValue>{parseFloat(earnedValue) === 0 ? 0 : earnedValue?.toString() || 0}</ColValue>
                      <ColCount>
                        ~{earnedValueUSD === 0 ? '0.00' : earnedValueUSD < 0.01 ? '<0.01' : earnedValueUSD?.toFixed(2)}$
                      </ColCount>
                    </ColText>
                    {/* <ColOuterRewardsButton
                      disabled={isAuthenticated && isStaked && !isApprovingUnstaking ? false : true}
                      onClick={() => onHarvestAction()}
                    >
                      {isApprovingUnstaking ? (
                        <Dots>
                          <span>Unstake and Claim</span>
                        </Dots>
                      ) : (
                        <span>Unstake and Claim</span>
                      )}
                    </ColOuterRewardsButton> */}
                  </AccordionCol>
                  <AccordionCol>
                    <ColText>
                      <ColTitle>DIVI-ETH LP Held</ColTitle>
                      <ColValue>{stakedValueDisplay || 0}</ColValue>
                      <ColCount>~${stakedValueUSD.toFixed(2)}</ColCount>
                    </ColText>
                    {isAuthenticated ? <></> : <Web3Status />}
                  </AccordionCol>
                  <AccordionLinks>
                    {ExpandItems.map((item, index) => (
                      <Link key={index} href={item.href} target={item.target}>
                        {item.title}
                        {item.isExternal && <LinkIcon style={{ marginLeft: '6px' }} />}
                      </Link>
                    ))}
                  </AccordionLinks>
                </TableExpand>
              </AccordionItemPanel>
            </RewardsTableRow>
          ))}
        </RewardsTableBody>
      </FarmsTable>

      <CardWrapper allowZeroExpanded style={{ borderRadius: '20px' }}>
        {TableBodyItems.map((item, index) => (
          <FarmsCard key={index}>
            <AccordionItemHeading>
              <CardHead>
                <CardTitle>
                  <IconWrapper>
                    <IconOuter src={item.iconOuter} alt={item.outerAlt} />
                    <IconInner src={item.iconInner} alt={item.innerAlt} />
                  </IconWrapper>
                  DIVI-ETH
                </CardTitle>
                <CardRow>
                  <CardText style={{ width: '50%' }}>
                    <TextDarkSmall>Rewards</TextDarkSmall>
                    <Text style={{ display: 'flex', alignItems: 'center' }}>
                      <IconOuter src={item.iconOuter} alt={item.outerAlt} style={{ marginRight: '6px' }} />
                      <span style={{ marginRight: '12px' }}>Divi</span> {earnedValue || 0}
                    </Text>
                  </CardText>
                  <CardText style={{ width: '50%' }}>
                    <TextDarkSmall>APR</TextDarkSmall>
                    <Text>{apy}%</Text>
                  </CardText>
                </CardRow>
              </CardHead>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <CardExpand>
                <CardRow>
                  <CardText style={{ width: '50%' }}>
                    <TextDarkSmall>Liquidity</TextDarkSmall>
                    <TooltipWrapper>
                      <Text>{formatDollarAmount(Number(liquidity), 2)}</Text>
                      <Tooltip>
                        <QuestionHelper
                          text={<span>Total value of the funds in this farm&apos;s liquidity pool</span>}
                        />
                      </Tooltip>
                    </TooltipWrapper>
                  </CardText>
                  {/*<CardText style={{ width: '50%' }}>
                    <TextDarkSmall>Multiplier</TextDarkSmall>
                    <TooltipWrapper>
                      <Text>{item.multiplier}x</Text>
                      <Tooltip>
                        <QuestionHelper
                          text={
                            <span>
                              The Multiplier represents the proportion of Divi Coin rewards each farm receives, as a
                              proportion of the Divi Coin produced each block. For example, if a 1x farm received 1 Divi
                              Coin per block, a 40x farm would receive 40 Divi Coin per block. This amount is already
                              included in all APR calculations for the farm.
                            </span>
                          }
                        />
                      </Tooltip>
                    </TooltipWrapper>
                  </CardText>*/}
                </CardRow>
                <CardBadge style={{ marginTop: '16px' }}>
                  <TextUppercase>Divi Rewards</TextUppercase>
                  <CardBetween style={{ marginBottom: '24px' }}>
                    <TextBold>{parseFloat(earnedValue) === 0 ? 0 : earnedValue?.toString() || 0}</TextBold>
                    <TextDark>
                      ~{earnedValueUSD === 0 ? '0.00' : earnedValueUSD < 0.01 ? '<0.01' : earnedValueUSD?.toFixed(2)}$
                    </TextDark>
                  </CardBetween>

                  {/* <ColOuterRewardsButton
                    disabled={isAuthenticated && isStaked && !isApprovingUnstaking ? false : true}
                    onClick={() => onHarvestAction()}
                    style={{ minWidth: '118px' }}
                  >
                    {isApprovingUnstaking ? (
                      <Dots>
                        <span>Unstake and Claim</span>
                      </Dots>
                    ) : (
                      <span>Unstake and Claim</span>
                    )}
                  </ColOuterRewardsButton> */}
                </CardBadge>
                <CardBadge style={{ marginTop: '16px' }}>
                  <TextUppercase>DIVI-ETH LP HELD</TextUppercase>
                  <CardBetween>
                    <TextBold>{stakedValueDisplay}</TextBold>
                    <TextDark>
                      ~{stakedValueUSD === 0 ? '0.00' : stakedValueUSD < 0.01 ? '<0.01' : stakedValueUSD?.toFixed(2)}$
                    </TextDark>
                  </CardBetween>
                  {isAuthenticated ? <></> : <Web3Status />}
                </CardBadge>
                <AccordionLinks>
                  {ExpandItems.map((item, index) => (
                    <Link key={index} href={item.href} target={item.target}>
                      {item.title}
                    </Link>
                  ))}
                </AccordionLinks>
              </CardExpand>
            </AccordionItemPanel>
          </FarmsCard>
        ))}
      </CardWrapper>
    </>
  )
}
