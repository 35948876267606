// eslint-disable-next-line no-restricted-imports
import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { Trade } from '@uniswap/router-sdk'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { FeeOptions } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import useENS from 'hooks/useENS'
import { SignatureData } from 'hooks/useERC20Permit'
import { useSwapCallArguments } from 'hooks/useSwapCallArguments'
import { ReactNode, useMemo } from 'react'
import useSendSwapTransaction from './useSendSwapTransaction'
import { Web3Provider } from '@ethersproject/providers'
import { RelayProvider } from '@opengsn/provider'
import { useEffect, useState } from 'react'
import { PAYMASTER_SWAP_ROUTER_ADDRESSES } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID,
}

interface UseSwapCallbackReturns {
  state: SwapCallbackState
  callback?: () => Promise<TransactionResponse>
  error?: ReactNode
}
interface UseSwapCallbackArgs {
  trade: Trade<Currency, Currency, TradeType> | undefined // trade to execute, required
  allowedSlippage: Percent // in bips
  recipientAddressOrName: string | null | undefined // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
  signatureData: SignatureData | null | undefined
  deadline: BigNumber | undefined
  feeOptions?: FeeOptions
}

export function useGSNProvider(legacyProvider: Web3Provider | undefined): Web3Provider | undefined {
  const [provider, setProvider] = useState<Web3Provider | undefined>(undefined)
  const chainId = legacyProvider?.network?.chainId as SupportedChainId
  const PAYMASTER_ADDRESS = PAYMASTER_SWAP_ROUTER_ADDRESSES[chainId] || ''

  useEffect(() => {
    if (!legacyProvider?.provider || !PAYMASTER_ADDRESS || provider) return

    RelayProvider.newProvider({
      provider: legacyProvider.provider,
      config: {
        paymasterAddress: PAYMASTER_ADDRESS,
        performDryRunViewRelayCall: false,
        jsonStringifyRequest: true,
        // performEstimateGasFromRealSender: true,
        domainSeparatorName: 'GSN Relayed Transaction',
        loggerConfiguration: {
          logLevel: 'debug',
        },
      },
    })
      .init()
      .then((provider) => {
        setProvider(new Web3Provider(provider as any))
        // setProvider(legacyProvider)
      })
  }, [PAYMASTER_ADDRESS, legacyProvider, provider])

  return provider
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback({
  trade,
  allowedSlippage,
  recipientAddressOrName,
  signatureData,
  deadline,
  feeOptions,
}: UseSwapCallbackArgs): UseSwapCallbackReturns {
  const { account, chainId, provider: currentProvider } = useWeb3React()

  const hasNativeToken = (trade?.routes.filter((r) => r.input.isNative || r.output.isNative)?.length ?? 0) > 0
  const isSupportsGassless = chainId === SupportedChainId.POLYGON || chainId === SupportedChainId.POLYGON_MUMBAI

  const gsn = useGSNProvider(currentProvider)
  const provider = hasNativeToken || !isSupportsGassless ? currentProvider : gsn

  const swapCalls = useSwapCallArguments(
    trade,
    allowedSlippage,
    recipientAddressOrName,
    signatureData,
    deadline,
    feeOptions
  )

  const { callback } = useSendSwapTransaction(account, chainId, provider, trade, swapCalls)

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  return useMemo(() => {
    if (!trade || !provider || !account || !chainId || !callback) {
      return { state: SwapCallbackState.INVALID, error: <span>Missing dependencies</span> }
    }
    if (!recipient) {
      if (recipientAddressOrName !== null) {
        return { state: SwapCallbackState.INVALID, error: <span>Invalid recipient</span> }
      } else {
        return { state: SwapCallbackState.LOADING }
      }
    }

    return {
      state: SwapCallbackState.VALID,
      callback: async () => callback(),
    }
  }, [trade, provider, account, chainId, callback, recipient, recipientAddressOrName])
}
