import { detectIncognito } from 'detectincognitojs'
import { useState } from 'react'
import styled from 'styled-components/macro'

import { ExclamationMarkIcon } from '../../nft/components/icons'

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  color: #f28c41;
  font-size: 14px;
  text-align: left;

  .icon-wrapper {
    padding: 10px;
  }
`

export function MessageIncognitoMode() {
  const [isIncognitoMode, setIsIncognitoMode] = useState(false)
  detectIncognito().then((result) => {
    setIsIncognitoMode(result.isPrivate)
  })

  return (
    <>
      {isIncognitoMode && (
        <MessageWrapper>
          <span className="icon-wrapper">
            <ExclamationMarkIcon width="16px" height="16px" color="#F28C41" />
          </span>
          <span>Seems, your browser is in private mode. Enable Third-party cookies to avoid the wallet errors.</span>
        </MessageWrapper>
      )}
    </>
  )
}
