export interface HourPrice {
  reserve0: number
  reserve1: number
  reserveUSD: number
  hourStartUnix: number
}

export interface DayPrice {
  reserve0: number
  reserve1: number
  reserveUSD: number
  date: number
}

export interface ResponseChartData {
  pairHourDatas: HourPrice[]
  pairDayDatas: DayPrice[]
}

export default class ChartApi {
  private static URL_API = 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2'
  private static ADRESS_DIVI = '0x246908bff0b1ba6ecadcf57fb94f6ae2fcd43a77'
  // Original
  private static ADRESS_PAIR_DIVI_ETH = '0x011a4318e7a45927004018dc88bec93953476615'
  private static CHART_QUERY = `
query ChartData($pairAddress: ID!) {  
  pairHourDatas(where: { pair: $pairAddress }, skip: 0, orderBy: hourStartUnix, first: 24, orderDirection: desc) {
    reserve0
    reserve1
    reserveUSD
    hourStartUnix
  }
  
  pairDayDatas(where: { pairAddress: $pairAddress }, skip: 0, orderBy: date, first: 365, orderDirection: desc) {
    reserve0
    reserve1
    reserveUSD
    date
  }
}
`

  static async getRawData(): Promise<ResponseChartData> {
    const response = await fetch(this.URL_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: this.CHART_QUERY,
        variables: {
          divi: this.ADRESS_DIVI,
          pairAddress: this.ADRESS_PAIR_DIVI_ETH,
          date: 0,
        },
      }),
    })

    const respJson = await response.json()

    return (respJson as { data: ResponseChartData }).data
  }
}
