import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { sendAnalyticsEvent } from 'analytics'
import { EventName, SWAP_PRICE_UPDATE_USER_RESPONSE } from 'analytics/constants'
import { getPriceUpdateBasisPoints } from 'analytics/utils'
import { Separator } from 'components/SearchModal/styleds'
import { RedesignVariant, useRedesignFlag } from 'featureFlags/flags/redesign'
import { SwapIcon } from 'nft/components/icons'
import { useEffect, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { Text } from 'rebass'
import { InterfaceTrade } from 'state/routing/types'
import styled, { useTheme } from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { isAddress, shortenAddress } from '../../utils'
import { ButtonPrimary } from '../Button'
import { LightCard } from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'
import TradePrice from '../swap/TradePrice'
import { AdvancedSwapDetails } from './AdvancedSwapDetails'
import { SwapShowAcceptChanges, TruncatedText } from './styleds'

const ArrowWrapper = styled.div<{ redesignFlag: boolean }>`
  border-radius: 12px;
  height: 40px;
  width: 40px;
  position: relative;
  margin-top: -22px;
  margin-bottom: -22px;
  left: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  z-index: 2;
`

const formatAnalyticsEventProperties = (
  trade: InterfaceTrade<Currency, Currency, TradeType>,
  priceUpdate: number | undefined,
  response: SWAP_PRICE_UPDATE_USER_RESPONSE
) => ({
  chain_id:
    trade.inputAmount.currency.chainId === trade.outputAmount.currency.chainId
      ? trade.inputAmount.currency.chainId
      : undefined,
  response,
  token_in_symbol: trade.inputAmount.currency.symbol,
  token_out_symbol: trade.outputAmount.currency.symbol,
  price_update_basis_points: priceUpdate,
})

export default function SwapModalHeader({
  trade,
  shouldLogModalCloseEvent,
  setShouldLogModalCloseEvent,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
}: {
  trade: InterfaceTrade<Currency, Currency, TradeType>
  shouldLogModalCloseEvent: boolean
  setShouldLogModalCloseEvent: (shouldLog: boolean) => void
  allowedSlippage: Percent
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const theme = useTheme()
  const redesignFlag = useRedesignFlag()
  const redesignFlagEnabled = redesignFlag === RedesignVariant.Enabled

  const [showInverted, setShowInverted] = useState<boolean>(false)
  const [lastExecutionPrice, setLastExecutionPrice] = useState(trade.executionPrice)
  const [priceUpdate, setPriceUpdate] = useState<number | undefined>()

  useEffect(() => {
    if (!trade.executionPrice.equalTo(lastExecutionPrice)) {
      setPriceUpdate(getPriceUpdateBasisPoints(lastExecutionPrice, trade.executionPrice))
      setLastExecutionPrice(trade.executionPrice)
    }
  }, [lastExecutionPrice, setLastExecutionPrice, trade.executionPrice])

  useEffect(() => {
    if (shouldLogModalCloseEvent && showAcceptChanges)
      sendAnalyticsEvent(
        EventName.SWAP_PRICE_UPDATE_ACKNOWLEDGED,
        formatAnalyticsEventProperties(trade, priceUpdate, SWAP_PRICE_UPDATE_USER_RESPONSE.REJECTED)
      )
    setShouldLogModalCloseEvent(false)
  }, [shouldLogModalCloseEvent, showAcceptChanges, setShouldLogModalCloseEvent, trade, priceUpdate])

  return (
    <AutoColumn gap={'4px'} style={{ marginTop: '1rem' }}>
      <LightCard style={{ height: '74px', padding: '0.75rem 1rem' }}>
        <AutoColumn style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <RowBetween align="center" style={{ width: '100%' }}>
            <RowFixed gap={'0px'} style={{ position: 'relative', height: '48px' }}>
              <RowFixed style={{ position: 'absolute', top: '0', left: '0', fontSize: '12px', color: '#85878f' }}>
                Pay:
              </RowFixed>
              <TruncatedText
                fontSize={20}
                fontWeight={500}
                style={{ alignSelf: 'flex-end' }}
                color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? theme.deprecated_primary1 : ''}
              >
                {trade.inputAmount.toSignificant(6)}
              </TruncatedText>
            </RowFixed>
            <RowFixed gap={'0px'} style={{ alignSelf: 'center' }}>
              <CurrencyLogo
                currency={trade.inputAmount.currency}
                style={{ marginRight: '4px', width: '20px', height: '20px', lineHeight: '20px' }}
              />
              <Text fontSize={14} fontWeight={400}>
                {trade.inputAmount.currency.symbol}
              </Text>
            </RowFixed>
          </RowBetween>
        </AutoColumn>
      </LightCard>
      <ArrowWrapper redesignFlag={redesignFlagEnabled}>
        <SwapIcon />
      </ArrowWrapper>
      <LightCard style={{ height: '74px', padding: '0.75rem 1rem', marginBottom: '0.25rem' }}>
        <AutoColumn style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <RowBetween align="flex-end" style={{ width: '100%' }}>
            <RowFixed gap={'0px'} style={{ position: 'relative', height: '48px' }}>
              <RowFixed style={{ position: 'absolute', top: '0', left: '0', fontSize: '12px', color: '#85878f' }}>
                Receive:
              </RowFixed>
              <TruncatedText fontSize={20} fontWeight={500} style={{ alignSelf: 'flex-end' }}>
                {trade.outputAmount.toSignificant(6)}
              </TruncatedText>
            </RowFixed>
            <RowFixed gap={'0px'} style={{ alignSelf: 'center' }}>
              <CurrencyLogo
                currency={trade.outputAmount.currency}
                style={{ marginRight: '4px', width: '20px', height: '20px', lineHeight: '20px' }}
              />
              <Text fontSize={14} fontWeight={400}>
                {trade.outputAmount.currency.symbol}
              </Text>
            </RowFixed>
          </RowBetween>
        </AutoColumn>
      </LightCard>
      <RowBetween style={{ marginTop: '-0.5rem', justifyContent: 'flex-end', padding: '0 1rem' }}>
        <TradePrice price={trade.executionPrice} showInverted={showInverted} setShowInverted={setShowInverted} />
      </RowBetween>
      <LightCard style={{ padding: '.75rem', marginTop: '0.5rem' }}>
        <AdvancedSwapDetails trade={trade} allowedSlippage={allowedSlippage} />
        <Separator style={{ margin: '16px 0', background: '#272828' }} />
        <AutoColumn justify="flex-start" gap="sm">
          {trade.tradeType === TradeType.EXACT_INPUT ? (
            <ThemedText.DeprecatedItalic fontWeight={400} textAlign="left" style={{ width: '100%' }}>
              <span>
                Output is estimated. You will receive at least{' '}
                <b>
                  {trade.minimumAmountOut(allowedSlippage).toSignificant(6)} {trade.outputAmount.currency.symbol}
                </b>{' '}
                or the transaction will revert.
              </span>
            </ThemedText.DeprecatedItalic>
          ) : (
            <ThemedText.DeprecatedItalic fontWeight={400} textAlign="left" style={{ width: '100%' }}>
              <span>
                Input is estimated. You will sell at most{' '}
                <b>
                  {trade.maximumAmountIn(allowedSlippage).toSignificant(6)} {trade.inputAmount.currency.symbol}
                </b>{' '}
                or the transaction will revert.
              </span>
            </ThemedText.DeprecatedItalic>
          )}
        </AutoColumn>
      </LightCard>
      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap={'0px'}>
          <RowBetween>
            <RowFixed>
              <AlertTriangle size={20} style={{ marginRight: '8px', minWidth: 24 }} />
              <ThemedText.DeprecatedMain color={theme.deprecated_primary1}>
                <span>Price Updated</span>
              </ThemedText.DeprecatedMain>
            </RowFixed>
            <ButtonPrimary
              style={{ padding: '.5rem', width: 'fit-content', fontSize: '0.825rem', borderRadius: '12px' }}
              onClick={onAcceptChanges}
            >
              <span>Accept</span>
            </ButtonPrimary>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <ThemedText.DeprecatedMain>
            <span>
              Output will be sent to{' '}
              <b title={recipient}>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
            </span>
          </ThemedText.DeprecatedMain>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
