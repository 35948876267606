import styled, { css } from 'styled-components/macro'

const buttonStyle = css`
  display: inline-block;
  height: 56px;
  padding: 16px 32px;
  border-radius: 80px;
  font-size: 16px;
  line-height: 24px;
  color: white;
  background-color: #6752f4;
  border: 1.5px solid #6752f4;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-family: 'GalanoGrotesque-Medium';

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  :hover {
    background-color: #7966f9;
    border-color: #7966f9;
  }

  :active {
    background-color: #523be9;
    border-color: #523be9;
  }

  :focus {
    background-color: #7b61ff;
    border-color: #7966f9;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding: 12px 24px;
    height: 48px;
  }
`

export const DButton = styled.button`
  ${buttonStyle}
`

export const DButtonCircle = styled(DButton)`
  --size: 44px;

  display: inline-block;
  font-size: 0;
  border-radius: 50%;
  padding: 0;
  width: var(--size);
  height: var(--size);
  line-height: var(--size);
  text-align: center;

  img {
    vertical-align: middle;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    --size: 40px;
  }
`

// export const D
