import { Pair } from '@uniswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { PageName } from 'analytics/constants'
import { Trace } from 'analytics/Trace'
import { UNSUPPORTED_V2POOL_CHAIN_IDS } from 'constants/chains'
import JSBI from 'jsbi'
import { Row } from 'nft/components/Flex'
import { PlusIcon } from 'nft/components/icons'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

import box from '../../assets/images/box.svg'
import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import FullPositionCard from '../../components/PositionCard'
import { RowFixed } from '../../components/Row'
import { Dots } from '../../components/swap/styleds'
import { BIG_INT_ZERO } from '../../constants/misc'
import { useV2Pairs } from '../../hooks/useV2Pairs'
import { useTokenBalancesWithLoadingIndicator } from '../../state/connection/hooks'
import { useStakingInfo } from '../../state/stake/hooks'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { HideSmall, ThemedText } from '../../theme'
import { PageSwitcher, SwitchItem, SwitchItemActive, SwitchText, Title } from './sharedStyling'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  padding: 64px 0 120px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding: 40px 0 64px;
  }
`

const TitleRow = styled(Row)`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    text-align: center;
    flex-direction: column-reverse;
  `};
`

const LiquidityCard = styled.div`
  background: #131415;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  max-width: 630px;
  width: 100%;
  row-gap: 32px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    margin: 0 20px;
    width: calc(100% - 40px);
    padding: 24px;
  }
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  border-radius: 80px;
  padding: 16px 32px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: 100%;
  }
`

const EmptyProposals = styled.div`
  padding: 32px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0d0f0f;
  width: 100%;
`

const CardIcon = styled.img`
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
`

export default function Pool() {
  const { account, chainId } = useWeb3React()
  const unsupportedV2Network = chainId && UNSUPPORTED_V2POOL_CHAIN_IDS.includes(chainId)

  // fetch the user's balances of all tracked V2 LP tokens
  let trackedTokenPairs = useTrackedTokenPairs()
  if (unsupportedV2Network) trackedTokenPairs = []
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = useV2Pairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter((pool) =>
    JSBI.greaterThan(pool.stakedAmount.quotient, BIG_INT_ZERO)
  )
  const stakingPairs = useV2Pairs(stakingInfosWithBalance?.map((stakingInfo) => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter((v2Pair) => {
    return (
      stakingPairs
        ?.map((stakingPair) => stakingPair[1])
        .filter((stakingPair) => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  return (
    <Trace page={PageName.POOL_PAGE} shouldLogImpression>
      <>
        <PageWrapper>
          <Title>Manage Your Liquidity Positions</Title>
          <PageSwitcher>
            <SwitchItem>
              <SwitchText as={Link} to="/swap">
                Swap
              </SwitchText>
            </SwitchItem>
            <SwitchItemActive>
              <SwitchText style={{ color: '#fff' }}>Liquidity</SwitchText>
            </SwitchItemActive>
          </PageSwitcher>
          <AutoColumn gap="lg" justify="center">
            <LiquidityCard>
              <TitleRow>
                <HideSmall>
                  <ThemedText.DeprecatedMediumHeader>
                    <span>Manage Your Unstaked Liquidity</span>
                  </ThemedText.DeprecatedMediumHeader>
                </HideSmall>
              </TitleRow>

              {!account ? (
                <EmptyProposals>
                  <ThemedText.DeprecatedBody style={{ color: 'rgba(255,255,255,0.5)' }} textAlign="center">
                    <span>Connect to a wallet to view your liquidity.</span>
                  </ThemedText.DeprecatedBody>
                </EmptyProposals>
              ) : v2IsLoading ? (
                <EmptyProposals>
                  <ThemedText.DeprecatedBody style={{ color: 'rgba(255,255,255,0.5)' }} textAlign="center">
                    <Dots>
                      <span>Loading</span>
                    </Dots>
                  </ThemedText.DeprecatedBody>
                </EmptyProposals>
              ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
                <>
                  {v2PairsWithoutStakedAmount.map((v2Pair) => (
                    <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                  ))}
                  {stakingPairs.map(
                    (stakingPair, i) =>
                      stakingPair[1] && ( // skip pairs that arent loaded
                        <FullPositionCard
                          key={stakingInfosWithBalance[i].stakingRewardAddress}
                          pair={stakingPair[1]}
                          stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                        />
                      )
                  )}
                </>
              ) : (
                <EmptyProposals>
                  <CardIcon src={box} alt="" />
                  <ThemedText.DeprecatedBody style={{ color: 'rgba(255,255,255,0.5)' }} textAlign="center">
                    <span>You don’t have any supplied liquidity yet</span>
                  </ThemedText.DeprecatedBody>
                </EmptyProposals>
              )}

              <ButtonRow>
                <ResponsiveButtonPrimary id="join-pool-button" as={Link} to="/add/ETH">
                  <PlusIcon style={{ marginRight: '8px' }} />
                  <Text fontWeight={500} fontSize={16}>
                    <span>Add Liquidity</span>
                  </Text>
                </ResponsiveButtonPrimary>
              </ButtonRow>
            </LiquidityCard>
          </AutoColumn>
        </PageWrapper>
      </>
    </Trace>
  )
}
