import { useWeb3React } from '@web3-react/core'
import { RedirectDuplicateTokenIdsV3 } from '../AddLiquidityV3/redirects'
import { RedirectDuplicateTokenIdsV2 } from '../AddLiquidityV2/redirects'
import { SupportedChainId } from '../../constants/chains'
export default function LiquidityRedirects() {
  const { chainId } = useWeb3React()
  const isPolygon = (chainId: SupportedChainId) => chainId === SupportedChainId.POLYGON
  const isMumbai = (chainId: SupportedChainId) => chainId === SupportedChainId.POLYGON_MUMBAI

  return chainId && (isPolygon(chainId) || isMumbai(chainId)) ? (
    <RedirectDuplicateTokenIdsV3 />
  ) : (
    <RedirectDuplicateTokenIdsV2 />
  )
}
