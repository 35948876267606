import { ElementName, Event, EventName } from 'analytics/constants'
import { TraceEvent } from 'analytics/TraceEvent'
import searchIcon from 'assets/svg/search.svg'
import xIcon from 'assets/svg/x.svg'
import useDebounce from 'hooks/useDebounce'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { MEDIUM_MEDIA_BREAKPOINT } from '../constants'
import { filterStringAtom } from '../state'
const ICON_SIZE = '20px'

const SearchBarContainer = styled.div`
  display: flex;
  flex: 1;
`
const SearchInput = styled.input`
  background: no-repeat scroll 7px 7px;
  background-image: url(${searchIcon});
  background-size: 20px 20px;
  background-position: 12px center;
  background-color: #0d0e0f;
  border-radius: 12px;
  border: 1px solid #272828;
  height: 100%;
  width: min(347px, 100%);
  font-size: 16px;
  padding-left: 40px;
  color: #fff;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  :focus {
    outline: none;
    border-color: ${({ theme }) => theme.accentActionSoft};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textTertiary};
  }
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: ${ICON_SIZE};
    width: ${ICON_SIZE};
    background-image: url(${xIcon});
    margin-right: 10px;
    background-size: ${ICON_SIZE} ${ICON_SIZE};
    cursor: pointer;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`

export default function SearchBar() {
  const currentString = useAtomValue(filterStringAtom)
  const [localFilterString, setLocalFilterString] = useState(currentString)
  const setFilterString = useUpdateAtom(filterStringAtom)
  const debouncedLocalFilterString = useDebounce(localFilterString, 300)

  useEffect(() => {
    setLocalFilterString(currentString)
  }, [currentString])

  useEffect(() => {
    setFilterString(debouncedLocalFilterString)
  }, [debouncedLocalFilterString, setFilterString])

  return (
    <SearchBarContainer>
      <TraceEvent
        events={[Event.onFocus]}
        name={EventName.EXPLORE_SEARCH_SELECTED}
        element={ElementName.EXPLORE_SEARCH_INPUT}
      >
        <SearchInput
          type="search"
          placeholder="Search"
          id="searchBar"
          autoComplete="off"
          value={localFilterString}
          onChange={({ target: { value } }) => setLocalFilterString(value)}
        />
      </TraceEvent>
    </SearchBarContainer>
  )
}
