import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import Card from 'components/Card'
import { LoadingRows } from 'components/Loader/styled'
import DiviPriceApi from 'components/Tokens/TokenDetails/ChartSwap/DiviPriceApi'
import { HelpIconGray } from 'nft/components/icons'
import { useMemo, useState } from 'react'
import { InterfaceTrade } from 'state/routing/types'
import styled, { useTheme } from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { computeRealizedPriceImpact } from '../../utils/prices'
import { AutoColumn } from '../Column'
import { RowFixed } from '../Row'
import { MouseoverTooltip } from '../Tooltip'
import FormattedPriceImpact from './FormattedPriceImpact'

const StyledCard = styled(Card)`
  padding: 12px 16px;
  background: #0d0e0f;
  border-radius: 15px;
`

const SwapCardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    display: block;
  }
`

const Text = styled.div`
  text-align: right;
  font-size: 14px;
  color: #85878f;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    text-align: left;
    margin-top: 8px;
  }
`

interface AdvancedSwapDetailsProps {
  trade?: InterfaceTrade<Currency, Currency, TradeType>
  allowedSlippage: Percent
  syncing?: boolean
  hideInfoTooltips?: boolean
}

function TextWithLoadingPlaceholder({
  syncing,
  width,
  children,
}: {
  syncing: boolean
  width: number
  children: JSX.Element
}) {
  return syncing ? (
    <LoadingRows>
      <div style={{ height: '15px', width: `${width}px` }} />
    </LoadingRows>
  ) : (
    children
  )
}

export function AdvancedSwapDetails({
  trade,
  allowedSlippage,
  syncing = false,
  hideInfoTooltips = false,
}: AdvancedSwapDetailsProps) {
  const theme = useTheme()

  const { priceImpact } = useMemo(() => {
    return {
      expectedOutputAmount: trade?.outputAmount,
      priceImpact: trade ? computeRealizedPriceImpact(trade) : undefined,
    }
  }, [trade])

  const [price, setPrice] = useState(0)

  DiviPriceApi.getDiviPrice().then((price) => {
    setPrice(price)
  })

  return !trade ? null : (
    <StyledCard>
      <AutoColumn style={{ gridRowGap: '8px' }}>
        <SwapCardInfo>
          <RowFixed style={{ whiteSpace: 'nowrap' }}>
            <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>Price</ThemedText.DeprecatedSubHeader>
          </RowFixed>
          <TextWithLoadingPlaceholder syncing={syncing} width={65}>
            <Text>{Math.trunc(1000 * price) / 1000}$</Text>
          </TextWithLoadingPlaceholder>
        </SwapCardInfo>
        <SwapCardInfo>
          <RowFixed style={{ marginRight: '20px', whiteSpace: 'nowrap' }}>
            <MouseoverTooltip
              text={
                'The minimum amount you are guaranteed to receive. If the price slips any further, your transaction will revert.'
              }
              disableHover={hideInfoTooltips}
            >
              <ThemedText.DeprecatedSubHeader>
                {trade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sent'}
                <HelpIconGray style={{ marginLeft: '8px', position: 'relative', top: '5px' }} />
              </ThemedText.DeprecatedSubHeader>
            </MouseoverTooltip>
          </RowFixed>
          <TextWithLoadingPlaceholder syncing={syncing} width={70}>
            <Text style={{ wordBreak: 'break-all' }}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? `${trade.minimumAmountOut(allowedSlippage).toSignificant(6)} ${trade.outputAmount.currency.symbol}`
                : `${trade.maximumAmountIn(allowedSlippage).toSignificant(6)} ${trade.inputAmount.currency.symbol}`}
            </Text>
          </TextWithLoadingPlaceholder>
        </SwapCardInfo>
        <SwapCardInfo>
          <RowFixed style={{ whiteSpace: 'nowrap' }}>
            <MouseoverTooltip
              text="The impact your trade has on the market price of this pool."
              disableHover={hideInfoTooltips}
            >
              <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>
                Price Impact
                <HelpIconGray style={{ marginLeft: '8px', position: 'relative', top: '5px' }} />
              </ThemedText.DeprecatedSubHeader>
            </MouseoverTooltip>
          </RowFixed>
          <TextWithLoadingPlaceholder syncing={syncing} width={50}>
            <Text>
              <FormattedPriceImpact priceImpact={priceImpact} />
            </Text>
          </TextWithLoadingPlaceholder>
        </SwapCardInfo>
        <SwapCardInfo>
          <RowFixed style={{ whiteSpace: 'nowrap' }}>
            <MouseoverTooltip
              text="Liquidity providers earn a 0.3% fee on all trades proportional to their share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity."
              disableHover={hideInfoTooltips}
            >
              <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>
                Liquidity Provider Fee
                <HelpIconGray style={{ marginLeft: '8px', position: 'relative', top: '5px' }} />
              </ThemedText.DeprecatedSubHeader>
            </MouseoverTooltip>
          </RowFixed>
          <TextWithLoadingPlaceholder syncing={syncing} width={50}>
            <Text>0.3%</Text>
          </TextWithLoadingPlaceholder>
        </SwapCardInfo>
      </AutoColumn>
    </StyledCard>
  )
}
