import styled from 'styled-components/macro'

export const Title = styled.h1`
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
  color: #fff;
  margin: 0 auto 24px;
  text-align: center;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    font-size: 24px;
    line-heigh: 1.4;
    margin-bottom: 14px;
  }
`

export const PageSwitcher = styled.div`
  width: 240px;
  height: 44px;
  border: 1px solid #6752f4;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 24px;
`

export const SwitchItem = styled.div`
  height: 40px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 1px);
  cursor: pointer;
`

export const SwitchItemActive = styled(SwitchItem)`
  background: #6752f4;
`

export const SwitchText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
`
