import { DIVI, DIVI_GOERLI, DIVI_POLYGON, DIVI_POLYGON_MUMBAI } from './tokens'
import WarningCache, { TOKEN_LIST_TYPES } from './TokenSafetyLookupTable'

export enum WARNING_LEVEL {
  MEDIUM,
  UNKNOWN,
  BLOCKED,
}

export function getWarningCopy(warning: Warning | null) {
  let heading = null,
    description = null
  if (warning) {
    if (warning.canProceed) {
      heading = <div>This token isn&apos;t verified.</div>
      description = <span>Please do your own research before trading.</span>
    } else {
      description = <div>You can&apos;t trade this token using the Divi Platform.</div>
    }
  }
  return { heading, description }
}

export type Warning = {
  level: WARNING_LEVEL
  message: JSX.Element
  /* canProceed determines whether triangle/octagon alert icon is used, and
    whether this token is supported/able to be traded */
  canProceed: boolean
}

const MediumWarning: Warning = {
  level: WARNING_LEVEL.MEDIUM,
  message: <span>Caution</span>,
  canProceed: true,
}

const StrongWarning: Warning = {
  level: WARNING_LEVEL.UNKNOWN,
  message: <span>Warning</span>,
  canProceed: true,
}

const BlockedWarning: Warning = {
  level: WARNING_LEVEL.BLOCKED,
  message: <span>Not Available</span>,
  canProceed: false,
}

export function checkWarning(tokenAddress: string) {
  if (
    tokenAddress === DIVI_GOERLI.address ||
    tokenAddress === DIVI.address ||
    tokenAddress === DIVI_POLYGON.address ||
    tokenAddress === DIVI_POLYGON_MUMBAI.address
  )
    return null
  switch (WarningCache.checkToken(tokenAddress.toLowerCase())) {
    case TOKEN_LIST_TYPES.UNI_DEFAULT:
      return null
    case TOKEN_LIST_TYPES.UNI_EXTENDED:
      return MediumWarning
    case TOKEN_LIST_TYPES.UNKNOWN:
      return StrongWarning
    case TOKEN_LIST_TYPES.BLOCKED:
      return BlockedWarning
    case TOKEN_LIST_TYPES.BROKEN:
      return BlockedWarning
  }
}
