import { useWeb3React } from '@web3-react/core'
import PoolV3 from './index'
import PoolV2 from './v2'
import { SupportedChainId } from '../../constants/chains'

export function PoolRedirects() {
  const { chainId } = useWeb3React()
  const isPolygon = (chainId: SupportedChainId) => chainId === SupportedChainId.POLYGON
  const isMumbai = (chainId: SupportedChainId) => chainId === SupportedChainId.POLYGON_MUMBAI

  return chainId && (isPolygon(chainId) || isMumbai(chainId)) ? <PoolV3 /> : <PoolV2 />
}
