import { Accordion, AccordionItem, AccordionItemButton } from 'react-accessible-accordion'
import styled from 'styled-components/macro'

export const FarmsTable = styled.div`
  width: 100%;
  font-weight: 500;
  display: block;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    display: none;
  }
`

export const TableHead = styled.div`
  width: 100%;
  padding: 16px 32px;
  display: flex;
  background: #1a1b1c;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  border-radius: 20px 20px 0 0;
`

export const TableHeadLP = styled(TableHead)`
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    display: none;
  }
`

export const TableCell = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
`

export const TableCellFarms = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
`

export const TableSwapCell = styled(TableCell)`
  width: calc(100% / 6);
`

export const TableLiquidityCell = styled(TableCell)`
  width: calc(100% / 7);
`

export const TableBody = styled.div`
  width: 100%;
  background: #131415;
  font-size: 16px;
  line-height: 24px;
`

export const RewardsTableBody = styled(Accordion)`
  width: 100%;
  background: #131415;
  font-size: 16px;
  line-height: 24px;
  border-radius: 0;
  padding-bottom: 8px;
`

export const TableRow = styled.div`
  width: 100%;
  padding: 16px 32px;

  &:not(:last-of-type) {
    border-bottom: 1px solid #272828;
  }
`

export const RewardsTableRow = styled(AccordionItem)`
  width: 100%;
  padding: 16px 32px;

  &:not(:last-of-type) {
    border-bottom: 1px solid #272828;
  }
`

export const TableValue = styled.div`
  width: 100%;
  display: flex;
  min-height: 32px;
  cursor: pointer;
`

export const RewardsTableValue = styled(AccordionItemButton)`
  width: 100%;
  position: relative;
  display: flex;
  min-height: 32px;
  cursor: pointer;

  &::after {
    position: absolute !important;
    display: inline-block;
    content: '';
    height: 7px;
    width: 7px;
    border-bottom: 1.5px solid rgb(133, 135, 143);
    border-right: 1.5px solid rgb(133, 135, 143);
    transform: rotate(45deg);
    position: relative;
    right: 20px;
    top: 11px;
  }

  &[aria-expanded='true']::after,
  &[aria-selected='true']::after {
    transform: rotate(225deg);
  }
`

export const TableCol = styled.div`
  width: 100%;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`

export const IconInner = styled.img`
  width: 32px;
  heigth: 32px;
  margin-left: -8px;
`

export const IconOuter = styled.img`
  width: 32px;
  height: 32px;
  position: relative;
  z-index: 1;
`

export const Text = styled.div`
  margin-bottom: 2px;
`

export const TextDark = styled.div`
  color: rgba(255, 255, 255, 0.5);

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 14px;
    line-height: 1.5;
  }
`

export const TextDarkSmall = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 12px;
    line-height: 1.5;
  }
`

export const TextGreen = styled.span`
  color: #34c759;
`

export const TableFooter = styled.div`
  width: 100%;
  border-radius: 0 0 20px 20px;
  padding: 8px 0 32px;
  background: #131415;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ColOuterButton = styled.a`
  background: transparent;
  border: 1px solid #6752f4;
  transition: all 0.15s;
  padding: 10px 24px;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  height: 44px;
  border-radius: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    background: #7966f9;
    border-color: #7966f9;
  }

  &:active {
    border-color: #523be9;
  }

  &:focus {
    border-color: #6752f4;
  }

  &:disabled {
    background: transparent;
    border: 1px solid #6752f4;
    opacity: 0.5;
    cursor: default;
  }
`

export const ColOuterLink = styled(ColOuterButton)`
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    min-width: 302px;
    display: flex;
    justify-content: center;
  }
`

export const CardWrapper = styled(Accordion)`
  width: 100%;
  background: #131415;
  border-radius: 20px;
  display: none;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    display: flex;
  }
`

export const FarmsCard = styled(AccordionItem)`
  width: 100%;
  padding: 16px;

  &:not(:first-of-type) {
    border-top: 1px solid #272828;
  }

  &:fist-of-type {
    border-top: none;
  }
`

export const CardHead = styled(AccordionItemButton)`
  position: relative;
  width: 100%;
  cursor: pointer;

  &::after {
    position: absolute !important;
    display: inline-block;
    content: '';
    height: 7px;
    width: 7px;
    border-bottom: 1.5px solid rgb(133, 135, 143);
    border-right: 1.5px solid rgb(133, 135, 143);
    transform: rotate(45deg);
    position: relative;
    right: 16px;
    top: 45%;
    transfrom: translate(-50%);
  }

  &[aria-expanded='true']::after,
  &[aria-selected='true']::after {
    transform: translate(-50%) rotate(225deg);
  }
`

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
`

export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  font-size: 16px;
  line-height: 24px;
  align-items: flex-start;
`

export const CardExpand = styled.div`
  position: relative;
  padding-top: 16px;
`

export const CardRow = styled.div`
  display: flex;
  column-gap: 16px;
`

export const CardButton = styled.div`
  padding-bottom: 16px;
`

export const Tooltip = styled.div`
  margin-top: 4px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    position: absolute;
    top: 0;
    left: calc(100% + 8px);
  }
`

export const TableExpand = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 24px 0 0;
  margin: 0 -16px;
`

export const AccordionCol = styled.div`
  width: calc(50% - 32px);
  margin: 0 16px 32px;
  border-radius: 12px;
  background: #1a1b1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`

export const AccordionLinks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 32px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    flex-direction: column;
    padding-top: 16px;
    row-gap: 16px;
  }
`

export const Link = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: color 0.15s;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4b81eb;
  cursor: pointer;

  &:hover {
    color: #523be9;

    svg path {
      &:first-of-type {
        fill: #523be9;
      }
      &:last-of-type {
        stroke: #523be9;
      }
    }
  }
`

export const ColText = styled.div`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #85878f;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const ColTitle = styled.div`
  text-transform: uppercase;
`

export const ColValue = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
`

export const ColCount = styled.div`
  font-weight: 400;
`

export const ColButton = styled.button`
  padding: 10px 24px;
  color: #fff;
  font-family: 'GalanoGrotesque-Regular', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  height: 44px;
  background: #6752f4;
  border-radius: 80px;
  cursor: pointer;
  border: 1px solid #6752f4;
  transition: all 0.15s;

  &:hover {
    background: #7966f9;
    border-color: #7966f9;
  }

  &:active {
    background: #523be9;
    border-color: #523be9;
  }

  &:focus {
    background: #7b61ff;
    border-color: #7b61ff;
  }

  &:disabled {
    border: 1px solid #6752f4;
    background: #6752f4;
    opacity: 0.5;
    cursor: default;
  }
`

export const ColOuterRewardsButton = styled(ColButton)`
  background: transparent;
  border: 1px solid #6752f4;
  transition: all 0.15s;

  &:hover {
    background: #7966f9;
    border-color: #7966f9;
  }

  &:active {
    border-color: #523be9;
  }

  &:focus {
    border-color: #6752f4;
  }

  &:disabled {
    background: transparent;
    border: 1px solid #6752f4;
    opacity: 0.5;
    cursor: default;
  }
`

export const TooltipWrapper = styled.div`
  position: relative;
`

export const StatusCell = styled.span`
  border-radius: 4px;
  background: #232425;
  height: 32px;
  padding: 4px 8px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 16px;
  column-gap: 4px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    height: 29px;
    font-size: 14px;
  }
`

export const CardBadge = styled.div`
  width: 100%;
  background: #1a1b1c;
  padding: 16px 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CardBetween = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const TextUppercase = styled.div`
  color: #85878f;
  text-transform: uppercase;
  font-family: 'GalanoGrotesque-Medium';
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 8px;
  align-self: flex-start;
`

export const TextBold = styled.div`
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
`

export const IncrementButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 24px;
  /* margin-top: 24px; */
  /* width: 100%; */

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    justify-content: center;
  }
`

export const PopupWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`

export const PopupStake = styled.div`
  width: 630px;
  padding: 32px;
  border-radius: 20px;
  background: #131415;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: calc(100% - 40px);
    max-width: 630px;
    padding: 24px;
    max-height: 80vh;
    overflow-y: auto;
  }
`

export const PopupConfirm = styled(PopupStake)`
  width: 410px;
  height: 200px;
`

export const PopupTitle = styled.h3`
  font-weight: 700;
  font-family: 'GalanoGrotesque-Bold';
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 32px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 18px;
  }
`

export const PopupInput = styled.div`
  width: 100%;
  height: 76px;
  position: relative;
  margin-bottom: 32px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    height: 106px;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 40px 16px 12px 16px;
  border: 1px solid #272828;
  border-radius: 15px;
  background: #0d0e0f;
  color: #85878f;
  font-size: 20px;
  line-height: 1.5;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    padding: 12px 16px 72px 62px;
    text-align: right;
  }

  &:focus {
    border-color: #7966f9;
    outline: none;
  }
`

export const PopupInputInfo = styled.div`
  position: absolute;
  top: 14px;
  right: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  z-index: 5;
`

export const MaxButton = styled.button`
  color: #f28c41;
  font-size: 10px;
  line-height: 1.5;
  padding: 2px 6px;
  text-transform: uppercase;
  background: #232425;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-family: 'GalanoGrotesque-Medium';
`

export const PopupLabel = styled.div`
  position: absolute;
  top: 12px;
  left: 16px;
  color: #85878f;
  font-size: 12px;
  line-height: 1.4;
  background: #0d0e0f;
  width: 40px;
  z-index: 5;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 14px;
  }
`

export const PopupLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const PopupButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
  margin-bottom: 32px;

  & > a,
  & > button {
    width: 50%;
    height: 56px;

    @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    row-gap: 24px;
    flex-direction: column;
  }
`

export const PopupRates = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
  margin-bottom: 32px;
`

export const PopupPercent = styled.span`
  color: #fff;
  font-size: 14px;
  line-height: 21px;
`

export const PopupText = styled.p`
  margin: 0;
  color: #fff;
  font-size: 14px;
  line-height: 21px;
`

export const LPToken = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-left: 8px;
`

export const Balance = styled.div`
  width: 100%;
  color: #85878f;
  font-size: 14px;
  line-height: 1.5;
  text-align: right;
  margin-top: 2px;
`

export const RangeWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`

export const RangePercent = styled.div`
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 12px;
`

export const RangeButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 8px;
  margin-top: 24px;
`

export const RangeButton = styled.button`
  border: none;
  border-radius: 24px;
  height: 40px;
  padding: 8px 24px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  line-height: 24px;
  background: #232425;
  width: 80px;
  font-family: 'GalanoGrotesque-Medium';

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: 70px;
    padding: 8px;
  }

  &:hover {
    background: #6752f4;
  }

  &:active {
    background: #6752f4;
  }

  &:focus {
    background: #6752f4;
  }
`

export const ModalLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: color 0.15s;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4b81eb;
  cursor: pointer;
`

export const SwapLink = styled.a`
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
`
