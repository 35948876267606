import { useWeb3React } from '@web3-react/core'
import { ElementName, Event, EventName } from 'analytics/constants'
import { TraceEvent } from 'analytics/TraceEvent'
import WalletDropdown from 'components/WalletDropdown'
import { getConnection } from 'connection/utils'
import { NavBarVariant, useNavBarFlag } from 'featureFlags/flags/navBar'
import { Portal } from 'nft/components/common/Portal'
import { WalletIcon } from 'nft/components/icons'
import { getIsValidSwapQuote } from 'pages/Swap'
import { darken } from 'polished'
import { useMemo, useRef } from 'react'
import { AlertTriangle, ChevronDown, ChevronUp } from 'react-feather'
import { useAppSelector } from 'state/hooks'
import { useDerivedSwapInfo } from 'state/swap/hooks'
import styled, { css, useTheme } from 'styled-components/macro'

import { ATTR_RESTRICT_CLICK_OUTSIDE, useOnClickOutside } from '../../hooks/useOnClickOutside'
// import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useHasSocks } from '../../hooks/useSocksBalance'
import {
  useCloseModal,
  useModalIsOpen,
  useToggleWalletDropdown,
  useToggleWalletModal,
} from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/types'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import StatusIcon from '../Identicon/StatusIcon'
import Loader from '../Loader'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'
import { SupportedChainId } from 'constants/chains'

// https://stackoverflow.com/a/31617326
const FULL_BORDER_RADIUS = 9999

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 12px 24px;
  border-radius: ${FULL_BORDER_RADIUS}px;
  cursor: pointer;
  user-select: none;
  height: 48px;
  :focus {
    outline: none;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    height: 56px;
    min-width: 335px;
    justify-content: center;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.deprecated_red1};
  border: 1px solid ${({ theme }) => theme.deprecated_red1};
  color: ${({ theme }) => theme.deprecated_white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.deprecated_red1)};
  }
`

const Web3StatusConnectWrapper = styled.div<{ faded?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: ${FULL_BORDER_RADIUS}px;
  border: none;
  padding: 0;
  height: 48px;

  :hover,
  :active,
  :focus {
    border: none;
  }
`

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  background-color: ${({ theme }) => theme.deprecated_primary4};
  border: none;
  color: #fff;
  font-weight: 500;

  :hover,
  :focus {
    border: 1px solid ${({ theme }) => darken(0.05, theme.deprecated_primary4)};
    color: #fff;
  }

  ${({ faded }) =>
    faded &&
    css`
      background-color: ${({ theme }) => theme.deprecated_primary5};
      border: 1px solid ${({ theme }) => theme.deprecated_primary5};
      color: #fff;

      :hover,
      :focus {
        border: 1px solid ${({ theme }) => darken(0.05, theme.deprecated_primary4)};
        color: #fff;
      }
    `}
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background-color: 'transparent';
  border: 1px solid ${({ theme }) => theme.buttonDefault};
  color: #fff;
  font-weight: 600;
  :hover,
  :focus {
    border-color: ${({ theme }) => theme.buttonHover};

    :focus {
      border: 1px solid ${({ pending, theme }) => (pending ? theme.buttonHover : theme.buttonActive)};
    }
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`

const NetworkIcon = styled(AlertTriangle)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

const DropdownContainerSmall = styled.span`
  display: none;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    display: inline;
  }
`

const DropdownContainerLarge = styled.span`
  display: inline;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    display: none;
  }
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Sock() {
  return (
    <span role="img" aria-label={`has socks emoji`} style={{ marginTop: -4, marginBottom: -4 }}>
      🧦
    </span>
  )
}

const StyledConnectButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.buttonDefault};
  border: none;
  border-radius: ${FULL_BORDER_RADIUS}px;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  font-family: 'GalanoGrotesque-Regular', sans-serif;
  white-space: nowrap;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    height: 56px;
    min-width: 335px;
    justify-content: center;
  }

  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => `${duration.fast} background-color ${timing.in}`};

  :hover,
  :active,
  :focus {
    border: none;
  }
  :focus {
    background-color: ${({ theme }) => theme.buttonDefault};
  }
  :hover {
    background-color: ${({ theme }) => theme.buttonHover};
  }
  :active {
    background-color: ${({ theme }) => theme.buttonActive};
  }
  :disabled {
    background-color: ${({ theme }) => theme.buttonDefault};
    opacity: 0.5;
  }
`

const CHEVRON_PROPS = {
  height: 22,
  width: 22,
}

function Web3StatusInner() {
  const { account, chainId, connector, ENSName } = useWeb3React()
  const connectionType = getConnection(connector).type
  const {
    trade: { state: tradeState, trade },
    inputError: swapInputError,
  } = useDerivedSwapInfo()
  const validSwapQuote = getIsValidSwapQuote(trade, tradeState, swapInputError)
  const navbarFlagEnabled = useNavBarFlag() === NavBarVariant.Enabled
  const theme = useTheme()
  const toggleWalletDropdown = useToggleWalletDropdown()
  const toggleWalletModal = useToggleWalletModal()
  const walletIsOpen = useIsOpen()

  const error = useAppSelector((state) => state.connection.errorByConnectionType[getConnection(connector).type])

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const isPolygonOrMumbai = chainId === SupportedChainId.POLYGON_MUMBAI || SupportedChainId.POLYGON

  let pending = []

  if (!isPolygonOrMumbai) {
    pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  }

  const hasPendingTransactions = !!pending.length
  const hasSocks = useHasSocks()
  const toggleWallet = navbarFlagEnabled ? toggleWalletDropdown : toggleWalletModal

  if (error) {
    return (
      <Web3StatusError onClick={toggleWallet}>
        <NetworkIcon />
        <Text>
          <span>Error</span>
        </Text>
      </Web3StatusError>
    )
  } else if (account) {
    const chevronProps = {
      ...CHEVRON_PROPS,
      color: theme.textPrimary,
    }
    return (
      <Web3StatusConnected data-testid="web3-status-connected" onClick={toggleWallet} pending={hasPendingTransactions}>
        {navbarFlagEnabled && !hasPendingTransactions && <StatusIcon size={24} connectionType={connectionType} />}
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>
              <span>{pending?.length} Pending</span>
            </Text>{' '}
            <Loader stroke="white" />
          </RowBetween>
        ) : (
          <>
            {hasSocks && !navbarFlagEnabled ? <Sock /> : null}
            <Text>{ENSName || shortenAddress(account)}</Text>
            {navbarFlagEnabled ? (
              walletIsOpen ? (
                <ChevronUp {...chevronProps} />
              ) : (
                <ChevronDown {...chevronProps} />
              )
            ) : null}
          </>
        )}
        {!navbarFlagEnabled && !hasPendingTransactions && <StatusIcon connectionType={connectionType} />}
      </Web3StatusConnected>
    )
  } else {
    return (
      <TraceEvent
        events={[Event.onClick]}
        name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
        properties={{ received_swap_quote: validSwapQuote }}
        element={ElementName.CONNECT_WALLET_BUTTON}
      >
        {navbarFlagEnabled ? (
          <Web3StatusConnectWrapper faded={!account}>
            <StyledConnectButton data-testid="navbar-connect-wallet" onClick={toggleWalletModal}>
              <WalletIcon width="24" height="24" />
              <span>Connect Wallet</span>
            </StyledConnectButton>
          </Web3StatusConnectWrapper>
        ) : (
          <Web3StatusConnect onClick={toggleWallet} faded={!account}>
            <Text>
              <WalletIcon width="24" height="24" />
              <span>Connect Wallet</span>
            </Text>
          </Web3StatusConnect>
        )}
      </TraceEvent>
    )
  }
}

const useIsOpen = () => {
  const walletDropdownOpen = useModalIsOpen(ApplicationModal.WALLET_DROPDOWN)
  const navbarFlag = useNavBarFlag()

  return useMemo(() => navbarFlag === NavBarVariant.Enabled && walletDropdownOpen, [navbarFlag, walletDropdownOpen])
}

export default function Web3Status() {
  const { ENSName } = useWeb3React()

  const allTransactions = useAllTransactions()
  const ref = useRef<HTMLDivElement>(null)
  const walletRef = useRef<HTMLDivElement>(null)
  const closeModal = useCloseModal(ApplicationModal.WALLET_DROPDOWN)
  const isOpen = useIsOpen()

  useOnClickOutside(ref, isOpen ? closeModal : undefined, [walletRef])

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

  return (
    <span ref={ref}>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
      <Portal>
        <DropdownContainerSmall {...ATTR_RESTRICT_CLICK_OUTSIDE}>
          <WalletDropdown />
        </DropdownContainerSmall>
        <DropdownContainerLarge ref={walletRef}>
          <WalletDropdown />
        </DropdownContainerLarge>
      </Portal>
    </span>
  )
}
