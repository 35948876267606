// import diviLogo from '../../../../assets/images/divi-logo.svg'
import { TimePeriod } from '../../../../graphql/data/util'
import ChartApi, { DayPrice, HourPrice, ResponseChartData } from './ChartApi'
import { ChartData, CurrencyInfo } from './index'

const CURRENCY_1: CurrencyInfo = {
  name: 'ETH',
  icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
}

const CURRENCY_2: CurrencyInfo = {
  name: 'DIVI',
  // icon: diviLogo,
  icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3441.png',
}

const DAYS_FOR_PERIOD = {
  [TimePeriod.HOUR]: 0,
  [TimePeriod.DAY]: 0,
  [TimePeriod.WEEK]: 7,
  [TimePeriod.MONTH]: 30,
  [TimePeriod.YEAR]: 365,
}

export default class ChartDataService {
  private rawData?: ResponseChartData
  private diviData: Record<TimePeriod, ChartData[]> = {
    [TimePeriod.HOUR]: [],
    [TimePeriod.DAY]: [],
    [TimePeriod.WEEK]: [],
    [TimePeriod.MONTH]: [],
    [TimePeriod.YEAR]: [],
  }

  private ethData: Record<TimePeriod, ChartData[]> = {
    [TimePeriod.HOUR]: [],
    [TimePeriod.DAY]: [],
    [TimePeriod.WEEK]: [],
    [TimePeriod.MONTH]: [],
    [TimePeriod.YEAR]: [],
  }

  public async init(): Promise<void> {
    this.rawData = await ChartApi.getRawData()
  }

  public get diviCurrency(): CurrencyInfo {
    return CURRENCY_2
  }

  public get ethCurrency(): CurrencyInfo {
    return CURRENCY_1
  }

  public getChartDiviToEth(period: TimePeriod): ChartData[] {
    if ((this.diviData[period] as ChartData[]).length > 0) {
      return this.diviData[period]
    }

    this.diviData[period] = period === TimePeriod.DAY ? this.calculateHourDatas(period) : this.calculateDayDatas(period)

    return this.diviData[period]
  }

  public getChartEthToDivi(timePeriod: TimePeriod): ChartData[] {
    const data = this.getChartDiviToEth(timePeriod)

    return data.map((item) => {
      return {
        timestamp: item.timestamp,
        value: 1 / item.value,
      }
    })
  }

  private calculateHourDatas(timePeriod: TimePeriod): ChartData[] {
    if (!this.rawData?.pairHourDatas || timePeriod !== TimePeriod.DAY) {
      return []
    }

    const minimumTimestamp = this.get24HoursBeforeTimestamp()

    let hours = this.rawData.pairHourDatas.filter((item: HourPrice) => item.hourStartUnix >= minimumTimestamp)

    hours = hours.reverse()

    if ((hours.length === 0 || hours.length === 1) && this.rawData.pairHourDatas.length >= 1) {
      const _hour = this.rawData.pairHourDatas[this.rawData.pairHourDatas.length - 1]

      const firstHour = JSON.parse(JSON.stringify(_hour))

      firstHour.hourStartUnix = minimumTimestamp

      const lastHour = this._copy(_hour)

      lastHour.hourStartUnix = this.getCurrentTimestamp()

      hours = [firstHour, lastHour]
    }

    const result = this._mapHours(hours)

    return result
  }

  private calculateDayDatas(timePeriod: TimePeriod): ChartData[] {
    const periodDays = DAYS_FOR_PERIOD[timePeriod]

    if (!this.rawData?.pairDayDatas || !periodDays) {
      return []
    }

    const minimumTimestamp = this.getMinimumTimestamp(periodDays)

    let days = this.rawData.pairDayDatas.filter((item: DayPrice) => item.date >= minimumTimestamp)

    days = days.reverse()

    if (days.length === 0 && this.rawData.pairDayDatas.length >= 1) {
      const _day = this.rawData.pairDayDatas[this.rawData.pairDayDatas.length - 1]

      const firstDay = this._copy(_day)

      firstDay.date = minimumTimestamp

      const lastDay = JSON.parse(JSON.stringify(_day))

      lastDay.date = this.getCurrentTimestamp()

      days = [firstDay, lastDay]
    } else if (days.length < periodDays) {
      const lastDay = this._copy(days[days.length - 1])

      lastDay.date = this.getCurrentTimestamp()

      days.push(lastDay)
    }

    return this._mapDays(days)
  }

  private _mapDays(days: DayPrice[]) {
    return days.map((item: DayPrice): ChartData => {
      return {
        timestamp: item.date,
        value: item.reserve1 / item.reserve0,
      }
    })
  }

  private _mapHours(days: HourPrice[]) {
    return days.map((item: HourPrice): ChartData => {
      return {
        timestamp: item.hourStartUnix,
        value: item.reserve1 / item.reserve0,
      }
    })
  }

  private getMinimumTimestamp(days: number): number {
    const _date = new Date()
    _date.setDate(_date.getDate() - days)
    _date.setUTCHours(0, 0, 0, 0)

    return parseInt(_date.getTime().toString().slice(0, -3))
  }

  get24HoursBeforeTimestamp() {
    const _date = new Date()

    _date.setDate(_date.getDate() - 1)

    return parseInt(_date.getTime().toString().slice(0, -3))
  }

  private getCurrentTimestamp(): number {
    return parseInt(Date.now().toString().slice(0, -3))
  }

  private _copy<T>(item: T): T {
    return JSON.parse(JSON.stringify(item))
  }
}
