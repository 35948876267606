import { ORACLE_ENDPOINT } from 'constants/misc'

export default class DiviPriceApi {
  private static URL_API = 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2'
  private static EDIVI_PRICE_ENDPOINT = ORACLE_ENDPOINT + 'getDiviPrice'
  private static ADRESS_DIVI = '0x246908bff0b1ba6ecadcf57fb94f6ae2fcd43a77'
  private static ETH_PRICE_QUERY = `
query EthPriceData($tokenAddress: ID!) {  
  bundles(where: { id: 1 }) {
        ethPrice
    }
  tokens(where: {id: $tokenAddress }) {
    derivedETH
  }
}
`

  static async getDiviPrice(): Promise<number> {
    const response = await fetch(this.URL_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: this.ETH_PRICE_QUERY,
        variables: {
          tokenAddress: this.ADRESS_DIVI,
        },
      }),
    })

    const respJson = await response.json()

    const ethPrice = (respJson as any).data?.bundles[0].ethPrice
    const derivedETH = (respJson as any).data?.tokens[0].derivedETH

    return ethPrice * derivedETH || 0
  }

  static async getDiviDisplayPrice(): Promise<number> {
    const response = await fetch(this.EDIVI_PRICE_ENDPOINT)

    const respJson = await response.json()

    const diviPrice = (respJson as any)?.price

    return diviPrice
  }

  static async getEthPrice(): Promise<number> {
    const response = await fetch(this.URL_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: this.ETH_PRICE_QUERY,
        variables: {
          tokenAddress: this.ADRESS_DIVI,
        },
      }),
    })

    const respJson = await response.json()

    const ethPrice = (respJson as any).data.bundles[0].ethPrice

    return ethPrice
  }
}
