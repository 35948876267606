import 'react-tabs/style/react-tabs.css'

import { useWeb3React } from '@web3-react/core'
import { PageName } from 'analytics/constants'
import { Trace } from 'analytics/Trace'
import { filterStringAtom } from 'components/Tokens/state'
import SearchBar from 'components/Tokens/TokenTable/SearchBar'
import { SupportedChainId } from 'constants/chains'
import { useResetAtom } from 'jotai/utils'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import Switch from 'react-switch'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { DIVIStakingCenter } from '../../utils/staking'

import emptyBG from '../../assets/images/empty-bg.png'
import emptyBGsm from '../../assets/images/empty-bg-sm.png'
import TableFarms from './TableFarms'

const EmptyContainer = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${emptyBG});
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    height: calc(100vh - 64px);
    background-image: url(${emptyBGsm});
  }
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 1372px;
  padding: 64px 30px 120px;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    padding: 40px 20px 64px;
  }
`

const TitleContainer = styled.div`
  margin: 0 auto 20px;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  width: 100%;
  justyfy-content: space-between;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 32px;
  }
`

const SubTitleContainer = styled.div`
  margin: 0 auto 14px;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  width: 100%;
  justyfy-content: space-between;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 32px;
  }
`

const Title = styled.h1`
  font-weight: 700;
  color: #fff;
  font-size: 36px;
  line-height: 42px;
  margin: 0 auto;
  font-family: 'GalanoGrotesque-Bold';

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 24px;
    line-height: 1.4;
  }
`

const SubTitle = styled.h4`
  font-weight: 700;
  color: #fff;
  font-size: 24px;
  line-height: 42px;
  margin: 0 auto;
  font-family: 'GalanoGrotesque-Bold';

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 12px;
    line-height: 1.4;
  }
`

const SubTitleCaption = styled.h6`
  font-weight: 700;
  color: #fff;
  font-size: 12px;
  line-height: 42px;
  margin: 0 auto;
  font-family: 'GalanoGrotesque-Bold';
  text-align: center;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 10px;
    line-height: 1.4;
  }
`

const PageSwitcher = styled(TabList)`
  width: 240px;
  height: 44px;
  border: 1px solid #6752f4;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 40px;
  padding: 0;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    margin-bottom: 32px;
  }
`

const SwitchItem = styled(Tab)`
  height: 40px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 1px);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);

  &.react-tabs__tab--selected {
    background: #6752f4;
    color: #fff;
    border-radius: 80px;
  }

  &:focus {
    outline: none;
  }
`

const Content = styled.div`
  position: relative;
`

const FiltersContainer = styled.div`
  display: flex;
  gap: 8px;
  height: 44px;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    flex-direction: column;
    height: auto;
    gap: 16px;
  }
`

const SearchContainer = styled(FiltersContainer)`
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    height: 44px;
    order: 0;
  }
`

const FiltersWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 24px 32px;
  background: #131415;
  border-radius: 20px 20px 0 0;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    padding: 16px;
    background: #1a1b1c;
  }
`

const SwitchLabel = styled.label`
  margin: 0 32px 0 auto;
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    order: 2;
    margin: 0;
  }
`

const SwitchText = styled.span`
  margin-left: 8px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
`

const SelectWrapper = styled.div`
  padding: 10px 16px;
  background: #0d0e0f;
  border: 1px solid #272828;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-radius: 12px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    height: 44px;
  }
`

const SelectDropdown = styled(Select)`
  background: transparent;
  width: 182px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: calc(100% - 60px);
    order: 1;
  }

  .css-1s2u09g-control {
    background: transparent;
    border: none;
    flex-wrap: nowrap;

    &::after {
      display: inline-block;
      content: '';
      height: 6px;
      width: 6px;
      border-bottom: 1.5px solid rgb(133, 135, 143);
      border-right: 1.5px solid rgb(133, 135, 143);
      transform: rotate(45deg);
      position: relative;
      right: 4px;
      top: -2px;
    }

    svg {
      display: none;
    }
  }

  .css-1hb7zxy-IndicatorsContainer {
    padding: 0;
    width: 38px;
    height: 38px;
    margin-right: -10px;
    display: none;

    span {
      display: none;
    }
  }

  .css-qc6sy-singleValue {
    margin: 0;
    color: #fff;
  }

  .css-tlfecz-indicatorContainer {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 0;

    &:hover {
      color: #fff;
    }
  }

  .css-319lph-ValueContainer {
    padding: 0;
    margin-right: 4px;
    width: 142px;
  }

  .css-26l3qy-menu {
    padding: 10px 0;
    background: #0d0e0f;
    border: 1px solid #272828;
    white-space: nowrap;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 3px;
    margin-top: 3px;
    right: -16px;
    min-width: 282px;
  }

  .css-4ljt47-MenuList {
    padding: 0;
    overflow-y: initial;
  }

  .css-1n7v3ny-option {
    background: transparent;
    color: #fff;
    cursor: pointer;

    &:hover {
      color: rgb(187, 178, 243);
    }
  }

  .css-9gakcf-option {
    background: #6752f4;
    color: #fff;
  }

  input {
    display: none;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }
`

const SelectText = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-right: 8px;
`

const TableWrapper = styled.div`
  width: 100%;
  display: block;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
  }
`

const sortOptions = [
  { value: 'hot', label: 'Hot' },
  { value: 'earned', label: 'Rewards' },
  { value: 'apr', label: 'APR' },
  { value: 'liquidity', label: 'Staked Liquidity' },
]

const FarmsV2 = () => {
  const { chainId, provider, account } = useWeb3React()

  if (chainId === SupportedChainId.GOERLI) {
    const stakingCenter = new DIVIStakingCenter(provider, chainId!)
    console.log(stakingCenter)
  }

  const resetFilterString = useResetAtom(filterStringAtom)
  const location = useLocation()

  useEffect(() => {
    resetFilterString()
  }, [location, resetFilterString])

  const [checked, setChecked] = useState(false)
  const handleChange = (nextChecked: any) => {
    setChecked(nextChecked)
  }

  return (
    <>
      <Trace page={PageName.FARMS_PAGE} shouldLogImpression>
        <Container>
          <TitleContainer>
            <ThemedText.LargeHeader>
              <Title>Divi Farm</Title>
            </ThemedText.LargeHeader>
          </TitleContainer>
          <SubTitleContainer>
            <ThemedText.SubHeaderSmall>
              <SubTitle>Hold LP Tokens and Receive Rewards!</SubTitle>
            </ThemedText.SubHeaderSmall>
          </SubTitleContainer>
          <SubTitleContainer>
            <ThemedText.SubHeaderSmall>
              <SubTitleCaption>
                No more staking. No more contract approvals. Hold the tokens below in your wallet and receive your
                rewards straight to your wallet automatically every two weeks via airdrop.
              </SubTitleCaption>
            </ThemedText.SubHeaderSmall>
          </SubTitleContainer>
          <Tabs>
            {/* <PageSwitcher>
              <SwitchItem>Live</SwitchItem>
              <SwitchItem>Finished</SwitchItem>
            </PageSwitcher> */}
            <TabPanel>
              <Content>
                <FiltersWrapper style={{ display: 'none' }}>
                  <FiltersContainer>
                    <SearchContainer>
                      <SearchBar />
                    </SearchContainer>
                    <SwitchLabel>
                      <Switch
                        onChange={handleChange}
                        checked={checked}
                        offColor="#232425"
                        onColor="#6752f4"
                        height={30}
                        width={52}
                        activeBoxShadow="none"
                        handleDiameter={26}
                        offHandleColor="#85878f"
                        onHandleColor="#fff"
                        uncheckedIcon={false}
                        checkedIcon={false}
                      />
                      <SwitchText>Staked only</SwitchText>
                    </SwitchLabel>
                    <SelectWrapper>
                      <SelectText>Sort by:</SelectText>
                      <SelectDropdown options={sortOptions} />
                    </SelectWrapper>
                  </FiltersContainer>
                </FiltersWrapper>
                <TableWrapper>
                  <TableFarms />
                </TableWrapper>
              </Content>
            </TabPanel>
            <TabPanel>
              <Content>
                <FiltersWrapper style={{ display: 'none' }}>
                  <FiltersContainer>
                    <SearchContainer>
                      <SearchBar />
                    </SearchContainer>
                    <SwitchLabel>
                      <Switch
                        onChange={handleChange}
                        checked={checked}
                        offColor="#232425"
                        onColor="#6752f4"
                        height={30}
                        width={52}
                        activeBoxShadow="none"
                        handleDiameter={26}
                        offHandleColor="#85878f"
                        onHandleColor="#fff"
                        uncheckedIcon={false}
                        checkedIcon={false}
                      />
                      <SwitchText>Staked only</SwitchText>
                    </SwitchLabel>
                    <SelectWrapper>
                      <SelectText>Sort by:</SelectText>
                      <SelectDropdown options={sortOptions} />
                    </SelectWrapper>
                  </FiltersContainer>
                </FiltersWrapper>
                <TableWrapper>
                  <TableFarms />
                </TableWrapper>
              </Content>
            </TabPanel>
          </Tabs>
        </Container>
      </Trace>
    </>
  )
}

export default FarmsV2
