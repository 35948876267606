import { AxisBottom, TickFormatter } from '@visx/axis'
import { localPoint } from '@visx/event'
import { EventType } from '@visx/event/lib/types'
import { GlyphCircle } from '@visx/glyph'
import { Line } from '@visx/shape'
import AnimatedInLineChart from 'components/Charts/AnimatedInLineChart'
import { filterTimeAtom } from 'components/Tokens/state'
import { bisect, curveCardinal, NumberValue, scaleLinear, timeDay, timeHour, timeMinute, timeMonth } from 'd3'
import { PricePoint } from 'graphql/data/Token'
import { TimePeriod } from 'graphql/data/util'
import { useActiveLocale } from 'hooks/useActiveLocale'
import { useAtom } from 'jotai'
import { ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { TrendingUp } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'
import {
  dayHourFormatter,
  hourFormatter,
  monthDayFormatter,
  monthTickFormatter,
  monthYearDayFormatter,
  weekFormatter,
  weekFormatterShort,
} from 'utils/formatChartTimes'
import { formatPriceNoCurrency } from 'utils/formatNumbers'

import { ExchangeIcon, HexagonIcon } from '../../../../nft/components/icons'
import { DButton } from '../../../divi/DButton'
import { LARGE_MEDIA_BREAKPOINT } from '../../constants'
import { DISPLAYS, ORDERED_TIMES } from '../../TokenTable/TimeSelector'
import { CurrencyInfo } from './index'

export function formatDelta(delta: number | null | undefined) {
  // Null-check not including zero
  if (delta === null || delta === undefined) {
    return '-'
  }
  let formattedDelta = delta.toFixed(2) + '%'
  if (Math.sign(delta) > 0) {
    formattedDelta = '+' + formattedDelta
  }
  return formattedDelta
}

export const DATA_EMPTY = { value: 0, timestamp: 0 }

export function calculateDelta(start: number, current: number) {
  return (current / start - 1) * 100
}

export const ChartHeader = styled.div`
  display: block;
  float: left;

  @media only screen and (max-width: 1100px) {
    float: none;
  }
`

const LineExchange = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`

const IconTokenWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50px;
  outline: 2px solid #1e1b19;
  background: linear-gradient(45deg, #f28c41 0%, #edaf42 100%), linear-gradient(0deg, #1e1b19, #1e1b19);

  &:nth-of-type(1) {
    margin-right: -7px;
    z-index: 999;
    position: relative;
  }

  img {
    width: 20px;
  }
`

const ExchangeButton = ({ onClickExchange }: { onClickExchange: () => unknown }) => {
  const EButton = styled.button`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 0;
    border: none;
    background: none;
    outline: none;

    .hexagon-icon {
      position: absolute;
      width: 100%;
      z-index: -1;

      path {
        fill: #6752f4;
      }

      &:hover {
        path {
          fill: #7966f9;
        }
      }
    }

    .exchange-icon {
      pointer-events: none;
      cursor: pointer;
      width: 12px;

      path {
        fill: white;
      }
    }
  `

  return (
    <>
      <EButton onClick={onClickExchange}>
        <HexagonIcon className="hexagon-icon" />
        <ExchangeIcon className="exchange-icon" />
      </EButton>
    </>
  )
}

export const TokenName = styled.span`
  padding: 0 16px 0 12px;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-align: left;
`

export const TimeOptionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 1100px) {
    justify-content: flex-start;
  }
`
export const TimeOptionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  width: fit-content;
`
const TimeButton = styled(DButton)`
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  height: 40px;
  width: 78px;
  border-radius: 24px;

  &:not(.selected) {
    & {
      background: #232425;
      border-color: #232425;
    }

    &:hover {
      background: #28292a;
      border-color: #28292a;
    }
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.xl}px`}) {
    font-size: 14px;
    width: 64px;
    height: 36px;
  }
`

const TokenInfoWrapper = styled.div`
  margin-bottom: 24px;
`

const LineToken = styled.div`
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const Value = styled.span`
  vertical-align: middle;
  margin-right: 10px;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.8px;
  text-align: left;
`

const Info = styled.img`
  vertical-align: middle;
  margin-right: 10px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #c3bbaf;
  background-color: #c3bbaf;
`

const Delta = styled.span`
  vertical-align: bottom;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-align: left;

  &.plus {
    color: #34c759;
  }

  &.minus {
    color: #ff0000;
  }
`

const DateTime = styled.span`
  color: #85878f;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.2px;
  text-align: left;
`

const TokenInfo = ({
  price,
  startingPrice,
  date,
  icon,
}: {
  price: number
  startingPrice: number
  date: string
  icon: string
}) => {
  const formattedPrice = formatPriceNoCurrency({ num: price, isPrice: true })
  const delta = calculateDelta(startingPrice, price)

  const getDeltaStr = (price: number, delta: number): string => {
    const deltaSymbol = delta > 0 ? '+' : ''
    const formattedDelta = Math.round(delta * 10000) / 10000
    const deltaPrecision = formatDelta(delta)

    return `${deltaSymbol}${formattedDelta} (${deltaPrecision})`
  }
  const deltaStr = getDeltaStr(price, delta)

  return (
    <>
      <TokenInfoWrapper>
        <LineToken>
          <Value>{formattedPrice}</Value>
          <Info src={icon} alt="" />
          <Delta className={delta > 0 ? 'plus' : 'minus'}>{deltaStr}</Delta>
        </LineToken>
        <LineToken>
          <DateTime>{date}</DateTime>
        </LineToken>
      </TokenInfoWrapper>
    </>
  )
}

const ChartWrapper = styled.div`
  padding: 32px;
  background: ${({ theme }) => theme.backgroundInteractive};
  border-radius: 30px;

  .circle {
    transform: scale(1.3);
  }

  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    .chartSvg {
      margin-left: -32px;
    }
  }
`

const margin = { top: 16, bottom: 32, crosshair: 72 }

interface SwapPriceChartProps {
  prices: PricePoint[] | undefined
  onClickExchange: () => unknown
  currency1: CurrencyInfo
  currency2: CurrencyInfo
}

export function SwapPriceChart({ prices, onClickExchange, currency1, currency2 }: SwapPriceChartProps) {
  const [timePeriod, setTimePeriod] = useAtom(filterTimeAtom)
  const locale = useActiveLocale()
  const theme = useTheme()

  // TODO REF
  const ref = useRef(null)

  const [graphWidth, setGraphWidth] = useState(0)
  const [graphHeight, setGraphHeight] = useState(0)
  const [graphInnerHeight, setGraphInnerHeight] = useState(0)

  const setGraphSize = () => {
    const padding = 32
    const currRef = ref?.current as null | HTMLBaseElement

    const refWidth = currRef?.offsetWidth || 0

    let _graphWidth
    let _graphHeight

    if ((window as any).innerWidth <= 840) {
      _graphWidth = Math.max(refWidth, 0)
      _graphHeight = _graphWidth / 1.5 + margin.bottom
    } else {
      _graphWidth = Math.max(refWidth - padding * 2, 0)
      _graphHeight = _graphWidth / 2.5 + margin.bottom
    }

    const _graphInnerHeight = Math.max(_graphHeight - margin.top - margin.bottom, 0)

    setGraphWidth(_graphWidth)
    setGraphHeight(_graphHeight)
    setGraphInnerHeight(_graphInnerHeight)
  }

  useLayoutEffect(setGraphSize, [ref, graphHeight, graphInnerHeight, graphWidth])

  window.addEventListener('resize', setGraphSize)

  // first price point on the x-axis of the current time period's chart
  const startingPrice = prices?.[0] ?? DATA_EMPTY
  // last price point on the x-axis of the current time period's chart
  const endingPrice = prices?.[prices.length - 1] ?? DATA_EMPTY
  const [displayPrice, setDisplayPrice] = useState(startingPrice)

  // set display price to ending price when prices have changed.
  useEffect(() => {
    if (prices) {
      setDisplayPrice(endingPrice)
    }
  }, [prices, endingPrice])
  const [crosshair, setCrosshair] = useState<number | null>(null)

  // Defining scales
  // x scale
  const timeScale = useMemo(
    () => scaleLinear().domain([startingPrice.timestamp, endingPrice.timestamp]).range([0, graphWidth]),
    [startingPrice, endingPrice, graphWidth]
  )
  // y scale

  const getPriceBounds = (pricePoints: PricePoint[]): [number, number] => {
    const prices = pricePoints.map((x) => x.value)
    const min = Math.min(...prices)
    const max = Math.max(...prices)
    return [min, max]
  }

  const rdScale = useMemo(() => {
    const minMax = getPriceBounds(prices ?? [])
    return scaleLinear().domain(minMax).range([graphInnerHeight, 0])
  }, [prices, graphInnerHeight])

  function tickFormat(
    timePeriod: TimePeriod,
    locale: string
  ): [TickFormatter<NumberValue>, (v: number) => string, NumberValue[]] {
    const offsetTime = (endingPrice.timestamp.valueOf() - startingPrice.timestamp.valueOf()) / 24
    const startDateWithOffset = new Date((startingPrice.timestamp.valueOf() + offsetTime) * 1000)
    const endDateWithOffset = new Date((endingPrice.timestamp.valueOf() - offsetTime) * 1000)
    switch (timePeriod) {
      case TimePeriod.HOUR:
        return [
          hourFormatter(locale),
          dayHourFormatter(locale),
          (timeMinute.every(5) ?? timeMinute)
            .range(startDateWithOffset, endDateWithOffset, 2)
            .map((x) => x.valueOf() / 1000),
        ]
      case TimePeriod.DAY:
        return [
          hourFormatter(locale),
          dayHourFormatter(locale),
          timeHour.range(startDateWithOffset, endDateWithOffset, 4).map((x) => x.valueOf() / 1000),
        ]
      case TimePeriod.WEEK:
        const _weekFormatter = (window as any).innerWidth <= 840 ? weekFormatterShort : weekFormatter

        return [
          _weekFormatter(locale),
          dayHourFormatter(locale),
          timeDay.range(startDateWithOffset, endDateWithOffset, 1).map((x) => x.valueOf() / 1000),
        ]
      case TimePeriod.MONTH:
        return [
          monthDayFormatter(locale),
          dayHourFormatter(locale),
          timeDay.range(startDateWithOffset, endDateWithOffset, 7).map((x) => x.valueOf() / 1000),
        ]
      case TimePeriod.YEAR:
        return [
          monthTickFormatter(locale),
          monthYearDayFormatter(locale),
          timeMonth.range(startDateWithOffset, endDateWithOffset, 2).map((x) => x.valueOf() / 1000),
        ]
    }
  }

  const handleHover = useCallback(
    (event: Element | EventType) => {
      if (!prices) return

      const { x } = localPoint(event) || { x: 0 }
      const x0 = timeScale.invert(x) // get timestamp from the scalexw
      const index = bisect(
        prices.map((x) => x.timestamp),
        x0,
        1
      )

      const d0 = prices[index - 1]
      const d1 = prices[index]
      let pricePoint = d0

      const hasPreviousData = d1 && d1.timestamp
      if (hasPreviousData) {
        pricePoint = x0.valueOf() - d0.timestamp.valueOf() > d1.timestamp.valueOf() - x0.valueOf() ? d1 : d0
      }

      if (pricePoint) {
        setCrosshair(timeScale(pricePoint.timestamp))
        setDisplayPrice(pricePoint)
      }
    },
    [timeScale, prices]
  )

  const resetDisplay = useCallback(() => {
    setCrosshair(null)
    setDisplayPrice(endingPrice)
  }, [setCrosshair, setDisplayPrice, endingPrice])

  const [tickFormatter, crosshairDateFormatter, ticks] = tickFormat(timePeriod, locale)
  const crosshairEdgeMax = graphWidth * 0.85
  const crosshairAtEdge = !!crosshair && crosshair > crosshairEdgeMax
  const hasData = prices && prices.length > 0

  /*
   * Default curve doesn't look good for the HOUR chart.
   * Higher values make the curve more rigid, lower values smooth the curve but make it less "sticky" to real data points,
   * making it unacceptable for shorter durations / smaller variances.
   */
  const curveTension = timePeriod === TimePeriod.HOUR ? 1 : 0.9

  const getX = useMemo(() => (p: PricePoint) => timeScale(p.timestamp), [timeScale])
  const getY = useMemo(() => (p: PricePoint) => rdScale(p.value), [rdScale])
  const curve = useMemo(() => curveCardinal.tension(curveTension), [curveTension])

  return (
    <>
      <ChartWrapper ref={ref}>
        <ChartHeader>
          <LineExchange>
            <IconTokenWrapper>
              <img src={currency1.icon} alt="" />
            </IconTokenWrapper>
            <IconTokenWrapper>
              <img src={currency2.icon} alt="" />
            </IconTokenWrapper>
            <TokenName>
              {currency1.name}-{currency2.name}
            </TokenName>
            <ExchangeButton onClickExchange={onClickExchange} />
          </LineExchange>
          <TokenInfo
            price={displayPrice.value}
            startingPrice={startingPrice.value}
            date={crosshairDateFormatter(displayPrice.timestamp)}
            icon={currency2.icon}
          />
        </ChartHeader>
        <TimeOptionsWrapper>
          <TimeOptionsContainer>
            {ORDERED_TIMES.map((time) => (
              <TimeButton
                key={DISPLAYS[time]}
                className={timePeriod === time ? 'selected' : ''}
                onClick={() => {
                  setTimePeriod(time)
                }}
              >
                {DISPLAYS[time]}
              </TimeButton>
            ))}
          </TimeOptionsContainer>
        </TimeOptionsWrapper>
        {!hasData ? (
          <MissingPriceChart
            width={graphWidth}
            height={graphHeight}
            message={prices && prices.length === 0 ? <NoV3DataMessage /> : <MissingDataMessage />}
          />
        ) : (
          <svg className="chartSvg" width={graphWidth} height={graphHeight}>
            <AnimatedInLineChart
              data={prices}
              getX={getX}
              getY={getY}
              marginTop={margin.top}
              yScale={rdScale}
              curve={curve}
              strokeWidth={2}
              color="#F28C41"
            />
            <AxisBottom
              scale={timeScale}
              stroke={theme.backgroundOutline}
              tickFormat={tickFormatter}
              tickStroke={theme.backgroundOutline}
              tickLength={4}
              hideTicks={true}
              tickTransform={'translate(0 0)'}
              tickValues={ticks}
              top={graphHeight - margin.bottom + 10}
              tickLabelProps={() => ({
                fill: '#85878f',
                fontSize: 12,
                textAnchor: 'middle',
                transform: 'translate(0 0)',
              })}
            />
            {crosshair !== null && (
              <g>
                <text
                  x={crosshair + (crosshairAtEdge ? -4 : 4)}
                  y={margin.crosshair + 10}
                  textAnchor={crosshairAtEdge ? 'end' : 'start'}
                  fontSize={12}
                  fill={theme.textSecondary}
                >
                  {crosshairDateFormatter(displayPrice.timestamp)}
                </text>
                <Line
                  from={{ x: crosshair, y: margin.crosshair }}
                  to={{ x: crosshair, y: graphHeight }}
                  stroke={theme.backgroundOutline}
                  strokeWidth={1}
                  pointerEvents="none"
                  strokeDasharray="4,4"
                />
                <GlyphCircle
                  left={crosshair}
                  top={rdScale(displayPrice.value) + margin.top}
                  size={60}
                  fill="#F28C41"
                  stroke="white"
                  strokeWidth={1.5}
                  className="circle"
                />
              </g>
            )}
            <rect
              x={0}
              y={0}
              width={graphWidth}
              height={graphHeight}
              fill={'transparent'}
              onTouchStart={handleHover}
              onTouchMove={handleHover}
              onMouseMove={handleHover}
              onMouseLeave={resetDisplay}
            />
          </svg>
        )}
      </ChartWrapper>
    </>
  )
}

const StyledMissingChart = styled.svg`
  text {
    font-size: 12px;
    font-weight: 400;
  }
`

const chartBottomPadding = 15

const NoV3DataMessage = () => (
  <span>This token doesn&apos;t have chart data because it hasn&apos;t been traded on Divi v3</span>
)
const MissingDataMessage = () => <span>Missing chart data</span>

function MissingPriceChart({ width, height, message }: { width: number; height: number; message: ReactNode }) {
  const theme = useTheme()
  const midPoint = height / 2 + 45
  return (
    <StyledMissingChart width={width} height={height}>
      <path
        d={`M 0 ${midPoint} Q 104 ${midPoint - 70}, 208 ${midPoint} T 416 ${midPoint}
          M 416 ${midPoint} Q 520 ${midPoint - 70}, 624 ${midPoint} T 832 ${midPoint}`}
        stroke={theme.backgroundOutline}
        fill="transparent"
        strokeWidth="2"
      />
      <TrendingUp stroke={theme.textTertiary} x={0} size={12} y={height - chartBottomPadding - 10} />
      <text y={height - chartBottomPadding} x="20" fill={theme.textTertiary}>
        {message || <span>Missing chart data</span>}
      </text>
      <path
        d={`M 0 ${height - 1}, ${width} ${height - 1}`}
        stroke={theme.backgroundOutline}
        fill="transparent"
        strokeWidth="1"
      />
    </StyledMissingChart>
  )
}

export default SwapPriceChart
