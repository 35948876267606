import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components/macro'

import AuthenticatedHeader from './AuthenticatedHeader'
import { MenuState } from './index'

const DefaultMenuWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 8px;
`

const WalletDropdown = ({ setMenu }: { setMenu: (state: MenuState) => void }) => {
  const { account } = useWeb3React()
  const isAuthenticated = !!account

  return (
    <>
      {isAuthenticated && (
        <DefaultMenuWrap>
          <AuthenticatedHeader />
        </DefaultMenuWrap>
      )}
    </>
  )
}

export default WalletDropdown
