import { useState } from 'react'
import { ImageProps } from 'rebass'
import styled from 'styled-components/macro'

// import diviLogo from '../../assets/images/divi-logo.svg'

const BAD_SRCS: { [tokenAddress: string]: true } = {}
const DIVI_SRC = 'https://s2.coinmarketcap.com/static/img/coins/64x64/3441.png'

interface LogoProps extends Pick<ImageProps, 'style' | 'alt' | 'className'> {
  srcs: string[]
  symbol?: string
  size?: string
}

const MissingImageLogo = styled.div<{ size?: string }>`
  --size: ${({ size }) => size};
  border-radius: 100px;
  color: ${({ theme }) => theme.textPrimary};
  background-color: #000;
  font-size: 50%;
  font-weight: 500;
  height: ${({ size }) => size ?? '24px'};
  line-height: ${({ size }) => size ?? '24px'};
  text-align: center;
  width: ${({ size }) => size ?? '24px'};
  margin-right: 4px;
  border: 2px solid #0d0c0c;
  box-sizing: content-box;
`

const MissingImage = styled.img`
  width: 100%;
  height: auto;
`

/**
 * Renders an image by sequentially trying a list of URIs, and then eventually a fallback triangle alert
 */
export default function Logo({ srcs, alt, style, size, symbol, ...rest }: LogoProps) {
  const [, refresh] = useState<number>(0)

  const src: string | undefined = srcs.find((src) => !BAD_SRCS[src])

  // if (src && src !== DIVI_SRC) {
  if (src) {
    return (
      <div {...rest} style={style}>
        <img
          alt={alt}
          src={src}
          style={{ width: '100%', height: 'auto', borderRadius: '50%' }}
          onError={() => {
            if (src) BAD_SRCS[src] = true
            refresh((i) => i + 1)
          }}
        />
      </div>
    )
  } else if (symbol === 'DIVI') {
    return (
      <MissingImageLogo {...rest} style={style} size={size}>
        <MissingImage src="https://s2.coinmarketcap.com/static/img/coins/64x64/3441.png" />
      </MissingImageLogo>
    )
  }

  return (
    <MissingImageLogo {...rest} style={style} size={size}>
      {/* use only first 3 characters of Symbol for design reasons */}
      {symbol?.toUpperCase().replace('$', '').replace(/\s+/g, '').slice(0, 3)}
    </MissingImageLogo>
  )
}
