import styled from 'styled-components/macro'

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1372px;
  padding: 0 30px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    padding: 0 20px;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 40px;
  row-gap: 40px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.xl}px`}) {
    flex-direction: column;
    align-items: center;
  }
`

export const Title = styled.h1`
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
  color: #fff;
  margin: 0 auto 40px;
  text-align: center;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 24px;
    line-heigh: 1.4;
    margin-bottom: 32px;
  }
`

export const PageSwitcher = styled.div`
  width: 240px;
  height: 44px;
  border: 1px solid #6752f4;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 40px;
`

export const SwitchItem = styled.div`
  height: 40px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 1px);
  cursor: pointer;
`

export const SwitchItemActive = styled(SwitchItem)`
  background: #6752f4;
`

export const SwitchText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
`

export const SwapColumn = styled.div`
  flex-basis: 410px;
  flex-grow: 1;
  max-width: 630px;
  min-width: 410px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.xl}px`}) {
    width: 100%;
    min-width: 320px;
  }
`

export const ChartColumn = styled.div`
  min-width: calc(100% - 450px);

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.xl}px`}) {
    width: 100%;
  }
`
