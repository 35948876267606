import { filterTimeAtom } from 'components/Tokens/state'
import { useAtomValue } from 'jotai/utils'
import { useCallback, useEffect, useState } from 'react'

import ChartDataService from './ChartDataService'
import SwapPriceChart from './SwapPriceChart'

export interface CurrencyInfo {
  icon: string
  name: string
}

export interface ChartData {
  timestamp: number
  value: number
}

function ChartSwap() {
  const timePeriod = useAtomValue(filterTimeAtom)
  const [isDataReversed, setIsDataReversed] = useState<boolean>(false)
  const [currentData, setCurrentData] = useState<ChartData[]>()
  const [currency1, setCurrency1] = useState<CurrencyInfo>()
  const [currency2, setCurrency2] = useState<CurrencyInfo>()
  const [chartService, setChartService] = useState<ChartDataService>()

  const setData = useCallback(
    (service: ChartDataService) => {
      let _currentData = service.getChartDiviToEth(timePeriod)
      let _currency1 = service.diviCurrency
      let _currency2 = service.ethCurrency

      if (isDataReversed) {
        _currentData = service.getChartEthToDivi(timePeriod)

        _currency1 = service.ethCurrency
        _currency2 = service.diviCurrency
      }

      setCurrency1(_currency1)
      setCurrency2(_currency2)
      setCurrentData(_currentData)
    },
    [isDataReversed, timePeriod]
  )

  useEffect(() => {
    const service = new ChartDataService()

    service.init().then(() => {
      setData(service)
      setChartService(service)
    })
  }, [setData])

  useEffect(() => {
    if (chartService) {
      setData(chartService)
    }
  }, [isDataReversed, timePeriod, chartService, setData])

  const onClickExchange = (): void => {
    setIsDataReversed(!isDataReversed)
  }

  return (
    <>
      {currency1 && currency2 && currentData && (
        <SwapPriceChart
          key={`${timePeriod}-${isDataReversed}`}
          prices={currentData}
          onClickExchange={onClickExchange}
          currency1={currency1}
          currency2={currency2}
        />
      )}
    </>
  )
}

export default ChartSwap
