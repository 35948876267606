import { Currency, CurrencyAmount, Fraction, Percent } from '@uniswap/sdk-core'
import { Text } from 'rebass'

import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { RowBetween, RowFixed } from '../../components/Row'
import { Field } from '../../state/mint/actions'
import { ThemedText } from '../../theme'

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
}: {
  noLiquidity?: boolean
  price?: Fraction
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  poolTokenPercentage?: Percent
  onAdd: () => void
}) {
  return (
    <>
      <AutoColumn
        style={{ marginTop: '10px', background: '#0d0e0f', borderRadius: '15px', padding: '16px', gridRowGap: '12px' }}
      >
        <RowBetween style={{ alignItems: 'flex-start' }}>
          <ThemedText.DeprecatedBody style={{ whiteSpace: 'nowrap' }} fontSize={14}>
            <span>{currencies[Field.CURRENCY_A]?.symbol} Deposited</span>
          </ThemedText.DeprecatedBody>
          <RowFixed style={{ width: '50%', wordBreak: 'break-all', textAlign: 'right', justifyContent: 'flex-end' }}>
            <ThemedText.DeprecatedBody fontSize={14}>
              {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
            </ThemedText.DeprecatedBody>
          </RowFixed>
        </RowBetween>
        <RowBetween style={{ alignItems: 'flex-start' }}>
          <ThemedText.DeprecatedBody style={{ whiteSpace: 'nowrap' }} fontSize={14}>
            <span>{currencies[Field.CURRENCY_B]?.symbol} Deposited</span>
          </ThemedText.DeprecatedBody>
          <RowFixed style={{ width: '50%', wordBreak: 'break-all', textAlign: 'right', justifyContent: 'flex-end' }}>
            <ThemedText.DeprecatedBody fontSize={14}>
              {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
            </ThemedText.DeprecatedBody>
          </RowFixed>
        </RowBetween>
        <RowBetween style={{ alignItems: 'flex-start' }}>
          <ThemedText.DeprecatedBody style={{ whiteSpace: 'nowrap' }} fontSize={14}>
            <span>Rates</span>
          </ThemedText.DeprecatedBody>
          <ThemedText.DeprecatedBody fontSize={14}>
            {`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${price?.toSignificant(4)} ${
              currencies[Field.CURRENCY_B]?.symbol
            }`}
          </ThemedText.DeprecatedBody>
        </RowBetween>
        <RowBetween style={{ justifyContent: 'flex-end', alignItems: 'flex-start' }}>
          <ThemedText.DeprecatedBody fontSize={14}>
            {`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${price?.invert().toSignificant(4)} ${
              currencies[Field.CURRENCY_A]?.symbol
            }`}
          </ThemedText.DeprecatedBody>
        </RowBetween>
        <RowBetween style={{ alignItems: 'flex-start' }}>
          <ThemedText.DeprecatedBody style={{ color: '#fff' }} fontSize={14}>
            <span>Share of pool: </span>
          </ThemedText.DeprecatedBody>
          <ThemedText.DeprecatedBody
            style={{ width: '50%', wordBreak: 'break-all', textAlign: 'right', justifyContent: 'flex-end' }}
            fontSize={14}
          >
            <span>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</span>
          </ThemedText.DeprecatedBody>
        </RowBetween>
      </AutoColumn>
      <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onAdd}>
        <Text fontWeight={500} fontSize={16}>
          {noLiquidity ? <span>Create Pool & Supply</span> : <span>Confirm Supply</span>}
        </Text>
      </ButtonPrimary>
    </>
  )
}
