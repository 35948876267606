import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ElementName, Event, EventName } from 'analytics/constants'
import { TraceEvent } from 'analytics/TraceEvent'
import { sendEvent } from 'components/analytics'
import { Box } from 'nft/components/Box'
import { HelpIcon } from 'nft/components/icons'
import { useCallback, useState } from 'react'
import { Plus } from 'react-feather'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Text } from 'rebass'

import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { BlueCard, LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import RangeSlider from '../../components/RangeSlider'
import Row, { RowBetween, RowFlat } from '../../components/Row'
import { RowFixed } from '../../components/Row'
import { MouseoverTooltip } from '../../components/Tooltip'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import { ZERO_PERCENT } from '../../constants/misc'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useV2RouterContract } from '../../hooks/useContract'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { PairState } from '../../hooks/useV2Pairs'
import { useToggleWalletModal } from '../../state/application/hooks'
import { useCurrencyBalance } from '../../state/connection/hooks'
import { Field } from '../../state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../state/mint/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionType } from '../../state/transactions/types'
import { useIsExpertMode, useUserSlippageToleranceWithDefault } from '../../state/user/hooks'
import { ThemedText } from '../../theme'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { calculateSlippageAmount } from '../../utils/calculateSlippageAmount'
import { currencyId } from '../../utils/currencyId'
import { formatCurrencyAmount } from '../../utils/formatCurrencyAmount'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'
import { Dots, Wrapper } from '../Pool/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
import { PoolPriceBar } from './PoolPriceBar'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'

const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

interface AddLiquidityProps {
  hideInfoTooltips?: boolean
}

export default function AddLiquidity({ hideInfoTooltips = false }: AddLiquidityProps) {
  const { currencyIdA, currencyIdB } = useParams<{ currencyIdA?: string; currencyIdB?: string }>()
  const navigate = useNavigate()
  const { account, chainId, provider } = useWeb3React()

  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  const toggleWalletModal = useToggleWalletModal() // toggle wallet when disconnected

  const expertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const currency = currencies[Field.CURRENCY_A] ?? null
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)

  const formattedSelectedCurrBalance = formatCurrencyAmount(selectedCurrencyBalance, 4)

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE) // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }
  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {}
  )
  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )

  const router = useV2RouterContract()

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], router?.address)
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], router?.address)

  const addTransaction = useTransactionAdder()

  async function onAdd() {
    if (!chainId || !provider || !account || !router) return

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? ZERO_PERCENT : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? ZERO_PERCENT : allowedSlippage)[0],
    }

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null
    if (currencyA.isNative || currencyB.isNative) {
      const tokenBIsETH = currencyB.isNative
      estimate = router.estimateGas.addLiquidityETH
      method = router.addLiquidityETH
      args = [
        (tokenBIsETH ? currencyA : currencyB)?.wrapped?.address ?? '', // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).quotient.toString(), // token desired
        amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadline.toHexString(),
      ]
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).quotient.toString())
    } else {
      estimate = router.estimateGas.addLiquidity
      method = router.addLiquidity
      args = [
        currencyA?.wrapped?.address ?? '',
        currencyB?.wrapped?.address ?? '',
        parsedAmountA.quotient.toString(),
        parsedAmountB.quotient.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline.toHexString(),
      ]
      value = null
    }

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then((response) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            type: TransactionType.ADD_LIQUIDITY_V2_POOL,
            baseCurrencyId: currencyId(currencyA),
            expectedAmountBaseRaw: parsedAmounts[Field.CURRENCY_A]?.quotient.toString() ?? '0',
            quoteCurrencyId: currencyId(currencyB),
            expectedAmountQuoteRaw: parsedAmounts[Field.CURRENCY_B]?.quotient.toString() ?? '0',
          })

          setTxHash(response.hash)

          sendEvent({
            category: 'Liquidity',
            action: 'Add',
            label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/'),
          })

          if (response.hash) {
            const addLiquidityObject = {
              chainId,
              inputSymbol: currencies[Field.CURRENCY_A]?.symbol,
              outputSymbol: currencies[Field.CURRENCY_B]?.symbol,
              inputAmount: parsedAmounts[Field.CURRENCY_A]?.toSignificant(6),
              outputAmount: parsedAmounts[Field.CURRENCY_B]?.toSignificant(6),
              lpAmount: liquidityMinted?.toSignificant(6),
              transactionDate: new Date().toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric' }),
              txnLink: getExplorerLink(chainId, response.hash, ExplorerDataType.TRANSACTION),
            }

            let swapArray = []
            if (localStorage.hasOwnProperty('addLiquidityArray')) {
              const addLiquidityArray = localStorage.getItem('addLiquidityArray') || '[]'
              swapArray = JSON.parse(addLiquidityArray)
            }

            swapArray.push(addLiquidityObject)
            localStorage.setItem('addLiquidityArray', JSON.stringify(swapArray))
          }
        })
      )
      .catch((error) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px">
        <LightCard $borderRadius="20px">
          <RowFlat>
            <Box style={{ marginLeft: '10px' }}>
              <DoubleCurrencyLogo
                currency0={currencies[Field.CURRENCY_A]}
                currency1={currencies[Field.CURRENCY_B]}
                size={50}
              />
            </Box>
            <Text fontSize="20px" fontWeight={500} lineHeight="30px" marginLeft={10}>
              {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
            </Text>
          </RowFlat>
        </LightCard>
      </AutoColumn>
    ) : (
      <AutoColumn gap="20px">
        <RowFlat style={{ alignItems: 'center' }}>
          <Box style={{ width: '36px', height: '36px', margin: '0 26px 0 0' }}>
            <DoubleCurrencyLogo
              currency1={currencies[Field.CURRENCY_B]}
              currency0={currencies[Field.CURRENCY_A]}
              size={50}
            />
          </Box>
          <Text fontSize="20px" fontWeight={500} lineHeight="42px" marginLeft={10}>
            {liquidityMinted?.toSignificant(6)}
          </Text>
        </RowFlat>
        <Row>
          <Text fontSize="14px" style={{ color: '#85878f', marginBottom: '10px' }}>
            {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol + ' Pool Tokens'}
          </Text>
        </Row>
        <ThemedText.DeprecatedItalic
          fontSize={12}
          textAlign="left"
          padding={'16px'}
          style={{ background: '#0d0e0f', borderRadius: '15px' }}
        >
          <span>
            Output is estimated. If the price changes by more than {allowedSlippage.toSignificant(4)}% your transaction
            will revert.
          </span>
        </ThemedText.DeprecatedItalic>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingText = (
    <span>
      Supplying {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} {currencies[Field.CURRENCY_A]?.symbol} and{' '}
      {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} {currencies[Field.CURRENCY_B]?.symbol}
    </span>
  )

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA)
      if (newCurrencyIdA === currencyIdB) {
        navigate(`/add/${currencyIdB}/${currencyIdA}`)
      } else {
        navigate(`/add/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyIdB, navigate, currencyIdA]
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          navigate(`/add/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          navigate(`/add/${newCurrencyIdB}`)
        }
      } else {
        navigate(`/add/${currencyIdA ? currencyIdA : 'ETH'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, navigate, currencyIdB]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const { pathname } = useLocation()
  const isCreate = pathname.includes('/create')

  const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  const formatValue = (maxValue: string): number => {
    const _maxValue = maxValue.replace(',', '')

    return Number(_maxValue) || 0
  }

  const onChangeValue = (value: number): void => {
    balanceCurrency0 > balanceCurrency1 ? onFieldAInput(value.toString()) : onFieldBInput(value.toString())
  }

  const currency0 = currencies[Field.CURRENCY_A] ?? null
  const currency1 = currencies[Field.CURRENCY_B] ?? null
  const selectedCurrencyBalance0 = useCurrencyBalance(account ?? undefined, currency0 ?? undefined)
  const selectedCurrencyBalance1 = useCurrencyBalance(account ?? undefined, currency1 ?? undefined)
  const formattedSelectedCurrBalance0 = formatValue(formatCurrencyAmount(selectedCurrencyBalance0, 4))
  const formattedSelectedCurrBalance1 = formatValue(formatCurrencyAmount(selectedCurrencyBalance1, 4))
  const balanceCurrency0 = Number(price?.toSignificant(6)) * formattedSelectedCurrBalance0
  const balanceCurrency1 = Number(price?.toSignificant(6)) * formattedSelectedCurrBalance1

  return (
    <>
      <AppBody>
        <AddRemoveTabs creating={isCreate} adding={true} defaultSlippage={DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE} />
        <Wrapper>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            content={() => (
              <ConfirmationModalContent
                title={noLiquidity ? <span>You are creating a pool</span> : <span>You will receive ...</span>}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={pendingText}
            currencyToAdd={pair?.liquidityToken}
          />
          <AutoColumn gap="20px">
            {noLiquidity ||
              (isCreate ? (
                <ColumnCenter>
                  <BlueCard>
                    <AutoColumn gap="10px">
                      <ThemedText.DeprecatedLink fontWeight={600} color={'#fff'}>
                        <span>You are the first liquidity provider.</span>
                      </ThemedText.DeprecatedLink>
                      <ThemedText.DeprecatedLink fontWeight={400} color={'#fff'}>
                        <span>The ratio of tokens you add will set the price of this pool.</span>
                      </ThemedText.DeprecatedLink>
                      <ThemedText.DeprecatedLink fontWeight={400} color={'#fff'}>
                        <span>Once you are happy with the rate click supply to review.</span>
                      </ThemedText.DeprecatedLink>
                    </AutoColumn>
                  </BlueCard>
                </ColumnCenter>
              ) : (
                <AutoColumn>
                  <RowFixed style={{ whiteSpace: 'nowrap', marginBottom: '6px' }}>
                    <MouseoverTooltip
                      text={
                        <span>
                          When you add liquidity, you will receive pool tokens representing your position. These tokens
                          automatically earn fees proportional to your share of the pool, and can be redeemed at any
                          time.
                        </span>
                      }
                      disableHover={hideInfoTooltips}
                    >
                      <ThemedText.DeprecatedSubHeader fontSize={16}>
                        <span>Add liquidity to receive LP tokens</span>
                        <HelpIcon style={{ marginLeft: '8px', position: 'relative', top: '5px' }} />
                      </ThemedText.DeprecatedSubHeader>
                    </MouseoverTooltip>
                  </RowFixed>
                </AutoColumn>
              ))}
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={(value) => {
                const _value = value.replace(',', '')

                onFieldAInput(_value)
              }}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
              }}
              onCurrencySelect={handleCurrencyASelect}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              currency={currency}
              id="add-liquidity-input-tokena"
              showCommonBases
            />
            <RangeSlider
              value={
                balanceCurrency0 > balanceCurrency1
                  ? formatValue(formattedAmounts[Field.CURRENCY_A])
                  : formatValue(formattedAmounts[Field.CURRENCY_B])
                // formatValue(formattedAmounts[Field.CURRENCY_A])
              }
              maxValue={
                balanceCurrency0 > balanceCurrency1
                  ? formatValue(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '') ||
                    formatValue(formattedSelectedCurrBalance)
                  : formatValue(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '') ||
                    formatValue(formattedSelectedCurrBalance)
              }
              onChangeValue={onChangeValue}
              disabled={
                formattedSelectedCurrBalance === '-' ||
                !(
                  formatValue(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '') ||
                  formatValue(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '') ||
                  formatValue(formattedSelectedCurrBalance)
                )
              }
            />
            <ColumnCenter>
              <Plus size="20" style={{ margin: '6px 0', color: '#85878f' }} />
            </ColumnCenter>
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                const _val =
                  formatValue(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '') ||
                  formatValue(formattedSelectedCurrBalance)

                onFieldBInput(_val.toString())
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
              currency={currencies[Field.CURRENCY_B] ?? null}
              id="add-liquidity-input-tokenb"
              showCommonBases
            />
            {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
              <>
                <LightCard
                  padding="0px"
                  $borderRadius={'20px'}
                  style={{ marginTop: '16px', background: 'transparent', border: 'none' }}
                >
                  <RowBetween padding="0 0 16px">
                    <ThemedText.DeprecatedSubHeader fontWeight={600} fontSize={16}>
                      {noLiquidity ? <span>Initial prices and pool share</span> : <span>Prices and pool share</span>}
                    </ThemedText.DeprecatedSubHeader>
                  </RowBetween>{' '}
                  <LightCard padding="1rem" style={{ border: 'none', borderRadius: '15px' }}>
                    <PoolPriceBar
                      currencies={currencies}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                    />
                  </LightCard>
                </LightCard>
              </>
            )}

            {addIsUnsupported ? (
              <ButtonPrimary disabled={true}>
                <ThemedText.DeprecatedMain mb="4px">
                  <span>Unsupported Asset</span>
                </ThemedText.DeprecatedMain>
              </ButtonPrimary>
            ) : !account ? (
              <TraceEvent
                events={[Event.onClick]}
                name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                properties={{ received_swap_quote: false }}
                element={ElementName.CONNECT_WALLET_BUTTON}
              >
                <ButtonLight onClick={toggleWalletModal}>
                  <span>Connect Wallet</span>
                </ButtonLight>
              </TraceEvent>
            ) : (
              <AutoColumn gap={'md'} style={{ marginTop: '32px' }}>
                {(approvalA === ApprovalState.NOT_APPROVED ||
                  approvalA === ApprovalState.PENDING ||
                  approvalB === ApprovalState.NOT_APPROVED ||
                  approvalB === ApprovalState.PENDING) &&
                  isValid && (
                    <RowBetween>
                      {approvalA !== ApprovalState.APPROVED && (
                        <ButtonPrimary
                          onClick={approveACallback}
                          disabled={approvalA === ApprovalState.PENDING}
                          width={approvalB !== ApprovalState.APPROVED ? '48%' : '100%'}
                        >
                          {approvalA === ApprovalState.PENDING ? (
                            <Dots>
                              <span>Approving {currencies[Field.CURRENCY_A]?.symbol}</span>
                            </Dots>
                          ) : (
                            <span>Approve {currencies[Field.CURRENCY_A]?.symbol}</span>
                          )}
                        </ButtonPrimary>
                      )}
                      {approvalB !== ApprovalState.APPROVED && (
                        <ButtonPrimary
                          onClick={approveBCallback}
                          disabled={approvalB === ApprovalState.PENDING}
                          width={approvalA !== ApprovalState.APPROVED ? '48%' : '100%'}
                        >
                          {approvalB === ApprovalState.PENDING ? (
                            <Dots>
                              <span>Approving {currencies[Field.CURRENCY_B]?.symbol}</span>
                            </Dots>
                          ) : (
                            <span>Approve {currencies[Field.CURRENCY_B]?.symbol}</span>
                          )}
                        </ButtonPrimary>
                      )}
                    </RowBetween>
                  )}
                <ButtonError
                  onClick={() => {
                    expertMode ? onAdd() : setShowConfirm(true)
                  }}
                  disabled={!isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED}
                  error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                >
                  <Text fontSize={16} fontWeight={500}>
                    {error ?? <span>Supply</span>}
                  </Text>
                </ButtonError>
              </AutoColumn>
            )}
          </AutoColumn>
        </Wrapper>
      </AppBody>
    </>
  )
}
