export default class LiquidityApi {
  private static URL_API = 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2'
  private static ADRESS_PAIR = ['0x011a4318e7a45927004018dc88bec93953476615']
  private static PAIRS_BULK = `
query pairs($allPairs: [Bytes]!) {  
  pairs(first: 1, where: { id_in: $allPairs }, orderBy: trackedReserveETH, orderDirection: desc) {
    reserve0
    reserveUSD
  }
}
`

  static async getPairLiquidity(): Promise<number[]> {
    const response = await fetch(this.URL_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: this.PAIRS_BULK,
        variables: {
          allPairs: this.ADRESS_PAIR,
        },
      }),
    })

    const respJson = await response.json()
    const liquidity = (respJson as any).data?.pairs[0].reserveUSD || 0
    const tokenLiquidity = (respJson as any).data?.pairs[0].reserve0 || 0

    return [liquidity, tokenLiquidity]
  }
}
