import { RedesignVariant, useRedesignFlag } from 'featureFlags/flags/redesign'
import React from 'react'
import styled from 'styled-components/macro'
import { Z_INDEX } from 'theme/zIndex'

export const BodyWrapper = styled.main<{ margin?: string; maxWidth?: string; redesignFlag?: boolean }>`
  position: relative;
  max-width: 630px;
  width: 100%;
  background: #131415;
  border-radius: 30px;
  margin: 64px auto;
  z-index: ${Z_INDEX.deprecated_content};
  font-feature-settings: ${({ redesignFlag }) =>
    redesignFlag ? "'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on" : "'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on"};

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    margin: 64px 20px;
    width: calc(100% - 40px);
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, ...rest }: { children: React.ReactNode }) {
  const redesignFlag = useRedesignFlag()
  const redesignFlagEnabled = redesignFlag === RedesignVariant.Enabled
  return (
    <BodyWrapper {...rest} redesignFlag={redesignFlagEnabled}>
      {children}
    </BodyWrapper>
  )
}
