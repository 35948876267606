import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { MetaMask } from '@web3-react/metamask'
import MAGIC_ICON_URL from 'assets/images/magicLinkIcon.png'
import DiviPriceApi from 'components/Tokens/TokenDetails/ChartSwap/DiviPriceApi'
import { getConnection } from 'connection/utils'
import { getChainInfoOrDefault } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import { DIVI, DIVI_GOERLI, DIVI_POLYGON, DIVI_POLYGON_MUMBAI } from 'constants/tokens'
import { NftVariant, useNftFlag } from 'featureFlags/flags/nft'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { useStablecoinUSDPriceHeader } from 'hooks/useStablecoinPrice'
import useCurrencyLogoURIs from 'lib/hooks/useCurrencyLogoURIs'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useCallback, useMemo, useState } from 'react'
import { Copy, ExternalLink, Power } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { useCurrencyBalanceString } from 'state/connection/hooks'
import { useAppDispatch } from 'state/hooks'
import { updateSelectedWallet } from 'state/user/reducer'
import styled from 'styled-components/macro'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

import { MagicConnector } from '../../connection/MagicConntector'
import { useCurrency } from '../../hooks/Tokens'
import { shortenAddress } from '../../nft/utils/address'
import { useCloseModal, useToggleModal } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { useUserHasAvailableClaim, useUserUnclaimedAmount } from '../../state/claim/hooks'
import { useCurrencyBalance } from '../../state/connection/hooks'
import { ButtonPrimary } from '../Button'
import StatusIcon from '../Identicon/StatusIcon'
import { MessageIncognitoMode } from '../MessageIncognitoMode'
import IconButton, { IconHoverText } from './IconButton'

const WalletButton = styled(ButtonPrimary)`
  border-radius: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 12px;
  color: white;
  border: none;
`

const MagicLinkButton = styled(ButtonPrimary)`
  margin: 10px 0 20px;
  padding: 8px;
  font-size: 14px;
  line-height: 22px;
  background-color: #232425;
  height: unset;

  &:hover,
  &:active,
  &:focus {
    background-color: #28292a;
    border-color: #28292a;
    box-shadow: 0 0 0 1pt #28292a;
  }

  .icon-magic {
    width: 20px;
    margin-right: 16px;
  }
`

const ProfileButton = styled(WalletButton)`
  background: ${({ theme }) => theme.backgroundInteractive};
  transition: ${({ theme }) => theme.transition.duration.fast} ${({ theme }) => theme.transition.timing.ease}
    background-color;
`

const UNIButton = styled(WalletButton)`
  background: linear-gradient(to right, #9139b0 0%, #4261d6 100%);
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  & > a,
  & > button {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-right: 0px;
    ${IconHoverText}:last-child {
      left: 0px;
    }
  }
`

const USDText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #85878f;
  margin-top: 8px;
`

const FlexContainer = styled.div`
  display: flex;
`

const StatusWrapper = styled.div`
  display: inline-block;
  margin-top: 4px;
`

const BalanceWrapper = styled.div`
  padding: 8px 0 0;
`

const HeaderWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
`

const AuthenticatedHeaderWrapper = styled.div`
  padding: 8px 16px;
`

const MessageIncognitoWrapper = styled.div`
  padding-top: 30px;
`

const PlusWrapper = styled.div`
  position: absolute;
  top: 1px;
  left: -24px;
  color: #fff;
  cursor: pointer;
  line-height: 1;
  font-size: 29px;

  &:hover {
    color: #7966f9;
  }
`

const AuthenticatedHeader = () => {
  const { account, chainId, connector } = useWeb3React()

  const isMagicLink = connector instanceof MagicConnector && MagicConnector.isMagicConnected()

  const openMagicWallet = useCallback(() => {
    if (!isMagicLink) {
      return
    }

    MagicConnector.showWallet()
  }, [isMagicLink])

  const [isCopied, setCopied] = useCopyClipboard()
  const copy = useCallback(() => {
    setCopied(account || '')
  }, [account, setCopied])
  const dispatch = useAppDispatch()
  const balanceString = useCurrencyBalanceString(account ?? '')
  const {
    nativeCurrency: { symbol: nativeCurrencySymbol },
    explorer,
  } = getChainInfoOrDefault(chainId ? chainId : SupportedChainId.MAINNET)
  const nftFlag = useNftFlag()
  const navigate = useNavigate()
  const closeModal = useCloseModal(ApplicationModal.WALLET_DROPDOWN)

  const unclaimedAmount: CurrencyAmount<Token> | undefined = useUserUnclaimedAmount(account)
  const isUnclaimed = useUserHasAvailableClaim(account)
  const connectionType = getConnection(connector).type
  const nativeCurrency = useNativeCurrency()
  const nativeCurrencyPrice = useStablecoinUSDPriceHeader(nativeCurrency ?? undefined) || 0
  const openClaimModal = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  const disconnect = useCallback(() => {
    if (connector && connector.deactivate) {
      connector.deactivate()
    }
    connector.resetState()
    dispatch(updateSelectedWallet({ wallet: undefined }))
  }, [connector, dispatch])

  const amountUSD = useMemo(() => {
    const price = parseFloat(nativeCurrencyPrice.toFixed(5))
    const balance = parseFloat(balanceString || '0')
    return price * balance
  }, [balanceString, nativeCurrencyPrice])

  const navigateToProfile = () => {
    navigate('/profile')
    closeModal()
  }

  const [price, setPrice] = useState(0)
  DiviPriceApi.getDiviPrice().then((price) => {
    setPrice(price)
  })

  let addressDIVI = chainId === SupportedChainId.MAINNET ? DIVI.address : DIVI_GOERLI.address

  if (chainId === SupportedChainId.POLYGON_MUMBAI) {
    addressDIVI = DIVI_POLYGON_MUMBAI.address
  }

  if (chainId === SupportedChainId.POLYGON) {
    addressDIVI = DIVI_POLYGON.address
  }

  const currency = useCurrency(addressDIVI)
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const balanceDIVI = parseFloat(formatCurrencyAmount(selectedCurrencyBalance, 4).replace(',', ''))
  const balanceDIVIUSD = ((Math.trunc(1000 * price) / 1000) * balanceDIVI).toFixed(2)

  const token = currency?.wrapped
  const logoURL = useCurrencyLogoURIs(token)[0]
  const [success, setSuccess] = useState<boolean | undefined>()

  const isConnectedMetaMask = connector instanceof MetaMask

  const addToken = useCallback(() => {
    if (!token?.symbol || !connector.watchAsset) return
    connector
      .watchAsset({
        address: token.address,
        symbol: token.symbol,
        decimals: token.decimals,
        image: logoURL,
      })
      .then(() => setSuccess(true))
      .catch(() => setSuccess(false))
  }, [connector, logoURL, token])

  return (
    <AuthenticatedHeaderWrapper>
      <HeaderWrapper>
        <StatusWrapper>
          <FlexContainer>
            <StatusIcon connectionType={connectionType} size={24} />
            <Text fontSize={16} fontWeight={600} marginTop="2.5px">
              {account && shortenAddress(account, 2, 4)}
            </Text>
          </FlexContainer>
        </StatusWrapper>
        <IconContainer>
          <IconButton onClick={copy} Icon={Copy}>
            {isCopied ? <span>Copied!</span> : <span>Copy</span>}
          </IconButton>
          <IconButton href={`${explorer}address/${account}`} target="_blank" Icon={ExternalLink}>
            <span>Explore</span>
          </IconButton>
          <IconButton data-testid="wallet-disconnect" onClick={disconnect} Icon={Power}>
            <span>Disconnect</span>
          </IconButton>
        </IconContainer>
      </HeaderWrapper>
      <Column>
        <BalanceWrapper>
          <Text fontSize={24} fontWeight={400}>
            {balanceString} {nativeCurrencySymbol}
          </Text>
          <USDText>${amountUSD.toFixed(2)} USD</USDText>
        </BalanceWrapper>
        <BalanceWrapper style={{ marginTop: '12px' }}>
          <Text fontSize={24} fontWeight={400}>
            <span style={{ position: 'relative' }}>
              {isConnectedMetaMask && <PlusWrapper onClick={addToken}>+</PlusWrapper>} {balanceDIVI || 0} DIVI
            </span>
          </Text>
          <USDText>${balanceDIVI > 0 ? balanceDIVIUSD : '0.00'} USD</USDText>
        </BalanceWrapper>
        {nftFlag === NftVariant.Enabled && (
          <ProfileButton onClick={navigateToProfile}>
            <span>View and sell NFTs</span>
          </ProfileButton>
        )}
        {isUnclaimed && (
          <UNIButton onClick={openClaimModal}>
            <span>Claim</span> {unclaimedAmount?.toFixed(0, { groupSeparator: ',' } ?? '-')} <span>reward</span>
          </UNIButton>
        )}
        {isMagicLink && (
          <span>
            <MessageIncognitoWrapper>
              <MessageIncognitoMode />
            </MessageIncognitoWrapper>
            <MagicLinkButton onClick={openMagicWallet}>
              <img className="icon-magic" src={MAGIC_ICON_URL} alt="" />
              Open Magic wallet
            </MagicLinkButton>
          </span>
        )}
      </Column>
    </AuthenticatedHeaderWrapper>
  )
}

export default AuthenticatedHeader
