import { Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent } from 'analytics'
import { ElementName, Event, EventName, PageName, SectionName } from 'analytics/constants'
import { Trace } from 'analytics/Trace'
import { TraceEvent } from 'analytics/TraceEvent'
import { formatSwapQuoteReceivedEventProperties } from 'analytics/utils'
import { sendEvent } from 'components/analytics'
import {
  BodyText,
  Header,
  L2Icon,
  LinkOutToBridge,
  NetworkAlert,
  StyledArrowUpRight,
} from 'components/NetworkAlert/NetworkAlert'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import TokenSafetyModal from 'components/TokenSafety/TokenSafetyModal'
import { MouseoverTooltip } from 'components/Tooltip'
import { isSupportedChain, SupportedChainId } from 'constants/chains'
import { NavBarVariant, useNavBarFlag } from 'featureFlags/flags/navBar'
import { RedesignVariant, useRedesignFlag } from 'featureFlags/flags/redesign'
import { useSwapCallback } from 'hooks/useSwapCallback'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import JSBI from 'jsbi'
import { SwapIcon } from 'nft/components/icons'
import { ChartIcon } from 'nft/components/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactNode } from 'react'
import { CheckCircle, HelpCircle } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import { useToggleWalletModal } from 'state/application/hooks'
import { InterfaceTrade } from 'state/routing/types'
import { TradeState } from 'state/routing/types'
import styled, { css, useTheme } from 'styled-components/macro'
import { currencyAmountToPreciseFloat, formatTransactionAmount } from 'utils/formatNumbers'

import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonConfirmed, ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import SwapCurrencyInputPanel from '../../components/CurrencyInputPanel/SwapCurrencyInputPanel'
import Loader from '../../components/Loader'
import { AutoRow } from '../../components/Row'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, PageWrapper, SwapCallbackError, SwapWrapper } from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import ChartSwap from '../../components/Tokens/TokenDetails/ChartSwap'
import TokenWarningModal from '../../components/TokenWarningModal'
import { TOKEN_SHORTHANDS } from '../../constants/tokens'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useERC20PermitFromTrade, UseERC20PermitState } from '../../hooks/useERC20Permit'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import { useStablecoinValue } from '../../hooks/useStablecoinPrice'
import useWrapCallback, { WrapErrorText, WrapType } from '../../hooks/useWrapCallback'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../state/swap/hooks'
import { useExpertModeManager } from '../../state/user/hooks'
import { HideSmall, LinkStyledButton, ThemedText } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from '../../utils/prices'
import { supportedChainId } from '../../utils/supportedChainId'
import {
  ChartColumn,
  Container,
  Content,
  PageSwitcher,
  SwapColumn,
  SwitchItem,
  SwitchItemActive,
  SwitchText,
  Title,
} from './sharedStyling'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'

const ArrowContainer = styled.div`
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const GasslessBlock = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 0;

  background: radial-gradient(100% 93.36% at 0% 10.64%, rgba(111, 192, 142, 0.1) 0%, rgba(56, 98, 72, 0.1) 100%);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  width: 100%;
  /* height: 64px; */
  justify-content: space-between;
  align-items: center;

  color: rgb(111, 192, 142);

  margin-bottom: 16px;
`

const SwapSection = styled.div<{ redesignFlag: boolean }>`
  position: relative;

  ${({ redesignFlag }) =>
    redesignFlag &&
    css`
      background-color: #0d0e0f;
      border-radius: 15px;
      padding: 12px 16px;
      color: #fff;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      min-height: 74px;

      &:before {
        box-sizing: border-box;
        background-size: 100%;
        border-radius: inherit;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        pointer-events: none;
        content: '';
        border: 1px solid #272828;
      }

      &:hover:before {
        border-color: #6752f4;
      }

      &:focus-within:before {
        border-color: #6752f4;
      }

      &::after {
        display: block;
        position: absolute;
        top: 12px;
        left: 16px;
        font-size: 12px;
        color: #85878f;
      }

      &:first-of-type::after {
        content: 'Pay:';
      }
    `}
`

const OutputSwapSection = styled(SwapSection)<{ showDetailsDropdown: boolean }>`
  border-bottom: ${({ theme, redesignFlag }) => redesignFlag && `1px solid ${theme.backgroundSurface}`};

  &:first-of-type::after {
    content: 'Receive:' !important;
  }
`

const DetailsSwapSection = styled(SwapSection)`
  padding: 0;
  border-top-left-radius: ${({ redesignFlag }) => redesignFlag && '0'};
  border-top-right-radius: ${({ redesignFlag }) => redesignFlag && '0'};
  background: transparent;

  &::before {
    border: none;
  }
`

const ChartIconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 32px;
  right: 68px;
  opacity: 0.7;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    top: 16px !important;
    right: 52px !important;
  }
`

export function getIsValidSwapQuote(
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  return !!swapInputError && !!trade && (tradeState === TradeState.VALID || tradeState === TradeState.SYNCING)
}

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

const TRADE_STRING = 'SwapRouter'

export default function Swap() {
  const navigate = useNavigate()
  const navBarFlag = useNavBarFlag()
  const navBarFlagEnabled = navBarFlag === NavBarVariant.Enabled
  const redesignFlag = useRedesignFlag()
  const redesignFlagEnabled = redesignFlag === RedesignVariant.Enabled
  const { account, chainId, provider } = useWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()
  const [newSwapQuoteNeedsLogging, setNewSwapQuoteNeedsLogging] = useState(true)
  const [fetchingSwapQuoteStartTime, setFetchingSwapQuoteStartTime] = useState<Date | undefined>()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.[Field.INPUT]?.currencyId),
    useCurrency(loadedUrlParams?.[Field.OUTPUT]?.currencyId),
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !Boolean(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = supportedChainId(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletModal = useToggleWalletModal()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo()

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.SYNCING === tradeState],
    [trade, tradeState]
  )

  const fiatValueInput = useStablecoinValue(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useStablecoinValue(parsedAmounts[Field.OUTPUT])
  const stablecoinPriceImpact = useMemo(
    () => (routeIsSyncing ? undefined : computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)),
    [fiatValueInput, fiatValueOutput, routeIsSyncing]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatTransactionAmount(currencyAmountToPreciseFloat(parsedAmounts[dependentField])),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)
  const transactionDeadline = useTransactionDeadline()
  const {
    state: signatureState,
    signatureData,
    gatherPermitSignature,
  } = useERC20PermitFromTrade(trade, allowedSlippage, transactionDeadline)

  const [approvalPending, setApprovalPending] = useState<boolean>(false)
  const handleApprove = useCallback(async () => {
    setApprovalPending(true)
    try {
      if (signatureState === UseERC20PermitState.NOT_SIGNED && gatherPermitSignature) {
        try {
          await gatherPermitSignature()
        } catch (error) {
          // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
          if (error?.code !== 4001) {
            await approveCallback()
          }
        }
      } else {
        await approveCallback()

        sendEvent({
          category: 'Swap',
          action: 'Approve',
          label: [TRADE_STRING, trade?.inputAmount?.currency.symbol].join('/'),
        })
      }
    } finally {
      setApprovalPending(false)
    }
  }, [signatureState, gatherPermitSignature, approveCallback, trade?.inputAmount?.currency.symbol])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalState === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalState, approvalSubmitted])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient,
    signatureData
  )

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    if (stablecoinPriceImpact && !confirmPriceImpactWithoutFee(stablecoinPriceImpact)) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })

    swapCallback()
      .then((hash) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })
        sendEvent({
          category: 'Swap',
          action: 'transaction hash',
          label: hash,
        })
        sendEvent({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [TRADE_STRING, trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol, 'MH'].join(
            '/'
          ),
        })

        if (hash) {
          const swapAmountObject = {
            chainId,
            inputSymbol: trade?.inputAmount?.currency?.symbol,
            outputSymbol: trade?.outputAmount?.currency?.symbol,
            inputAmount: trade?.inputAmount?.toSignificant(6),
            outputAmount: trade?.outputAmount?.toSignificant(6),
            transactionDate: new Date().toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric' }),
            txnLink: getExplorerLink(chainId || 0, hash, ExplorerDataType.TRANSACTION),
          }

          let swapArray = []
          if (localStorage.hasOwnProperty('swapAmountArray')) {
            const swapAmountArray = localStorage.getItem('swapAmountArray') || '[]'
            swapArray = JSON.parse(swapAmountArray)
          }

          swapArray.push(swapAmountObject)
          localStorage.setItem('swapAmountArray', JSON.stringify(swapArray))
        }
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    swapCallback,
    stablecoinPriceImpact,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade?.inputAmount?.currency?.symbol,
    trade?.outputAmount?.currency?.symbol,
  ])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [stablecoinPriceImpact, trade])

  const isArgentWallet = useIsArgentWallet()

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !isArgentWallet &&
    !swapInputError &&
    (approvalState === ApprovalState.NOT_APPROVED ||
      approvalState === ApprovalState.PENDING ||
      (approvalSubmitted && approvalState === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )

  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode
  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3

  // Handle time based logging events and event properties.
  useEffect(() => {
    const now = new Date()
    // If a trade exists, and we need to log the receipt of this new swap quote:
    if (newSwapQuoteNeedsLogging && !!trade) {
      // Set the current datetime as the time of receipt of latest swap quote.
      setSwapQuoteReceivedDate(now)
      // Log swap quote.
      sendAnalyticsEvent(
        EventName.SWAP_QUOTE_RECEIVED,
        formatSwapQuoteReceivedEventProperties(trade, trade.gasUseEstimateUSD ?? undefined, fetchingSwapQuoteStartTime)
      )
      // Latest swap quote has just been logged, so we don't need to log the current trade anymore
      // unless user inputs change again and a new trade is in the process of being generated.
      setNewSwapQuoteNeedsLogging(false)
      // New quote is not being fetched, so set start time of quote fetch to undefined.
      setFetchingSwapQuoteStartTime(undefined)
    }
    // If another swap quote is being loaded based on changed user inputs:
    if (routeIsLoading) {
      setNewSwapQuoteNeedsLogging(true)
      if (!fetchingSwapQuoteStartTime) setFetchingSwapQuoteStartTime(now)
    }
  }, [
    newSwapQuoteNeedsLogging,
    routeIsSyncing,
    routeIsLoading,
    fetchingSwapQuoteStartTime,
    trade,
    setSwapQuoteReceivedDate,
  ])
  const isSupportsGassless = chainId === SupportedChainId.POLYGON || chainId === SupportedChainId.POLYGON_MUMBAI
  const gasslessActive =
    isSupportsGassless && currencies[Field.INPUT]?.isNative === false && currencies[Field.OUTPUT]?.isNative === false

  const approveTokenButtonDisabled =
    approvalState !== ApprovalState.NOT_APPROVED || approvalSubmitted || signatureState === UseERC20PermitState.SIGNED

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing)
  )

  const [isChartOpen, setIsChartOpen] = useState<boolean>(true)

  return (
    <Trace page={PageName.SWAP_PAGE} shouldLogImpression>
      <>
        {/* <ButtonLight onClick={approveForwarder} redesignFlag={redesignFlagEnabled} fontWeight={600}>
          <span>Approve Forwarder</span>
        </ButtonLight> */}
        {redesignFlagEnabled ? (
          <TokenSafetyModal
            isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
            tokenAddress={importTokensNotInDefault[0]?.address}
            secondTokenAddress={importTokensNotInDefault[1]?.address}
            onContinue={handleConfirmTokenWarning}
            onCancel={handleDismissTokenWarning}
            showCancel={true}
          />
        ) : (
          <TokenWarningModal
            isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
            tokens={importTokensNotInDefault}
            onConfirm={handleConfirmTokenWarning}
            onDismiss={handleDismissTokenWarning}
          />
        )}
        <PageWrapper redesignFlag={redesignFlagEnabled} navBarFlag={navBarFlagEnabled} style={{ maxWidth: '100%' }}>
          <Container>
            <Title>Trade at the Price You Want</Title>
            <PageSwitcher>
              <SwitchItemActive>
                <SwitchText style={{ color: '#fff' }}>Swap</SwitchText>
              </SwitchItemActive>
              <SwitchItem>
                <SwitchText as={Link} to="/liquidity">
                  Liquidity
                </SwitchText>
              </SwitchItem>
            </PageSwitcher>
            <Content>
              <SwapColumn>
                {gasslessActive && (
                  <GasslessBlock>
                    <LinkOutToBridge href="https://opengsn.org/">
                      <BodyText>
                        <L2Icon src={'/images/gsn-icon.png'} />
                        <AutoRow>
                          <Header>Gasless Trade powered by GSN</Header>
                          <div style={{ opacity: 0.6 }}>
                            <HideSmall>You are not required to pay gas for this transaction.</HideSmall>
                          </div>
                        </AutoRow>
                      </BodyText>
                      <StyledArrowUpRight color={'rgb(111, 192, 142)'} />
                    </LinkOutToBridge>
                  </GasslessBlock>
                )}
                <SwapWrapper id="swap-page" redesignFlag={redesignFlagEnabled}>
                  <SwapHeader allowedSlippage={allowedSlippage} />
                  <ChartIconWrapper style={{ opacity: isChartOpen ? '1' : '0.7' }}>
                    <ChartIcon onClick={() => setIsChartOpen(!isChartOpen)} />
                  </ChartIconWrapper>
                  <ConfirmSwapModal
                    isOpen={showConfirm}
                    trade={trade}
                    originalTrade={tradeToConfirm}
                    onAcceptChanges={handleAcceptChanges}
                    attemptingTxn={attemptingTxn}
                    txHash={txHash}
                    recipient={recipient}
                    allowedSlippage={allowedSlippage}
                    onConfirm={handleSwap}
                    swapErrorMessage={swapErrorMessage}
                    onDismiss={handleConfirmDismiss}
                    swapQuoteReceivedDate={swapQuoteReceivedDate}
                    fiatValueInput={fiatValueInput}
                    fiatValueOutput={fiatValueOutput}
                  />

                  <div style={{ display: 'relative' }}>
                    <SwapSection redesignFlag={redesignFlagEnabled}>
                      <Trace section={SectionName.CURRENCY_INPUT_PANEL}>
                        <SwapCurrencyInputPanel
                          label={
                            independentField === Field.OUTPUT && !showWrap ? (
                              <span>From (at most)</span>
                            ) : (
                              <span>From</span>
                            )
                          }
                          value={formattedAmounts[Field.INPUT]}
                          showMaxButton={showMaxButton}
                          currency={currencies[Field.INPUT] ?? null}
                          onUserInput={handleTypeInput}
                          onMax={handleMaxInput}
                          fiatValue={fiatValueInput ?? undefined}
                          onCurrencySelect={handleInputSelect}
                          otherCurrency={currencies[Field.OUTPUT]}
                          showCommonBases={true}
                          id={SectionName.CURRENCY_INPUT_PANEL}
                          loading={independentField === Field.OUTPUT && routeIsSyncing}
                        />
                      </Trace>
                    </SwapSection>
                    <ArrowWrapper clickable={isSupportedChain(chainId)} redesignFlag={redesignFlagEnabled}>
                      <TraceEvent
                        events={[Event.onClick]}
                        name={EventName.SWAP_TOKENS_REVERSED}
                        element={ElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
                      >
                        <ArrowContainer
                          onClick={() => {
                            setApprovalSubmitted(false) // reset 2 step UI for approvals
                            onSwitchTokens()
                          }}
                          color={theme.textPrimary}
                        >
                          <SwapIcon />
                        </ArrowContainer>
                      </TraceEvent>
                    </ArrowWrapper>
                  </div>
                  <AutoColumn gap={redesignFlagEnabled ? '12px' : '8px'}>
                    <div>
                      <OutputSwapSection redesignFlag={redesignFlagEnabled} showDetailsDropdown={showDetailsDropdown}>
                        <Trace section={SectionName.CURRENCY_OUTPUT_PANEL}>
                          <SwapCurrencyInputPanel
                            value={formattedAmounts[Field.OUTPUT]}
                            onUserInput={handleTypeOutput}
                            label={
                              independentField === Field.INPUT && !showWrap ? (
                                <span>To (at least)</span>
                              ) : (
                                <span>To</span>
                              )
                            }
                            showMaxButton={false}
                            hideBalance={false}
                            fiatValue={fiatValueOutput ?? undefined}
                            priceImpact={stablecoinPriceImpact}
                            currency={currencies[Field.OUTPUT] ?? null}
                            onCurrencySelect={handleOutputSelect}
                            otherCurrency={currencies[Field.INPUT]}
                            showCommonBases={true}
                            id={SectionName.CURRENCY_OUTPUT_PANEL}
                            loading={independentField === Field.INPUT && routeIsSyncing}
                          />
                        </Trace>

                        {recipient !== null && !showWrap ? (
                          <>
                            <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                              <ArrowWrapper clickable={false} redesignFlag={redesignFlagEnabled}>
                                <SwapIcon />
                              </ArrowWrapper>
                              <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                                <span>- Remove recipient</span>
                              </LinkStyledButton>
                            </AutoRow>
                            <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                          </>
                        ) : null}
                      </OutputSwapSection>
                      {showDetailsDropdown && (
                        <DetailsSwapSection redesignFlag={redesignFlagEnabled}>
                          <SwapDetailsDropdown
                            trade={trade}
                            syncing={routeIsSyncing}
                            loading={routeIsLoading}
                            showInverted={showInverted}
                            setShowInverted={setShowInverted}
                            allowedSlippage={allowedSlippage}
                          />
                        </DetailsSwapSection>
                      )}
                    </div>
                    {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
                    <div>
                      {swapIsUnsupported ? (
                        <ButtonPrimary disabled={true}>
                          <ThemedText.DeprecatedMain mb="4px">
                            <span>Unsupported Asset</span>
                          </ThemedText.DeprecatedMain>
                        </ButtonPrimary>
                      ) : !account ? (
                        <TraceEvent
                          events={[Event.onClick]}
                          name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                          properties={{ received_swap_quote: getIsValidSwapQuote(trade, tradeState, swapInputError) }}
                          element={ElementName.CONNECT_WALLET_BUTTON}
                        >
                          <ButtonLight onClick={toggleWalletModal} redesignFlag={redesignFlagEnabled} fontWeight={600}>
                            <span>Connect Wallet</span>
                          </ButtonLight>
                        </TraceEvent>
                      ) : showWrap ? (
                        <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap} fontWeight={600}>
                          {wrapInputError ? (
                            <WrapErrorText wrapInputError={wrapInputError} />
                          ) : wrapType === WrapType.WRAP ? (
                            <span>Wrap</span>
                          ) : wrapType === WrapType.UNWRAP ? (
                            <span>Unwrap</span>
                          ) : null}
                        </ButtonPrimary>
                      ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
                        <GreyCard style={{ textAlign: 'center' }}>
                          <ThemedText.DeprecatedMain mb="4px">
                            <span>Insufficient liquidity for this trade.</span>
                          </ThemedText.DeprecatedMain>
                        </GreyCard>
                      ) : showApproveFlow ? (
                        <AutoRow style={{ flexWrap: 'nowrap', width: '100%' }}>
                          <AutoColumn style={{ width: '100%' }} gap="12px">
                            <ButtonConfirmed
                              fontWeight={600}
                              onClick={handleApprove}
                              disabled={approveTokenButtonDisabled}
                              width="100%"
                              altDisabledStyle={approvalState === ApprovalState.PENDING} // show solid button while waiting
                              confirmed={
                                approvalState === ApprovalState.APPROVED ||
                                signatureState === UseERC20PermitState.SIGNED
                              }
                            >
                              <AutoRow justify="space-between" style={{ flexWrap: 'nowrap' }} height="20px">
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                  {/* we need to shorten this string on mobile */}
                                  {approvalState === ApprovalState.APPROVED ||
                                  signatureState === UseERC20PermitState.SIGNED ? (
                                    <span>You can now trade {currencies[Field.INPUT]?.symbol}</span>
                                  ) : (
                                    <span>Allow the DIVI Platform to use your {currencies[Field.INPUT]?.symbol}</span>
                                  )}
                                </span>
                                {approvalPending || approvalState === ApprovalState.PENDING ? (
                                  <Loader stroke={theme.white} />
                                ) : (approvalSubmitted && approvalState === ApprovalState.APPROVED) ||
                                  signatureState === UseERC20PermitState.SIGNED ? (
                                  <CheckCircle size="20" color={theme.deprecated_green1} />
                                ) : (
                                  <MouseoverTooltip
                                    text={
                                      <span>
                                        You must give the Divi smart contracts permission to use your{' '}
                                        {currencies[Field.INPUT]?.symbol}. You only have to do this once per token.
                                      </span>
                                    }
                                  >
                                    <HelpCircle
                                      size="20"
                                      color={theme.deprecated_white}
                                      style={{ marginLeft: '8px' }}
                                    />
                                  </MouseoverTooltip>
                                )}
                              </AutoRow>
                            </ButtonConfirmed>
                            <ButtonError
                              onClick={() => {
                                if (isExpertMode) {
                                  handleSwap()
                                } else {
                                  setSwapState({
                                    tradeToConfirm: trade,
                                    attemptingTxn: false,
                                    swapErrorMessage: undefined,
                                    showConfirm: true,
                                    txHash: undefined,
                                  })
                                }
                              }}
                              width="100%"
                              id="swap-button"
                              disabled={
                                !isValid ||
                                routeIsSyncing ||
                                routeIsLoading ||
                                (approvalState !== ApprovalState.APPROVED &&
                                  signatureState !== UseERC20PermitState.SIGNED) ||
                                priceImpactTooHigh
                              }
                              error={isValid && priceImpactSeverity > 2}
                            >
                              <Text fontSize={16} fontWeight={600}>
                                {priceImpactTooHigh ? (
                                  <span>High Price Impact</span>
                                ) : trade && priceImpactSeverity > 2 ? (
                                  <span>Swap Anyway</span>
                                ) : (
                                  <span>Swap</span>
                                )}
                              </Text>
                            </ButtonError>
                          </AutoColumn>
                        </AutoRow>
                      ) : (
                        <ButtonError
                          onClick={() => {
                            if (isExpertMode) {
                              handleSwap()
                            } else {
                              setSwapState({
                                tradeToConfirm: trade,
                                attemptingTxn: false,
                                swapErrorMessage: undefined,
                                showConfirm: true,
                                txHash: undefined,
                              })
                            }
                          }}
                          id="swap-button"
                          disabled={
                            !isValid || routeIsSyncing || routeIsLoading || priceImpactTooHigh || !!swapCallbackError
                          }
                          error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                        >
                          <Text fontSize={16} fontWeight={600}>
                            {swapInputError ? (
                              swapInputError
                            ) : routeIsSyncing || routeIsLoading ? (
                              <span>Swap</span>
                            ) : priceImpactSeverity > 2 ? (
                              <span>Swap Anyway</span>
                            ) : priceImpactTooHigh ? (
                              <span>Price Impact Too High</span>
                            ) : (
                              <span>Swap</span>
                            )}
                          </Text>
                        </ButtonError>
                      )}
                      {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                    </div>
                  </AutoColumn>
                </SwapWrapper>
                <NetworkAlert />
              </SwapColumn>
              {isChartOpen && (
                <ChartColumn>
                  <ChartSwap />
                </ChartColumn>
              )}
            </Content>
          </Container>
        </PageWrapper>
        {!swapIsUnsupported ? null : (
          <UnsupportedCurrencyFooter
            show={swapIsUnsupported}
            currencies={[currencies[Field.INPUT], currencies[Field.OUTPUT]]}
          />
        )}
      </>
    </Trace>
  )
}
