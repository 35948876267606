import DiviPriceApi from 'components/Tokens/TokenDetails/ChartSwap/DiviPriceApi'
import Web3Status from 'components/Web3Status'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import {
  BurgerIcon,
  CloseIcon,
  CoinIcon,
  DiviIcon,
  DiviIconMobile,
  Eth2Icon,
  EtherIcon,
  MaticIcon,
} from 'nft/components/icons'
import React from 'react'
import { ReactNode, useRef, useState, useEffect } from 'react'
import { ChevronDown, ChevronRight, ChevronUp } from 'react-feather'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'

// import coinRedIcon from '../../assets/images/divi-logo.svg'
import { NavDropdown } from './NavDropdown'
import * as styles from './style.css'
import { useWeb3React } from '@web3-react/core'
import { SupportedChainId } from 'constants/chains'
import { sprinkles } from 'nft/css/sprinkles.css'
import useSelectChain from 'hooks/useSelectChain'
import useSyncChainQuery from 'hooks/useSyncChainQuery'

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  target?: string
  onClick?: () => void
}

const MenuItem = ({ href, id, isActive, children, target, onClick }: MenuItemProps) => {
  const tagName = target === '_blank' ? 'a' : NavLink

  const props = {
    id,
    target,
    style: {
      textDecoration: 'none',
    },
    className: isActive ? styles.activeMenuItem : styles.menuItem,
    href: target === '_blank' ? href : '',
    to: target === '_blank' ? '' : href,
    onClick,
  }

  return React.createElement(tagName, props, children)
}

const chevronProps = {
  height: 18,
  width: 18,
  color: 'fff',
}

const chevronSmProps = {
  height: 24,
  width: 24,
  color: 'fff',
}

const PageTabs = ({ closeMenu }: { closeMenu: () => void }) => {
  const { pathname } = useLocation()

  const [isTradeTabOpen, setIsTradeTabOpen] = useState<boolean>(false)
  const [isFarmTabOpen, setIsFarmTabOpen] = useState<boolean>(false)
  const [isInfoTabOpen, setIsInfoTabOpen] = useState<boolean>(false)

  const ref = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const dropdownTradeRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsTradeTabOpen(false), [modalRef, dropdownTradeRef])
  const dropdownFarmRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsFarmTabOpen(false), [modalRef, dropdownFarmRef])
  const dropdownInfoRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsInfoTabOpen(false), [modalRef, dropdownInfoRef])

  const dropdownTrade = (
    <NavDropdown top="40" right="20" ref={modalRef}>
      <NavLink
        to="/swap"
        style={{ padding: '0', textAlign: 'left' }}
        className={pathname.startsWith('/swap') ? styles.dropdownItemActive : styles.dropdownItem}
        onClick={() => setIsTradeTabOpen(false)}
      >
        <span>Swap</span>
      </NavLink>
      <NavLink
        to="/liquidity"
        style={{ padding: '0', textAlign: 'left' }}
        className={pathname.startsWith('/liquidity') ? styles.dropdownItemActive : styles.dropdownItem}
        onClick={() => setIsTradeTabOpen(false)}
      >
        <span>Liquidity</span>
      </NavLink>
    </NavDropdown>
  )

  const dropdownTradeMobile = (
    <Box>
      <NavLink
        to="/swap"
        style={{ padding: '0', textAlign: 'left', display: 'block', fontSize: '18px', marginTop: '16px' }}
        className={pathname.startsWith('/swap') ? styles.dropdownItemActive : styles.dropdownItem}
        onClick={closeMenu}
      >
        <span>Swap</span>
      </NavLink>
      <NavLink
        to="/liquidity"
        style={{ padding: '0', textAlign: 'left', display: 'block', fontSize: '18px', marginTop: '16px' }}
        className={pathname.startsWith('/liquidity') ? styles.dropdownItemActive : styles.dropdownItem}
        onClick={closeMenu}
      >
        <span>Liquidity</span>
      </NavLink>
    </Box>
  )

  const dropdownFarm = (
    <NavDropdown top="40" right="20" ref={modalRef}>
      <NavLink
        to="/farmsV1"
        style={{ padding: '0', textAlign: 'left' }}
        className={pathname.startsWith('/farmsV1') ? styles.dropdownItemActive : styles.dropdownItem}
        onClick={() => setIsFarmTabOpen(false)}
      >
        <span>Farms V1</span>
      </NavLink>
      <NavLink
        to="/farms"
        style={{ padding: '0', textAlign: 'left' }}
        className={
          pathname.startsWith('/farms') && !pathname.startsWith('/farmsV1')
            ? styles.dropdownItemActive
            : styles.dropdownItem
        }
        onClick={() => setIsFarmTabOpen(false)}
      >
        <span>Farms V2</span>
      </NavLink>
    </NavDropdown>
  )

  const dropdownFarmMobile = (
    <Box>
      <NavLink
        to="/farmsV1"
        style={{ padding: '0', textAlign: 'left', display: 'block', fontSize: '18px', marginTop: '16px' }}
        className={pathname.startsWith('/farmsV1') ? styles.dropdownItemActive : styles.dropdownItem}
        onClick={closeMenu}
      >
        <span>Farms V1</span>
      </NavLink>
      <NavLink
        to="/farms"
        style={{ padding: '0', textAlign: 'left', display: 'block', fontSize: '18px', marginTop: '16px' }}
        className={
          pathname.startsWith('/farms') && !pathname.startsWith('/farmsV1')
            ? styles.dropdownItemActive
            : styles.dropdownItem
        }
        onClick={closeMenu}
      >
        <span>Farms V2</span>
      </NavLink>
    </Box>
  )

  const dropdownInfo = (
    <NavDropdown top="40" right="20" ref={modalRef}>
      <NavLink
        to="/overview"
        style={{ padding: '0', textAlign: 'left' }}
        className={pathname.startsWith('/overview') ? styles.dropdownItemActive : styles.dropdownItem}
        onClick={() => setIsInfoTabOpen(false)}
      >
        <span>Overview</span>
      </NavLink>
      <NavLink
        to="/pair"
        style={{ padding: '0', textAlign: 'left' }}
        className={pathname.startsWith('/pair') ? styles.dropdownItemActive : styles.dropdownItem}
        onClick={() => setIsInfoTabOpen(false)}
      >
        <span>Pair</span>
      </NavLink>
      <NavLink
        to="/token/divi"
        style={{ padding: '0', textAlign: 'left' }}
        className={pathname.startsWith('/token') ? styles.dropdownItemActive : styles.dropdownItem}
        onClick={() => setIsInfoTabOpen(false)}
      >
        <span>Token</span>
      </NavLink>
    </NavDropdown>
  )

  const dropdownInfoMobile = (
    <Box>
      <NavLink
        to="/overview"
        style={{ padding: '0', textAlign: 'left', display: 'block', fontSize: '18px', marginTop: '16px' }}
        className={pathname.startsWith('/overview') ? styles.dropdownItemActive : styles.dropdownItem}
        onClick={closeMenu}
      >
        <span>Overview</span>
      </NavLink>
      <NavLink
        to="/pair"
        style={{ padding: '0', textAlign: 'left', display: 'block', fontSize: '18px', marginTop: '16px' }}
        className={pathname.startsWith('/pair') ? styles.dropdownItemActive : styles.dropdownItem}
        onClick={closeMenu}
      >
        <span>Pair</span>
      </NavLink>
      <NavLink
        to="/token/divi"
        style={{ padding: '0', textAlign: 'left', display: 'block', fontSize: '18px', marginTop: '16px' }}
        className={pathname.startsWith('/token') ? styles.dropdownItemActive : styles.dropdownItem}
        onClick={closeMenu}
      >
        <span>Token</span>
      </NavLink>
    </Box>
  )

  return (
    <>
      <Box className={styles.menuItem} ref={dropdownTradeRef}>
        <Box
          style={{ display: 'flex', alignItems: 'center', gap: '3px', cursor: 'pointer' }}
          onClick={() => setIsTradeTabOpen(!isTradeTabOpen)}
        >
          <span>Trade</span>
          {isTradeTabOpen ? (
            <>
              <Box display={{ sm: 'none', xxl: 'block' }}>
                <ChevronUp {...chevronProps} style={{ marginTop: '7px', stroke: '#fff' }} />
              </Box>
              <Box display={{ sm: 'block', xxl: 'none' }} style={{ marginLeft: 'auto' }}>
                <ChevronUp {...chevronSmProps} style={{ marginTop: '5px', stroke: '#fff' }} />
              </Box>
            </>
          ) : (
            <>
              <Box display={{ sm: 'none', xxl: 'block' }}>
                <ChevronDown {...chevronProps} style={{ marginTop: '7px', stroke: '#fff' }} />
              </Box>
              <Box display={{ sm: 'block', xxl: 'none' }} style={{ marginLeft: 'auto' }}>
                <ChevronRight {...chevronSmProps} style={{ marginTop: '5px', stroke: '#fff' }} />
              </Box>
            </>
          )}
        </Box>
        <Box display={{ sm: 'none', xxl: 'block' }}>{isTradeTabOpen && <>{dropdownTrade}</>}</Box>
        <Box display={{ sm: 'block', xxl: 'none' }}>{isTradeTabOpen && <>{dropdownTradeMobile}</>}</Box>
      </Box>
      <Box className={styles.menuItem} ref={dropdownFarmRef}>
        <Box
          style={{ display: 'flex', alignItems: 'center', gap: '3px', cursor: 'pointer' }}
          onClick={() => setIsFarmTabOpen(!isFarmTabOpen)}
        >
          <span>Farms</span>
          {isFarmTabOpen ? (
            <>
              <Box display={{ sm: 'none', xxl: 'block' }}>
                <ChevronUp {...chevronProps} style={{ marginTop: '7px', stroke: '#fff' }} />
              </Box>
              <Box display={{ sm: 'block', xxl: 'none' }} style={{ marginLeft: 'auto' }}>
                <ChevronUp {...chevronSmProps} style={{ marginTop: '5px', stroke: '#fff' }} />
              </Box>
            </>
          ) : (
            <>
              <Box display={{ sm: 'none', xxl: 'block' }}>
                <ChevronDown {...chevronProps} style={{ marginTop: '7px', stroke: '#fff' }} />
              </Box>
              <Box display={{ sm: 'block', xxl: 'none' }} style={{ marginLeft: 'auto' }}>
                <ChevronRight {...chevronSmProps} style={{ marginTop: '5px', stroke: '#fff' }} />
              </Box>
            </>
          )}
        </Box>
        <Box display={{ sm: 'none', xxl: 'block' }}>{isFarmTabOpen && <>{dropdownFarm}</>}</Box>
        <Box display={{ sm: 'block', xxl: 'none' }}>{isFarmTabOpen && <>{dropdownFarmMobile}</>}</Box>
      </Box>
      {/* <MenuItem href="/dashboard" isActive={pathname.startsWith('/dashboard')} onClick={closeMenu}>
        <span>Dashboard</span>
      </MenuItem> */}
      <MenuItem href="https://discord.com/invite/diviproject" target="_blank" onClick={closeMenu}>
        <span>Community</span>
      </MenuItem>
      {/* <Box className={styles.menuItem} ref={dropdownInfoRef}>
        <Box
          style={{ display: 'flex', alignItems: 'center', gap: '3px', cursor: 'pointer' }}
          onClick={() => setIsInfoTabOpen(!isInfoTabOpen)}
        >
          <span>Info</span>
          {isInfoTabOpen ? (
            <>
              <Box display={{ sm: 'none', xxl: 'block' }}>
                <ChevronUp {...chevronProps} style={{ marginTop: '7px', stroke: '#fff' }} />
              </Box>
              <Box display={{ sm: 'block', xxl: 'none' }} style={{ marginLeft: 'auto' }}>
                <ChevronUp {...chevronSmProps} style={{ marginTop: '5px', stroke: '#fff' }} />
              </Box>
            </>
          ) : (
            <>
              <Box display={{ sm: 'none', xxl: 'block' }}>
                <ChevronDown {...chevronProps} style={{ marginTop: '7px', stroke: '#fff' }} />
              </Box>
              <Box display={{ sm: 'block', xxl: 'none' }} style={{ marginLeft: 'auto' }}>
                <ChevronRight {...chevronSmProps} style={{ marginTop: '5px', stroke: '#fff' }} />
              </Box>
            </>
          )}
        </Box>
        <Box display={{ sm: 'none', xxl: 'block' }}>{isInfoTabOpen && <>{dropdownInfo}</>}</Box>
        <Box display={{ sm: 'block', xxl: 'none' }}>{isInfoTabOpen && <>{dropdownInfoMobile}</>}</Box>
      </Box> */}
    </>
  )
}

const Navbar = () => {
  const { chainId, account, connector } = useWeb3React()

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const ref = useRef<HTMLDivElement>(null)

  const modalRef = useRef<HTMLDivElement>(null)
  const [isBridgeTabOpen, setIsBridgeTabOpen] = useState<boolean>(false)
  const dropdownBridgeRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsBridgeTabOpen(false), [modalRef, dropdownBridgeRef])

  const netRef = useRef<HTMLDivElement>(null)
  const [isNetOpen, setIsNetOpen] = useState<boolean>(false)
  const chainRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsNetOpen(false), [netRef, chainRef])

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  const selectChain = useSelectChain()
  useSyncChainQuery()

  const [price, setPrice] = useState(0)

  useEffect(() => {
    DiviPriceApi.getDiviDisplayPrice().then((price) => {
      setPrice(price)
    })
  }, [])

  const bridgeList = (
    <NavDropdown top="48" right="20" ref={modalRef}>
      <a
        href="https://wallet.polygon.technology/polygon/bridge/deposit"
        target="_blank"
        style={{ padding: '0', textAlign: 'left', display: 'none' }}
        className={styles.dropdownItem}
        onClick={() => setIsBridgeTabOpen(false)}
        rel="noreferrer"
      >
        <span>Polygon Bridge</span>
      </a>
      <a
        href="https://changenow.io/currencies/divi-erc20/divi"
        target="_blank"
        style={{ padding: '0', textAlign: 'left' }}
        className={styles.dropdownItem}
        onClick={() => setIsBridgeTabOpen(false)}
        rel="noreferrer"
      >
        <span>ETH Bridge</span>
      </a>
    </NavDropdown>
  )

  const chainList = (
    <NavDropdown top="48" right="20" ref={modalRef}>
      <a
        href=""
        target="none"
        style={{ padding: '0', textAlign: 'left' }}
        className={styles.dropdownItem}
        onClick={async (e) => {
          e.preventDefault()
          selectChain(SupportedChainId.POLYGON)
          setIsNetOpen(false)
        }}
        rel="noreferrer"
      >
        <MaticIcon width="24" height="24" />
        <span>Polygon</span>
      </a>
      <a
        href=""
        target="none"
        style={{ padding: '0', textAlign: 'left' }}
        className={styles.dropdownItem}
        onClick={(e) => {
          e.preventDefault()
          selectChain(SupportedChainId.MAINNET)
          setIsNetOpen(false)
        }}
        rel="noreferrer"
      >
        <EtherIcon width="24" height="24" />
        <span>Ethereum</span>
      </a>
    </NavDropdown>
  )

  return (
    <>
      <nav className={styles.nav}>
        <Box
          display="flex"
          height="full"
          flexWrap="nowrap"
          alignItems="stretch"
          style={{ width: '1372px', padding: '0 30px', margin: '0 auto' }}
        >
          <Box className={styles.leftSideContainer}>
            <Box className={styles.logoContainer}>
              <NavLink to="/swap">
                <DiviIcon width="108" height="44" className={styles.logo} />
              </NavLink>
            </Box>
            <Row gap="12">
              <PageTabs closeMenu={closeMenu} />
            </Row>
          </Box>
          <Box className={styles.rightSideContainer}>
            <Row>
              <Box className={styles.menuItem} ref={dropdownBridgeRef}>
                <Box
                  style={{ display: 'flex', alignItems: 'center', gap: '3px', cursor: 'pointer', padding: '8px 0' }}
                  onClick={() => setIsBridgeTabOpen(!isBridgeTabOpen)}
                >
                  <span>Bridge</span>
                  {isBridgeTabOpen ? (
                    <>
                      <Box>
                        <ChevronUp {...chevronProps} style={{ marginTop: '7px', stroke: '#fff' }} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box>
                        <ChevronDown {...chevronProps} style={{ marginTop: '7px', stroke: '#fff' }} />
                      </Box>
                    </>
                  )}
                </Box>
                <Box>{isBridgeTabOpen && <>{bridgeList}</>}</Box>
              </Box>

              {account !== undefined && (
                <Box className={styles.menuItem} ref={netRef}>
                  <Box
                    style={{ display: 'flex', alignItems: 'center', gap: '3px', cursor: 'pointer', padding: '0 0' }}
                    onClick={() => setIsNetOpen(!isNetOpen)}
                  >
                    {chainId === SupportedChainId.POLYGON || chainId === SupportedChainId.POLYGON_MUMBAI ? (
                      <MaticIcon width="24" height="24" />
                    ) : (
                      <EtherIcon width="24" height="24" />
                    )}

                    {isNetOpen ? (
                      <>
                        <Box>
                          <ChevronUp {...chevronProps} style={{ marginTop: '7px', stroke: '#fff' }} />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box>
                          <ChevronDown {...chevronProps} style={{ marginTop: '7px', stroke: '#fff' }} />
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box>{isNetOpen && <>{chainList}</>}</Box>
                </Box>
              )}

              <Box className={styles.currencyContainer}>
                <CoinIcon width="24" height="24" />
                <Box display="flex" gap="4">
                  <Box> 1 = $ </Box>
                  {/* <Box>{Math.trunc(1000 * price) / 1000}</Box> */}
                  <Box>{price.toFixed(4)}</Box>
                </Box>
              </Box>

              <Web3Status />
            </Row>
          </Box>
        </Box>
      </nav>
      <nav className={styles.navMobile}>
        <Box display="flex" width="full" height="full" flexWrap="nowrap" alignItems="stretch">
          <Box className={styles.leftSideContainer}>
            <NavLink to="/swap" onClick={closeMenu}>
              <DiviIconMobile width="80" height="32" className={styles.logo} />
            </NavLink>
          </Box>
          <Box className={styles.rightSideContainer} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? (
              <CloseIcon width="32" height="32" className={styles.close} />
            ) : (
              <BurgerIcon width="32" height="32" className={styles.burger} />
            )}
          </Box>
          <>
            {isMenuOpen && (
              <Box
                display="flex"
                style={{
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100vw',
                  height: 'calc(100vh - 64px)',
                  background: '#131415',
                  overflow: 'scroll',
                }}
                className={styles.dropDrown}
              >
                <Row gap="14" width="full" style={{ flexDirection: 'column' }}>
                  <PageTabs closeMenu={closeMenu} />
                </Row>
                <Box
                  display="flex"
                  gap="24"
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginTop: '50px',
                    width: '100%',
                  }}
                >
                  <Box className={styles.menuItem} ref={dropdownBridgeRef}>
                    <Box
                      style={{ display: 'flex', alignItems: 'center', gap: '3px', cursor: 'pointer' }}
                      onClick={() => setIsBridgeTabOpen(!isBridgeTabOpen)}
                    >
                      <span>Bridge</span>
                    </Box>
                    <a
                      href="https://wallet.polygon.technology/polygon/bridge/deposit"
                      target="_blank"
                      style={{
                        padding: '0',
                        fontSize: '18px',
                        marginTop: '24px',
                        justifyContent: 'space-between',
                        display: 'none',
                      }}
                      className={styles.dropdownItem}
                      onClick={closeMenu}
                      rel="noreferrer"
                    >
                      <span>Polygon Bridge</span>
                    </a>
                    <a
                      href="https://changenow.io/currencies/divi-erc20/divi"
                      target="_blank"
                      style={{ padding: '0', fontSize: '18px', marginTop: '16px', justifyContent: 'space-between' }}
                      className={styles.dropdownItem}
                      onClick={closeMenu}
                      rel="noreferrer"
                    >
                      <span>ETH Bridge</span>
                    </a>
                  </Box>
                  <Box className={styles.currencyContainer}>
                    <CoinIcon width="24" height="24" />
                    <Box display="flex" gap="4">
                      <Box> 1 = $ </Box>
                      {/* <Box>{Math.trunc(1000 * price) / 1000}</Box> */}
                      <Box>{price.toFixed(4)}</Box>
                    </Box>
                  </Box>
                  <Web3Status />
                </Box>
              </Box>
            )}
          </>
        </Box>
      </nav>
    </>
  )
}

export default Navbar
