import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import Slider from '../Slider'

export const RangeWrapper = styled.div`
  width: 100%;

  &.disabled {
    pointer-events: none;
    opacity: 0.9;
  }
`

export const RangePercent = styled.div`
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 12px;
`

export const RangeButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 8px;
  margin-top: 20px;
`

export const RangeButton = styled.button`
  border: none;
  border-radius: 24px;
  height: 40px;
  padding: 8px 24px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  line-height: 24px;
  background: #232425;
  width: 80px;
  font-family: 'GalanoGrotesque-Medium';

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: 70px;
    padding: 8px;
  }

  &:hover {
    background: #6752f4;
  }

  &:active {
    background: #6752f4;
  }

  &:focus {
    background: #6752f4;
  }
`

interface RangeSliderProps {
  value: number
  maxValue: number
  disabled?: boolean
  onChangeValue: (value: number) => void
}

const BUTTONS = [
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: 'MAX',
  },
]

export default function RangeSlider(props: RangeSliderProps) {
  const [stakePercents, setStakePercents] = useState(0)

  useEffect(() => {
    const maxValue = isNaN(props.maxValue) || props.maxValue === 0 ? 1 : props.maxValue

    let value = isNaN(props.value) || props.disabled ? 0 : props.value

    if (value > maxValue) {
      value = maxValue
      props.onChangeValue(value)
    }

    const _value = (value / maxValue) * 100 || 0
    const _percents = Number((Math.round(_value * 100) / 100).toFixed(2))

    setStakePercents(_percents)
  }, [props])

  const onSetPercents = (percents: number): void => {
    setStakePercents(percents)

    const maxValue = isNaN(props.maxValue) || props.maxValue === 0 ? 1 : props.maxValue
    const value = (maxValue * percents) / 100

    props.onChangeValue(value)
  }

  return (
    <>
      <RangeWrapper className={props.disabled ? 'disabled' : ''}>
        <RangePercent>{stakePercents}%</RangePercent>
        <Slider value={stakePercents} onChange={onSetPercents} />
        <RangeButtons>
          {BUTTONS.map((item, index) => (
            <RangeButton className="range-button" key={index} onClick={() => onSetPercents(item.value)}>
              {item.label}
            </RangeButton>
          ))}
        </RangeButtons>
      </RangeWrapper>
    </>
  )
}
