// import discord from 'assets/svg/social/discord.svg'
// import facebook from 'assets/svg/social/facebook.svg'
// import instagram from 'assets/svg/social/instagram.svg'
// import medium from 'assets/svg/social/medium.svg'
// import telegram from 'assets/svg/social/telegram.svg'
// import twitter from 'assets/svg/social/twitter.svg'
// import youtube from 'assets/svg/social/youtube.svg'
import { DButton, DButtonCircle } from 'components/divi/DButton'
import styled from 'styled-components/macro'

import { DiscordIconFlag } from '../../nft/components/icons'

const Wrapper = styled.div`
  width: 100%;
  background-color: #131415;
`

const Container = styled.div`
  margin: 0 auto;
  padding: 80px 0 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1312px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding: 40px 0 50px;
  }
`

const Column = styled.div`
  padding: 0 16px 40px;
  width: 50%;
  max-width: 616px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    width: 100%;
    max-width: unset;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    &:last-child {
      padding-bottom: 24px;
    }
  }
`

const Title = styled.h2`
  margin: 0 0 24px;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
  color: white;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    font-size: 24px;
    line-height: 34px;
  }
`

const TitleSocial = styled.h3`
  padding-top: 10px;
  margin: 0 0 28px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: white;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    margin-bottom: 32px;
    font-size: 22px;
    line-height: 24px;
  }
`

const Text = styled.p`
  margin: 0 0 40px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: white;
`

const IconPrepend = styled.img`
  vertical-align: middle;
  margin-right: 8px;
`

const LinkSocial = styled(DButtonCircle)`
  vertical-align: top;
  margin: 0 16px 16px 0;
`

const ImgSocial = styled.img`
  vertical-align: middle;
  width: 24px;
  position: relative;
  top: -1px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 20px;
  }
`

const SVGLinkSocial = styled(LinkSocial)`
  margin: 0 10px 10px 0;
  --size: 50px;
  border: none;
  border-radius: 0;

  &,
  &:hover {
    background-color: transparent;
  }

  & path {
    fill: #6752f4;
  }

  &:hover path {
    fill: #7966f9;
  }
`

type LinkFollow = {
  href: string
  icon: string
  iconOnly?: boolean
}

export default function Footer() {
  const title = 'Community'
  const text =
    'Be part of a diverse and active community with members from across the world. DIVI fosters an open, welcoming, inclusive, and collaborative environment. Get support, learn about DIVI, make friends!'
  const titleSocial = 'Follow us:'

  const discordButton = {
    label: 'Join us on Discord',
    href: 'https://discord.com/invite/diviproject',
    icon: '/social/discord.svg',
  }

  const linksFollow: LinkFollow[] = [
    {
      href: 'https://www.facebook.com/diviproject/',
      icon: '/social/facebook.svg',
    },
    {
      href: 'https://twitter.com/diviproject',
      icon: '/social/twitter.svg',
    },
    {
      href: 'https://www.youtube.com/channel/UCihooU0crqQkBOvV0_lsRnw',
      icon: '/social/youtube.svg',
    },
    // {
    //   href: 'https://t.me/diviproject',
    //   icon: '/social/telegram.svg',
    // },
    {
      href: 'https://www.instagram.com/diviproject/',
      icon: '/social/instagram.svg',
    },
    {
      href: 'https://medium.com/diviproject',
      icon: '/social/medium.svg',
    },
    {
      href: 'https://discord.com/invite/diviproject',
      icon: '/social/discord.svg',
      iconOnly: true,
    },
  ]

  return (
    <>
      <Wrapper>
        <Container>
          <Column>
            <Title>{title}</Title>
            <Text>{text}</Text>
            <div>
              <DButton as="a" href={discordButton.href} target="_blank">
                <IconPrepend src={discordButton.icon} />
                {discordButton.label}
              </DButton>
            </div>
          </Column>
          <Column>
            <TitleSocial>{titleSocial}</TitleSocial>
            {linksFollow.map((item, index) => {
              if (item.iconOnly) {
                return (
                  <SVGLinkSocial
                    key={index}
                    as="a"
                    href={item.href}
                    rel="noreferrer"
                    target="_blank"
                    data-aos="zoom-up"
                    data-aos-delay={index * 100}
                  >
                    <DiscordIconFlag />
                  </SVGLinkSocial>
                )
              } else {
                return (
                  <LinkSocial
                    key={index}
                    as="a"
                    href={item.href}
                    target="_blank"
                    data-aos="zoom-up"
                    data-aos-delay={index * 100}
                  >
                    <ImgSocial src={item.icon} alt="" />
                  </LinkSocial>
                )
              }
            })}
          </Column>
        </Container>
      </Wrapper>
    </>
  )
}
