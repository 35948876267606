import { arrayify } from '@ethersproject/bytes'
import { Contract } from '@ethersproject/contracts'

import { Wallet } from '@ethersproject/wallet'
import { MAX_INT } from '@opensea/seaport-js/lib/constants'
import { Erc20 } from 'abis/types/Erc20'
import { StakingCenter } from 'abis/types/StakingCenter'
import { Vault } from 'abis/types/Vault'
import { VaultFactory } from 'abis/types/VaultFactory'
import { DIVI_STAKING } from 'constants/addresses'
// eslint-disable-next-line no-restricted-imports
import { soliditySha3 } from 'web3-utils'

import STAKING_ERC20 from '../abis/erc20.json'
import STAKING_CENTER from '../abis/staking-center.json'
import STAKING_VAULT_FACTORY from '../abis/vault-factory.json'
import STAKING_VAULT from '../abis/vault.json'

export const StakingCenterContract = (provider: any, chaindId: number) => {
  return new Contract(DIVI_STAKING[chaindId]?.staking, STAKING_CENTER, provider) as StakingCenter
}

export const VaultContract = (provider: any, chaindId: number, address: string) => {
  return new Contract(address, STAKING_VAULT, provider) as Vault
}

export const VaultFactoryContract = (provider: any, chaindId: number) => {
  return new Contract(DIVI_STAKING[chaindId]?.vaultFactory, STAKING_VAULT_FACTORY, provider) as VaultFactory
}

export const ERC20Token = (provider: any, chaindId: number) => {
  return new Contract(DIVI_STAKING[chaindId]?.stakingToken, STAKING_ERC20, provider) as Erc20
}

const LOCK_TYPEHASH = 'Lock(address delegate,address token,uint256 amount,uint256 nonce)'
const UNLOCK_TYPEHASH = 'Unlock(address delegate,address token,uint256 amount,uint256 nonce)'

export class DIVIStakingCenter {
  staking: StakingCenter
  vaultFactory: VaultFactory

  signer: Wallet
  token: Erc20

  protected provider: any
  protected chainId: number

  constructor(provider: any, chainId: number) {
    this.provider = provider
    this.chainId = chainId
    this.staking = StakingCenterContract(provider, chainId).connect(provider.getSigner())

    this.signer = provider.getSigner()
    this.vaultFactory = VaultFactoryContract(provider, chainId).connect(this.signer)
    this.token = ERC20Token(provider, chainId).connect(this.signer)
  }

  async generateSignature(token: string, amount: string, vaultAddress: string, hash: string) {
    try {
      const vault = VaultContract(this.provider, this.chainId, vaultAddress).connect(this.signer)
      const _nonce = await vault.getNonce()
      const message = await vault.getPermissionHash(
        soliditySha3(hash) as any,
        this.staking.address,
        token,
        amount,
        _nonce
      )
      return await this.signer.signMessage(arrayify(message))
    } catch (e) {
      console.log(e)
    }

    return ''
  }

  async getFarmData() {
    try {
      return await this.staking.getStakingCenterData()
    } catch (e) {
      console.log(e)
    }
    return undefined
  }

  async getStaketAmount(vault: string) {
    const stakingData = await this.staking.getVaultData(vault)
    return stakingData.totalStake
  }

  async getRewardAmount(vault: string) {
    return await this.staking.getCurrentVaultReward(vault)
  }

  async getAccountStakingData(vault: string) {
    const staked = await this.getStaketAmount(vault)
    const reward = await this.getRewardAmount(vault)
    return {
      staked,
      reward,
    }
  }

  async getVaultAddress(account: string) {
    try {
      const vault = await this.vaultFactory.getOwnerVault(account)
      return vault
    } catch (e) {
      return 'e'
    }
  }

  async stake(amount: any, account?: string) {
    if (!account) return false

    const vaultAddress = await this.getVaultAddress(account)
    if (!vaultAddress) return false

    try {
      const stData = await this.staking.getStakingCenterData()
      const flatSig = await this.generateSignature(stData.stakingToken, amount, vaultAddress, LOCK_TYPEHASH)
      const transaction = await this.staking.stake(vaultAddress, amount, flatSig, {
        from: account,
      })

      const result = await transaction.wait()

      return true
    } catch (e) {
      console.error(e)
    }

    return false
  }

  async getFutureVaultReward(vaultAddress: string, timestamp: number) {
    if (!vaultAddress) return 0
    try {
      const rewardData = await this.staking.getFutureVaultReward(vaultAddress, timestamp)
      return rewardData
    } catch (e) {
      return 0
    }
  }

  async approveToken(account?: string) {
    if (!account) return
    const allowedTokens = await this.token.allowance(account, this.staking.address)
    if (allowedTokens.isZero()) {
      const tx = await this.token?.approve(this.staking.address, MAX_INT)
      await tx.wait()
    }
  }

  async createVault() {
    try {
      const vaultTransaction = await this.vaultFactory.create()
      const receipt = await vaultTransaction.wait()
      if (receipt.events) {
        const args = receipt.events[receipt.events.length - 1].args
        return args ? (args[0] as string) : undefined
      } else {
        return undefined
      }
    } catch (e) {
      return undefined
    }
  }

  async unstake(amount: any, account?: string) {
    if (!account) return false

    const vaultAddress = await this.getVaultAddress(account)
    if (!vaultAddress) return false

    try {
      const stData = await this.staking.getStakingCenterData()
      const flatSig = await this.generateSignature(stData.stakingToken, amount, vaultAddress, UNLOCK_TYPEHASH)
      const transaction = await this.staking.unstakeAndClaim(vaultAddress, amount, flatSig, {
        from: account,
      })
      const result = await transaction.wait()
      return true
    } catch (e) {
      console.error(e)
    }

    return false
  }

  async getRewardsData() {
    try {
      return await this.staking.getCurrentUnlockedRewards()
    } catch (e) {
      console.log(e)
    }
    return undefined
  }
}
