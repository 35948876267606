import { Connector } from '@web3-react/types'
import MAGIC_ICON_URL from 'assets/images/magicIcon.png'
import { ConnectionType, magicConnection } from 'connection'
import { getConnectionName } from 'connection/utils'

import { MessageIncognitoMode } from '../MessageIncognitoMode'
import Option from './Option'

const BASE_PROPS = {
  color: '#6851FF',
  icon: MAGIC_ICON_URL,
  id: 'magiclink',
}
interface MagicOptionParams {
  tryActivation: (connector: Connector) => void
  toggleWalletModal: () => void
}
export function MagicOption({ tryActivation, toggleWalletModal }: MagicOptionParams) {
  const isActive = magicConnection.hooks.useIsActive()

  return (
    <span>
      <Option
        {...BASE_PROPS}
        isActive={isActive}
        onClick={() => {
          toggleWalletModal()
          setTimeout(() => {
            tryActivation(magicConnection.connector)
          }, 100)
        }}
        header={getConnectionName(ConnectionType.MAGIC)}
      />
      <MessageIncognitoMode />
    </span>
  )
}
