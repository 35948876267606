import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ElementName, Event, EventName } from 'analytics/constants'
import { TraceEvent } from 'analytics/TraceEvent'
import Card from 'components/Card'
import { AutoColumn } from 'components/Column'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import { LoadingRows } from 'components/Loader/styled'
import Row, { RowFixed } from 'components/Row'
import DiviPriceApi from 'components/Tokens/TokenDetails/ChartSwap/DiviPriceApi'
import { SUPPORTED_GAS_ESTIMATE_CHAIN_IDS } from 'constants/chains'
import { RedesignVariant, useRedesignFlag } from 'featureFlags/flags/redesign'
import { HelpIconGray } from 'nft/components/icons'
import { useMemo, useState } from 'react'
import { InterfaceTrade } from 'state/routing/types'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { computeRealizedPriceImpact } from '../../utils/prices'
import { MouseoverTooltip } from '../Tooltip'
import FormattedPriceImpact from './FormattedPriceImpact'
import GasEstimateBadge from './GasEstimateBadge'
import TradePrice from './TradePrice'

const Wrapper = styled(Row)<{ redesignFlag: boolean }>`
  width: 100%;
  justify-content: center;
  border-radius: ${({ redesignFlag }) => redesignFlag && 'inherit'};
  padding: 14px 0 8px;
  margin-top: ${({ redesignFlag }) => (redesignFlag ? '0px' : '4px')};
  min-height: ${({ redesignFlag }) => redesignFlag && '32px'};
`

const StyledCard = styled(Card)`
  padding: 12px 16px;
  background: #0d0e0f;
  border-radius: 15px;
`

const SwapCardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    display: block;
  }
`

const Text = styled.div`
  text-align: right;
  font-size: 14px;
  color: #85878f;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    text-align: left;
    margin-top: 8px;
  }
`

const CurrencyText = styled.div`
  text-align: right;
  font-size: 14px;
  color: #85878f;
`

const Block = styled.div`
  display: block;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    margin-top: 8px;
  }
`

interface SwapDetailsInlineProps {
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
  syncing?: boolean
  loading: boolean
  showInverted: boolean
  setShowInverted: React.Dispatch<React.SetStateAction<boolean>>
  allowedSlippage: Percent
  hideInfoTooltips?: boolean
}

function TextWithLoadingPlaceholder({
  syncing,
  width,
  children,
}: {
  syncing: boolean
  width: number
  children: JSX.Element
}) {
  return syncing ? (
    <LoadingRows>
      <div style={{ height: '15px', width: `${width}px` }} />
    </LoadingRows>
  ) : (
    children
  )
}

export default function SwapDetailsDropdown({
  trade,
  syncing = false,
  loading,
  showInverted,
  setShowInverted,
  allowedSlippage,
  hideInfoTooltips = false,
}: SwapDetailsInlineProps) {
  const { chainId } = useWeb3React()
  const redesignFlag = useRedesignFlag()
  const redesignFlagEnabled = redesignFlag === RedesignVariant.Enabled

  const theme = useTheme()

  const { priceImpact } = useMemo(() => {
    return {
      expectedOutputAmount: trade?.outputAmount,
      priceImpact: trade ? computeRealizedPriceImpact(trade) : undefined,
    }
  }, [trade])

  const [price, setPrice] = useState(0)

  DiviPriceApi.getDiviPrice().then((price) => {
    setPrice(price)
  })

  return (
    <Wrapper style={{ marginTop: redesignFlagEnabled ? '0' : '8px' }} redesignFlag={redesignFlagEnabled}>
      <AutoColumn gap={'8px'} style={{ width: '100%', marginBottom: '-8px' }}>
        <TraceEvent
          events={[Event.onClick]}
          name={EventName.SWAP_DETAILS_EXPANDED}
          element={ElementName.SWAP_DETAILS_DROPDOWN}
        >
          <AutoColumn style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <RowFixed style={{ position: 'relative' }}>
              {trade ? (
                <LoadingOpacityContainer $loading={syncing}>
                  <CurrencyText>
                    <span>1 DIVI = {Math.trunc(1000 * price) / 1000}$</span>
                  </CurrencyText>
                </LoadingOpacityContainer>
              ) : loading || syncing ? (
                <ThemedText.DeprecatedMain fontSize={14}>
                  <span>Fetching best price...</span>
                </ThemedText.DeprecatedMain>
              ) : null}
            </RowFixed>
            <RowFixed>
              {!trade?.gasUseEstimateUSD || !chainId || !SUPPORTED_GAS_ESTIMATE_CHAIN_IDS.includes(chainId) ? null : (
                <GasEstimateBadge trade={trade} loading={syncing || loading} />
              )}
            </RowFixed>
          </AutoColumn>
        </TraceEvent>
        <AutoColumn>
          <AutoColumn gap={'8px'} style={{ padding: '0', paddingBottom: '8px' }}>
            {trade ? (
              <StyledCard>
                <AutoColumn style={{ gridRowGap: '8px' }}>
                  <SwapCardInfo>
                    <RowFixed style={{ whiteSpace: 'nowrap' }}>
                      <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>
                        <span>Price</span>
                      </ThemedText.DeprecatedSubHeader>
                    </RowFixed>
                    <Block>
                      <TradePrice
                        price={trade.executionPrice}
                        showInverted={showInverted}
                        setShowInverted={setShowInverted}
                      />
                    </Block>
                  </SwapCardInfo>
                  <SwapCardInfo>
                    <RowFixed style={{ marginRight: '20px', whiteSpace: 'nowrap' }}>
                      <MouseoverTooltip
                        text="The minimum amount you are guaranteed to receive. If the price slips any further, your transaction will revert."
                        disableHover={hideInfoTooltips}
                      >
                        <ThemedText.DeprecatedSubHeader>
                          {trade.tradeType === TradeType.EXACT_INPUT ? (
                            <span>Minimum received</span>
                          ) : (
                            <span>Maximum sent</span>
                          )}
                          <HelpIconGray style={{ marginLeft: '8px', position: 'relative', top: '5px' }} />
                        </ThemedText.DeprecatedSubHeader>
                      </MouseoverTooltip>
                    </RowFixed>
                    <TextWithLoadingPlaceholder syncing={syncing} width={70}>
                      <Text style={{ wordBreak: 'break-all' }}>
                        {trade.tradeType === TradeType.EXACT_INPUT
                          ? `${trade.minimumAmountOut(allowedSlippage).toSignificant(6)} ${
                              trade.outputAmount.currency.symbol
                            }`
                          : `${trade.maximumAmountIn(allowedSlippage).toSignificant(6)} ${
                              trade.inputAmount.currency.symbol
                            }`}
                      </Text>
                    </TextWithLoadingPlaceholder>
                  </SwapCardInfo>
                  <SwapCardInfo>
                    <RowFixed style={{ whiteSpace: 'nowrap' }}>
                      <MouseoverTooltip
                        text="The impact your trade has on the market price of this pool."
                        disableHover={hideInfoTooltips}
                      >
                        <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>
                          <span>Price Impact</span>
                          <HelpIconGray style={{ marginLeft: '8px', position: 'relative', top: '5px' }} />
                        </ThemedText.DeprecatedSubHeader>
                      </MouseoverTooltip>
                    </RowFixed>
                    <TextWithLoadingPlaceholder syncing={syncing} width={50}>
                      <Text>
                        <FormattedPriceImpact priceImpact={priceImpact} />
                      </Text>
                    </TextWithLoadingPlaceholder>
                  </SwapCardInfo>
                  <SwapCardInfo>
                    <RowFixed style={{ whiteSpace: 'nowrap' }}>
                      <MouseoverTooltip
                        text="Liquidity providers earn a 0.3% fee on all trades proportional to their share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity."
                        disableHover={hideInfoTooltips}
                      >
                        <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>
                          <span>Liquidity Provider Fee</span>
                          <HelpIconGray style={{ marginLeft: '8px', position: 'relative', top: '5px' }} />
                        </ThemedText.DeprecatedSubHeader>
                      </MouseoverTooltip>
                    </RowFixed>
                    <TextWithLoadingPlaceholder syncing={syncing} width={50}>
                      <Text>0.3%</Text>
                    </TextWithLoadingPlaceholder>
                  </SwapCardInfo>
                </AutoColumn>
              </StyledCard>
            ) : null}
          </AutoColumn>
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  )
}
