import { Currency } from '@uniswap/sdk-core'
import styled from 'styled-components/macro'

import CurrencyLogo from '../CurrencyLogo'

const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 36px;
  height: 36px;
  margin-left: ${({ sizeraw, margin }) => margin && (sizeraw / 3 + 8).toString() + 'px'};

  & > div {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 10px;
    box-sizing: content-box;
  }
`

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0?: Currency
  currency1?: Currency
}

const HigherLogo = styled(CurrencyLogo)`
  z-index: 1;
  left: 26px;
  position: relative;
`
const CoveredLogo = styled(CurrencyLogo)<{ sizeraw: number }>`
  position: absolute;
  left: 0;
  z-index: 0;
`

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 32,
  margin = false,
}: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      {currency1 && <HigherLogo currency={currency1} size={size.toString() + 'px'} />}
      {currency0 && <CoveredLogo currency={currency0} size={size.toString() + 'px'} sizeraw={size} />}
    </Wrapper>
  )
}
