import { Currency, Percent, Price } from '@uniswap/sdk-core'
import { Text } from 'rebass'

import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { ONE_BIPS } from '../../constants/misc'
import { Field } from '../../state/mint/actions'
import { ThemedText } from '../../theme'

export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price,
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Price<Currency, Currency>
}) {
  return (
    <AutoColumn gap="md">
      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text fontWeight={500} fontSize={14}>
          <span>
            {currencies[Field.CURRENCY_B]?.symbol} per {currencies[Field.CURRENCY_A]?.symbol}
          </span>
        </Text>
        <ThemedText.DeprecatedBlack fontSize={14}>{price?.toSignificant(6) ?? '-'}</ThemedText.DeprecatedBlack>
      </AutoRow>
      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text fontWeight={500} fontSize={14}>
          <span>
            {currencies[Field.CURRENCY_A]?.symbol} per {currencies[Field.CURRENCY_B]?.symbol}
          </span>
        </Text>
        <ThemedText.DeprecatedBlack fontSize={14}>
          {price?.invert()?.toSignificant(6) ?? '-'}
        </ThemedText.DeprecatedBlack>
      </AutoRow>
      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text fontWeight={500} fontSize={14}>
          <span>Pool share</span>
        </Text>
        <ThemedText.DeprecatedBlack fontSize={14} style={{ color: '#34c759' }}>
          {noLiquidity && price
            ? '100'
            : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
          %
        </ThemedText.DeprecatedBlack>
      </AutoRow>
    </AutoColumn>
  )
}
