import { ConnectExtension } from '@magic-ext/connect'
import { EIP1193 } from '@web3-react/eip1193'
import type { Actions } from '@web3-react/types'
import { SupportedChainId } from 'constants/chains'
import { Magic } from 'magic-sdk'

const magicProvider = new Magic(process.env.REACT_APP_MAGIC_LINK_KEY ?? '', {
  extensions: [new ConnectExtension()],
  // @ts-ignore
  network: process.env.REACT_APP_MAGIC_LINK_NETWORK || 'mainnet',
})

function magicPolygonFactory() {
  const isMainnet = process.env.REACT_APP_MAGIC_LINK_NETWORK === 'mainnet'
  return new Magic(process.env.REACT_APP_MAGIC_LINK_KEY ?? '', {
    extensions: [new ConnectExtension()],
    // @ts-ignore
    network: {
      rpcUrl: isMainnet ? 'https://polygon-rpc.com/' : 'https://rpc-mumbai.maticvigil.com/',
      chainId: isMainnet ? 137 : 80001,
    },
  })
}

export interface MagicConnectorConstructorArgs {
  actions: Actions
  onError?: (error: Error) => void
}

export class MagicConnector extends EIP1193 {
  static ether = magicProvider
  static polygon = magicPolygonFactory()

  static magic = MagicConnector.ether

  constructor({ actions, onError }: MagicConnectorConstructorArgs) {
    super({ actions, provider: MagicConnector.magic.rpcProvider, onError })
  }

  reactivate(chainId: SupportedChainId) {
    MagicConnector.magic = chainId === SupportedChainId.POLYGON ? MagicConnector.polygon : MagicConnector.ether
    this.provider = MagicConnector.magic.rpcProvider
  }

  static showWallet() {
    return MagicConnector.magic.connect.showWallet().catch((e) => console.error(e))
  }

  static async isMagicConnected() {
    let isConnected = false

    try {
      const walletInfo = await MagicConnector.magic.connect.getWalletInfo()

      isConnected = walletInfo.walletType === 'magic'
    } catch (error) {
      console.error('Failed to check is connected magic link: ', error)
    }

    return isConnected
  }
}
