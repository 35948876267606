//import { ReactNode } from 'react'
import styled from 'styled-components/macro'
const FilterOption = styled.button<{ active: boolean; highlight?: boolean }>`
  height: 100%;
  color: #fff;
  background: transparent;
  margin: 0;
  padding: 0;
  border-radius: 12px;
  font-size: 16px;
  line-height: 24px;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};
  border: none;

  :hover {
    cursor: pointer;
  }
  :focus {
    background-color: transparent;
  }
`
export default FilterOption
