import { resetMagicLink } from 'connection'

const ROUTE_EVENTS = {
  ADD_LIQUIDITY: 'add-liquidity',
  SWAP: 'swap',
  PAIR: 'pair',
  TOKEN_ETH: 'token-ETH',
  TOKEN_DIVI: 'token-DIVI',
}

const ROUTE_BY_EVENT_NAME = {
  [ROUTE_EVENTS.ADD_LIQUIDITY]: '/liquidity',
  [ROUTE_EVENTS.SWAP]: '/swap',
  [ROUTE_EVENTS.TOKEN_ETH]: '/token/eth',
  [ROUTE_EVENTS.TOKEN_DIVI]: '/token/divi',
  [ROUTE_EVENTS.PAIR]: '/pair',
}
export default class PostMessengerListener {
  private navigate!: (route: string) => void

  constructor(navigate: (route: string) => void) {
    this.navigate = navigate
  }

  init() {
    if ((window as any).isInitPostMessenger) {
      return
    }

    const cb = (event: { data?: { type: string; msgType?: string }; origin: string }) => {
      const isEventLogoutMagicLink = () =>
        event.origin === 'https://auth.magic.link' && event.data?.msgType?.includes('FORTMATIC_USER_LOGOUT')
      if (isEventLogoutMagicLink()) {
        resetMagicLink()
      }
      if (!event?.data?.type || event?.data?.type !== 'change-route') {
        return
      }

      this.callback(event)
    }
    window.addEventListener('message', cb, false)
    ;(window as any).isInitPostMessenger = true
  }

  private callback(event: any) {
    const route = ROUTE_BY_EVENT_NAME[event.data.name]
    if (route) {
      this.navigate(route)
    }
  }
}
